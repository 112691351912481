import "./Sidebar.css";
import {
  FaTimes,
  FaPowerOff,
  FaTh,
  FaLock,
  FaBook,
  FaUserTie,
  FaBookmark,
  FaUserFriends,
  FaCogs,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useContext } from "react";

// hooks
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty } from "../../Utilities/utils";

// images
import appLogo from "../../assets/logo/blue-logo.png";
import { MdSchedule } from "react-icons/md";

const SettingsSidebar = ({ sidebarOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const schoolAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";
  const reverseSidebar = () => {
    toggleSidebar();
  };

  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar_title mt-20">
        <div className="flex flex-center" style={{ width: "100%" }}>
          <img
            style={{ width: 60, height: "auto" }}
            src={!empty(schoolAvatar) ? schoolAvatar : appLogo}
            alt="logo"
          />
        </div>
        <FaTimes id="sidebar_close_icon" onClick={toggleSidebar} />
      </div>

      <div className="sidebar_menu">
        {/* sidebar link */}
        <div className="separator">Home</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaTh className="sidebar_link_icon" />
              <span className="sidebar_link_text">Dashboard</span>
            </div>
          )}
        </NavLink>

        <div className="separator">Settings</div>
        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings/home"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaCogs className="sidebar_link_icon" />
              <div className="sidebar_link_text">Home</div>
            </div>
          )}
        </NavLink>
        {/* sidebar link */}
        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings/grade"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBook className="sidebar_link_icon" />
              <div className="sidebar_link_text">Grade</div>
            </div>
          )}
        </NavLink>
        {/* sidebar link */}

        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings/assessment"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBookmark className="sidebar_link_icon" />
              <div className="sidebar_link_text">Assessment</div>
            </div>
          )}
        </NavLink>
        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings/class/teachers"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserFriends className="sidebar_link_icon" />
              <div className="sidebar_link_text">Class Teachers</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings/profile"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserTie className="sidebar_link_icon" />
              <div className="sidebar_link_text">Profile</div>
            </div>
          )}
        </NavLink>

        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings/update-password"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaLock className="sidebar_link_icon" />
              <div className="sidebar_link_text">Password</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <div className="sidebar_logout mb-20">
          <FaPowerOff className="sidebar_logout_icon" />
          <NavLink to="/logout" className="sidebar_logout_text">
            Logout
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SettingsSidebar;
