import { useContext, useRef, useState, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import schoolApi from "../../api/School";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { AuthContext } from "../Root/ProtectedRoute";
import {
  empty,
  isArray,
  isObject,
  isUndefined,
  prepareResponseData,
} from "../../Utilities/utils";
import InputFieldChange from "../../components/form/InputFieldChange";
import SettingsWrapper from "../../components/appWrapper/SettingsWrapper";
import SelectField from "../../components/form/SelectField";
import { Numbers } from "../../data/Numbers";
import { DaysOfTheWeek } from "../../data/DaysOfTheWeek";
import MultiSelectField from "../../components/form/MultiSelectField";
import colors from "../../config/colors";

const required = "This field is required!";

const validationSchemaAcademicDates = Yup.object().shape({
  closing_date: Yup.string().required(required),
  opening_date: Yup.string().required(required),
});

const validateSchemaPeriods = Yup.object().shape({
  no_of_breaks: Yup.number().required("Number of breaks is required"),
  no_of_periods_after_last_break: Yup.number().required(
    "Number of periods after last break is required"
  ),
  break_details: Yup.array().of(
    Yup.object().shape({
      no_of_periods: Yup.number().required("Number of periods is required"),
    })
  ),
});

const validationSchemaTimes = Yup.object({
  class_start_time: Yup.string()
    .required("Class start time is required")
    .matches(
      /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
      "Class start time must be in 12-hour format (e.g., 01:00 PM)"
    ),
  school_start_time: Yup.string()
    .required("School start time is required")
    .matches(
      /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
      "School start time must be in 12-hour format (e.g., 01:00 PM)"
    ),
  school_dismissal_time: Yup.string()
    .required("School Dimissal time is required")
    .matches(
      /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
      "School dismissal time must be in 12-hour format (e.g., 01:00 PM)"
    ),
  lesson_start_time: Yup.string()
    .optional("Lesson start time is required")
    .matches(
      /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
      "Lesson start time must be in 12-hour format (e.g., 01:00 PM)"
    ),
  lesson_dismissal_time: Yup.string()
    .optional("Time is required")
    .matches(
      /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
      "Lesson dismissal time must be in 12-hour format (e.g., 01:00 PM)"
    ),
});

const Settings = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const _closingDate =
    !empty(user) && !empty(user.closingDate) ? user.closingDate : "";
  const _openingDate =
    !empty(user) && !empty(user.openingDate) ? user.openingDate : "";
  const periodSettings =
    !empty(user) && !empty(user.periodSettings) ? user.periodSettings : {};
  const noOfBreaks =
    !empty(user) && !empty(periodSettings) && !empty(periodSettings.noOfBreaks)
      ? periodSettings.noOfBreaks
      : 0;
  const schoolClockInOut =
    !empty(user) && !empty(user.schoolClockInOut) ? user.schoolClockInOut : {};
  const _schoolOpenDays =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.schoolOpenDays)
      ? schoolClockInOut.schoolOpenDays
      : [];
  const _lessonFreeDays =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.lessonFreeDays)
      ? schoolClockInOut.lessonFreeDays
      : [];
  const schoolStartTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.schoolStartTime)
      ? schoolClockInOut.schoolStartTime
      : "07:30 AM";
  const classStartTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.classStartTime)
      ? schoolClockInOut.classStartTime
      : "08:00 AM";
  const schoolDismissalTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.schoolDismissalTime)
      ? schoolClockInOut.schoolDismissalTime
      : "04:00 PM";
  const lessonStartTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.lessonStartTime)
      ? schoolClockInOut.lessonStartTime
      : "03:00 PM";
  const lessonDismissalTime =
    !empty(user) &&
    !empty(schoolClockInOut) &&
    !empty(schoolClockInOut.lessonDismissalTime)
      ? schoolClockInOut.lessonDismissalTime
      : "04:00 PM";
  const breakDetails =
    !empty(periodSettings) && !empty(periodSettings.breakDetails)
      ? periodSettings.breakDetails
      : [];
  const noOfPeriodsAfterLastBreak =
    !empty(periodSettings) &&
    !isUndefined(periodSettings.noOfPeriodsAfterLastBreak)
      ? periodSettings.noOfPeriodsAfterLastBreak
      : 0;
  const noOfClassesPerDay =
    !empty(periodSettings) && !isUndefined(periodSettings.noOfClassesPerDay)
      ? periodSettings.noOfClassesPerDay
      : 0;
  const initialValuesAcademicDates = {
    closing_date: _closingDate,
    opening_date: _openingDate,
  };
  const initialValuesPeriods = {
    no_of_breaks: noOfBreaks,
    break_details: breakDetails,
    no_of_periods_after_last_break: noOfPeriodsAfterLastBreak,
    no_of_classes_per_day: noOfClassesPerDay,
  };
  const initialValuesTime = {
    class_start_time: classStartTime,
    school_start_time: schoolStartTime,
    school_dismissal_time: schoolDismissalTime,
    lesson_start_time: lessonStartTime,
    lesson_dismissal_time: lessonDismissalTime,
  };
  const [isLoading, setIsLoading] = useState(false);
  const [schoolOpenDays, setSchoolOpenDays] = useState(_schoolOpenDays);
  const [lessonFreeDays, setLessonFreeDays] = useState(_lessonFreeDays);
  const [selectedNoOfBreaks, setSelectedNoOfBreaks] = useState(
    Array.from({ length: noOfBreaks }, (_, i) => i + 1)
  );
  const toastTR = useRef(null);

  useEffect(() => {
    // This effect will trigger when selectedNoOfBreaks changes
  }, [selectedNoOfBreaks]);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const handleSubmitAcademicDates = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        responseDailog("error", "Error Alert", "Something went wrong.");
      }
      const closingDate = !empty(values.closing_date)
        ? values.closing_date
        : "";
      const openingDate = !empty(values.opening_date)
        ? values.opening_date
        : "";
      await updateSchoolSettings("Academic dates", closingDate, openingDate);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const updateSchoolSettings = async (
    type = "",
    closingDate = "",
    openingDate = "",
    remarks = []
  ) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.updateSchoolSettings(
        closingDate,
        openingDate,
        remarks,
        schoolId,
        type
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      } else {
        return responseDailog(
          "success",
          "Success",
          `${type} updated successfully.`
        );
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    }
  };

  const handlePeriodsSubmit = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        responseDailog("error", "Error Alert", "Something went wrong.");
      }
      const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
      const noOfBreaks = !empty(values.no_of_breaks) ? values.no_of_breaks : 0;
      let _index = -1;
      let timeType = "";
      const breakDetails = [];
      for (let i = 0; i < selectedNoOfBreaks.length; i++) {
        const start_time = values.break_details?.[i]?.start_time || "";
        const end_time = values.break_details?.[i]?.end_time || "";

        if (!timeRegex.test(start_time)) {
          timeType = "start";
          _index = i;
          break;
        }
        if (!timeRegex.test(end_time)) {
          timeType = "end";
          _index = i;
          break;
        }
        breakDetails.push({
          break_number: i + 1,
          no_of_periods: values.break_details?.[i]?.no_of_periods || 0,
          start_time: values.break_details?.[i]?.start_time || "",
          end_time: values.break_details?.[i]?.end_time || "",
        });
      }

      if (timeType === "start") {
        return responseDailog(
          "error",
          "Invalid input",
          `Start time must be in 12-hour format (e.g., 01:00 PM) for break ${
            _index + 1
          }`
        );
      }

      if (timeType === "end") {
        return responseDailog(
          "error",
          "Invalid input",
          `End time must be in 12-hour format (e.g., 01:00 PM) break ${
            _index + 1
          }`
        );
      }

      const noOfPeriodsAfterLastBreak = !empty(
        values.no_of_periods_after_last_break
      )
        ? values.no_of_periods_after_last_break
        : 0;
      const noOfClassesPerDay = !empty(values.no_of_classes_per_day)
        ? values.no_of_classes_per_day
        : 0;
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.updatePeriods(
        schoolId,
        noOfBreaks,
        breakDetails,
        noOfPeriodsAfterLastBreak,
        noOfClassesPerDay
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to update period settings!"
        );
      } else {
        return responseDailog(
          "success",
          "Success",
          `Period Settings updated successfully.`
        );
      }
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error",
        "Failed to update period settings."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimesSubmit = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        responseDailog("error", "Error Alert", "Something went wrong.");
      }
      const schoolStartTime = !empty(values.school_start_time)
        ? values.school_start_time
        : "07:30 AM";
      const classStartTime = !empty(values.class_start_time)
        ? values.class_start_time
        : "08:00 AM";
      const schoolDismissalTime = !empty(values.school_dismissal_time)
        ? values.school_dismissal_time
        : "02:45 PM";
      const lessonStartTime = !empty(values.lesson_start_time)
        ? values.lesson_start_time
        : "03:00 PM";
      const lessonDismissalTime = !empty(values.lesson_dismissal_time)
        ? values.lesson_dismissal_time
        : "04:00 PM";
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.updateSchoolTimes(
        schoolId,
        classStartTime,
        schoolStartTime,
        schoolDismissalTime,
        lessonStartTime,
        lessonDismissalTime,
        schoolOpenDays,
        lessonFreeDays
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to update school opening/closing hours/days settings!"
        );
      } else {
        return responseDailog(
          "success",
          "Success",
          `School opening/closing hours/days updated successfully.`
        );
      }
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error",
        "Failed to update school opening/closing hours/days."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleNumberOfBreakChange = (name, e, setFieldValue) => {
    let target = !empty(e) && isObject(e) && !empty(e.target) ? e.target : {};
    const noOfBreaks =
      !empty(target) && !isUndefined(target.value) ? e.target.value : 0;
    let breaks = [];
    for (let i = 1; i <= noOfBreaks; i++) {
      breaks.push(i);
    }
    setSelectedNoOfBreaks(breaks);
    setFieldValue(name, noOfBreaks);
  };

  const handleMultiChange = (name, newValue, setFieldValue) => {
    try {
      setFieldValue(name, []);
      const days = !empty(newValue) && isArray(newValue) ? newValue : [];
      setFieldValue(name, days);
      if (name === "school_open_days") {
        return setSchoolOpenDays(days);
      } else {
        return setLessonFreeDays(days);
      }
    } catch (error) {}
  };

  return (
    <>
      <SettingsWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader title="Academic Settings" />
            <div className="form-container mt-10">
              <Formik
                enableReinitialize
                initialValues={initialValuesAcademicDates}
                validationSchema={validationSchemaAcademicDates}
                onSubmit={handleSubmitAcademicDates}
              >
                {({ values, handleChange }) => (
                  <Form style={{ width: "100%" }}>
                    <div
                      style={{ marginTop: 30, fontSize: 18, color: "#633ccd" }}
                    >
                      Dates
                    </div>
                    <div className="app_input_group">
                      <InputFieldChange
                        placeholder="Enter Closing Date"
                        name="closing_date"
                        type="date"
                        height={50}
                        required={true}
                        labelTitle="Closing Date"
                        value={values.closing_date}
                        onChange={handleChange}
                      />
                      <InputFieldChange
                        placeholder="Enter Opening Date"
                        name="opening_date"
                        type="date"
                        required={true}
                        height={50}
                        labelTitle="Opening Date"
                        value={values.opening_date}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <ButtonIcon
                        height={45}
                        marginTop={2}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={100}
                        borderColor="#633ccd"
                        buttonText="Update"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="form-container mt-10">
              <Formik
                enableReinitialize
                initialValues={initialValuesTime}
                validationSchema={validationSchemaTimes}
                onSubmit={handleTimesSubmit}
              >
                {({ values, handleChange, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div
                      style={{ marginTop: 30, fontSize: 18, color: "#633ccd" }}
                    >
                      Opening / Closing Hours/Days
                    </div>
                    <p className="mt-10">
                      <strong>Note:</strong> School Start and End time includes
                      both the lesson times
                    </p>
                    <div className="app_input_group mb-30">
                      <InputFieldChange
                        placeholder="Enter School Start Time using 12-hour format (e.g., 01:00 PM)"
                        name="school_start_time"
                        height={50}
                        required={true}
                        labelTitle="School Start Time"
                        value={values.school_start_time}
                        onChange={handleChange}
                      />
                      <InputFieldChange
                        placeholder="Enter Class Start Time using 12-hour format (e.g., 01:00 PM)"
                        name="class_start_time"
                        height={50}
                        required={true}
                        labelTitle="Class Start Time"
                        value={values.class_start_time}
                        onChange={handleChange}
                      />
                      <InputFieldChange
                        placeholder="Enter School Dismissal Time using 12-hour format (e.g., 01:00 PM)"
                        name="school_dismissal_time"
                        required={true}
                        height={50}
                        labelTitle="School Dismissal Time"
                        value={values.school_dismissal_time}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <InputFieldChange
                        placeholder="Enter Lesson Start Time using 12-hour format (e.g., 01:00 PM)"
                        name="lesson_start_time"
                        height={50}
                        labelTitle="Lesson Start Time"
                        value={values.lesson_start_time}
                        onChange={handleChange}
                      />
                      <InputFieldChange
                        placeholder="Enter Lesson Dismissal Time using 12-hour format (e.g., 01:00 PM)"
                        name="lesson_dismissal_time"
                        height={50}
                        labelTitle="Lesson Dismissal Time"
                        value={values.lesson_dismissal_time}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <MultiSelectField
                        labelTitle="School-Open days"
                        required={true}
                        placeholder="Select School-Open days"
                        name="school_open_days"
                        options={DaysOfTheWeek}
                        selectedOptions={schoolOpenDays}
                        optionValue="value"
                        optionLabel="value"
                        onChange={(e) =>
                          handleMultiChange(
                            "school_open_days",
                            e.value,
                            setFieldValue
                          )
                        }
                      />
                    </div>
                    <div className="app_input_group">
                      <MultiSelectField
                        labelTitle="Lesson Free days"
                        required={true}
                        placeholder="Select Lesson Free days"
                        name="lesson_free_days"
                        options={DaysOfTheWeek}
                        selectedOptions={lessonFreeDays}
                        optionValue="value"
                        optionLabel="value"
                        onChange={(e) =>
                          handleMultiChange(
                            "lesson_free_days",
                            e.value,
                            setFieldValue
                          )
                        }
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <ButtonIcon
                        height={45}
                        marginTop={2}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={100}
                        borderColor="#633ccd"
                        buttonText="Update"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="form-container mt-10">
              <Formik
                enableReinitialize
                initialValues={initialValuesPeriods}
                validationSchema={validateSchemaPeriods}
                onSubmit={handlePeriodsSubmit}
              >
                {({ values, handleChange, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div
                      style={{ marginTop: 30, fontSize: 18, color: "#633ccd" }}
                    >
                      Class Schedules and Periods
                    </div>
                    <div className="app_input_group  md-w-50pc">
                      <SelectField
                        labelTitle="Number of Break(s)"
                        placeholder="Enter number of breaks"
                        name="no_of_breaks"
                        required={true}
                        options={Numbers}
                        height={50}
                        valueKey="value"
                        display="value"
                        selectedOption={values.no_of_breaks}
                        handleChangeFunc={(e) =>
                          handleNumberOfBreakChange(
                            "no_of_breaks",
                            e,
                            setFieldValue
                          )
                        }
                      />
                    </div>
                    {selectedNoOfBreaks.map((item, index) => {
                      return (
                        <div
                          className="app_input_group mb-10 py-20"
                          style={{
                            borderBottom: "1px solid " + colors.primary,
                          }}
                        >
                          <SelectField
                            index={index}
                            key={index}
                            labelTitle={`No. of Periods/Classes before Break ${item}`}
                            placeholder={`Enter number of periods/classes for break ${item}`}
                            name={`break_details.${index}.no_of_periods`}
                            required={true}
                            options={Numbers}
                            height={50}
                            valueKey="value"
                            display="value"
                            selectedOption={
                              values.break_details?.[index]?.no_of_periods || 0
                            }
                            handleChangeFunc={handleChange}
                          />
                          <InputFieldChange
                            placeholder="Enter Break-start Time format (e.g., 01:00 PM)"
                            name={`break_details.${index}.start_time`}
                            required={true}
                            height={50}
                            labelTitle={`Break${item}-start Time`}
                            marginTop={10}
                            value={
                              values.break_details?.[index]?.start_time || ""
                            }
                            onChange={handleChange}
                          />
                          <InputFieldChange
                            placeholder="Enter Break-end Time format (e.g., 01:00 PM)"
                            name={`break_details.${index}.end_time`}
                            required={true}
                            height={50}
                            labelTitle={`Break${item}-end Time`}
                            marginTop={10}
                            value={
                              values.break_details?.[index]?.end_time || ""
                            }
                            onChange={handleChange}
                          />
                        </div>
                      );
                    })}
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="Number of periods/classes after last break"
                        placeholder="Enter number of periods/classes after last break"
                        name="no_of_periods_after_last_break"
                        required={true}
                        options={Numbers}
                        height={50}
                        valueKey="value"
                        display="value"
                        selectedOption={values.no_of_periods_after_last_break}
                        handleChangeFunc={handleChange}
                      />
                      <SelectField
                        labelTitle="Number of periods/classes per day"
                        placeholder="Enter number of periods/classes per day"
                        name="no_of_classes_per_day"
                        required={true}
                        options={Numbers}
                        height={50}
                        valueKey="value"
                        display="value"
                        selectedOption={values.no_of_classes_per_day}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <ButtonIcon
                        height={45}
                        marginTop={2}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={100}
                        borderColor="#633ccd"
                        buttonText="Update"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </SettingsWrapper>
    </>
  );
};

export default Settings;
