export const Intervals = [
  {
    _id: 1,
    title: "5 Minutes",
    value: 5,
  },
  {
    _id: 2,
    title: "15 Minutes",
    value: 15,
  },
  {
    _id: 3,
    title: "20 Minutes",
    value: 20,
  },
  {
    _id: 4,
    title: "25 Minutes",
    value: 25,
  },
  {
    _id: 5,
    title: "30 Minutes",
    value: 30,
  },
  {
    _id: 6,
    title: "35 Minutes",
    value: 35,
  },
  {
    _id: 7,
    title: "40 Minutes",
    value: 40,
  },
  {
    _id: 8,
    title: "45 Minutes",
    value: 45,
  },
  {
    _id: 9,
    title: "50 Minutes",
    value: 50,
  },
  {
    _id: 10,
    title: "55 Minutes",
    value: 55,
  },
  {
    _id: 11,
    title: "1 Hour",
    value: 60,
  },
  {
    _id: 12,
    title: "1 Hour 30 Minutes",
    value: 90,
  },
  {
    _id: 13,
    title: "2 Hours",
    value: 120,
  },
  {
    _id: 14,
    title: "2 Hours 30 Minutes",
    value: 150,
  },
  {
    _id: 15,
    title: "3 Hours",
    value: 180,
  },
];
