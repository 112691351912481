import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import assessmentApi from "../../api/Assessment";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import TableLoading from "../../components/skeleton/TableLoading";
import { FaPlus } from "react-icons/fa";
import PsychomotorDataTable from "../../components/tables/primeTable/psychomotor/Psychomotor";

const Psychomotor = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [psychomotorData, setPsychomotorData] = useState([]);
  const [psychomotorModalVisibility, setPsychomotorModalVisibility] =
    useState(false);
  const [selectedPsychomotorDetail, setSelectedPsychomotorDetail] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  useEffect(() => {
    if (!empty(location) && !empty(location.state)) {
      const paramState = !empty(location.state) ? location.state : {};
      let psychomotorAdded = !empty(paramState.psychomotorAdded)
        ? paramState.psychomotorAdded
        : false;
      let psychomotorUpdated = !empty(paramState.psychomotorUpdated)
        ? paramState.psychomotorUpdated
        : false;
      if (psychomotorAdded === true || psychomotorUpdated === true) {
        const actionType = psychomotorAdded
          ? "added"
          : psychomotorUpdated
          ? "updated"
          : "";
        paramState.psychomotorAdded = false;
        paramState.psychomotorUpdated = false;
        responseDailog(
          "success",
          "Success",
          `Psychomotor ${actionType} successfully!`
        );
        if (!empty(location) && !empty(location.state)) {
          navigate(null, {
            replace: true,
            state: {
              psychomotorAdded: false,
              psychomotorUpdated: false,
            },
          });
        }
      }
    }
  }, []);
  useEffect(() => {
    // fetch psychomotor
    try {
      getAllPsychomotor();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load psychomotor. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { psychomotorAdded: false, psychomotorUpdated: false },
        });
      }
    }
  }, [user]);

  // function to get all psychomotor
  const getAllPsychomotor = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await assessmentApi.getPsychomotor(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setPsychomotorData([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setPsychomotorData(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  // delete psychomotor
  const deletePsychomotor = async () => {
    try {
      if (psychomotorModalVisibility) setPsychomotorModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const psychomotorId =
        !empty(selectedPsychomotorDetail) &&
        !empty(selectedPsychomotorDetail._id)
          ? selectedPsychomotorDetail._id
          : "";
      const response = await assessmentApi.deletePsychomotor(
        schoolId,
        psychomotorId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to delete psychomotor";
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Psychomotor, ${
          !empty(selectedPsychomotorDetail.title)
            ? selectedPsychomotorDetail.title
            : ""
        }, was deleted successfully!`
      );
      return getAllPsychomotor();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to hide confirm modal
  const hidePsychomotorModal = () => {
    setPsychomotorModalVisibility(false);
  };

  //go to update psychomotor page
  const goToUpdatePsychomotor = (data) => {
    const psychomotorId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/assessment/psychomotor/${psychomotorId}/update`);
  };

  // delete psychomotor
  const showDeletePsychomotorModal = (data) => {
    setSelectedPsychomotorDetail(data);
    setPsychomotorModalVisibility(true);
  };

  const psychomotorModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hidePsychomotorModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={deletePsychomotor}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button
              buttonText="Add Psychomotor"
              link="/assessment/psychomotor/add"
              title="Psychomotors"
              icon={<FaPlus />}
              iconMarginRight={5}
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <PsychomotorDataTable
                psychomotor={psychomotorData}
                goToUpdatePsychomotorScreen={goToUpdatePsychomotor}
                goToDeletePsychomotorScreen={showDeletePsychomotorModal}
              />
            ) : (
              <TableLoading rows={20} cols={3} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
      </AppWrapper>
      {isActionLoading && <FullPageLoader visible={isActionLoading} />}

      <Dialog
        visible={psychomotorModalVisibility}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={psychomotorModalFooter}
        onHide={hidePsychomotorModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to delete the selected psychomotor with
              title,
              <strong>
                {" "}
                {!empty(selectedPsychomotorDetail) &&
                !empty(selectedPsychomotorDetail.title)
                  ? selectedPsychomotorDetail.title
                  : ""}
              </strong>
              ?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Psychomotor;
