export const months = [
  { _id: 1, title: "January" },
  { _id: 2, title: "Febuary" },
  { _id: 3, title: "March" },
  { _id: 4, title: "April" },
  { _id: 5, title: "May" },
  { _id: 6, title: "June" },
  { _id: 7, title: "July" },
  { _id: 8, title: "August" },
  { _id: 9, title: "September" },
  { _id: 10, title: "October" },
  { _id: 11, title: "November" },
  { _id: 12, title: "December" },
];
