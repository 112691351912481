import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  empty,
  isArray,
  isObject,
  isString,
  isUndefined,
  prepareResponseData,
  reIndex,
  toNormalCase,
} from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import _ from "lodash";

// css
import "../students/Students.css";
import "./Assessment.css";

// api
import studentApi from "../../api/Student";
import classApi from "../../api/Classes";
import termApi from "../../api/Terms";
import assessmentApi from "../../api/Assessment";
import sessionApi from "../../api/Session";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { Button } from "primereact/button";
import { FaExclamationTriangle } from "react-icons/fa";
import colors from "../../config/colors";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import Barchart from "../../components/chart/barchart/Barchart";

const DisplayReportSheet = ({ ...props }) => {
  const printRef = useRef();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [classData, setClassData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [studentAssessment, setStudentAssessment] = useState([]);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [activeDetails, setActiveDetails] = useState({});
  const [termData, setTermData] = useState({});
  const [sessionData, setSessionData] = useState({});
  const [studentReport, setStudentReport] = useState({});
  const [hasPaid, setHasPaid] = useState(false);
  const [classTeacherName, setClassTeacherName] = useState("");
  const [ratingIndices, setRatingIndices] = useState([]);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [chartData, setChartData] = useState({
    labels: studentAssessment.map((data) => {
      const subjectCode =
        !empty(data) && !empty(data.subjectCode) ? data.subjectCode : "";
      return subjectCode;
    }),
    datasets: [
      {
        label: "Student Performance",
        data: studentAssessment.map((data) => {
          const sum = !empty(data) && !empty(data.total) ? data.total : 0;
          return sum;
        }),
        backgroundColor: colors.charcoal,
        borderRadius: 12,
      },
      {
        label: "Class Average",
        data: studentAssessment.map((data) => {
          const sum = !empty(data) && !empty(data.total) ? data.total : 0;
          return sum;
        }),
        backgroundColor: colors.charcoal,
        borderRadius: 12,
      },
    ],
  });
  const { studentId, termId, sessionId, classId } = useParams();
  if (!studentId || !termId || !sessionId || !classId) {
    navigate("/404");
  }

  const schoolName = !empty(user) && !empty(user.name) ? user.name : "";
  const schoolAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";
  const email = !empty(user) && !empty(user.email) ? user.email : "";
  const address = !empty(user) && !empty(user.address) ? user.address : "";
  const phoneNumber1 =
    !empty(user) && !empty(user.phoneNumber1) ? user.phoneNumber1 : "";
  const phoneNumber2 =
    !empty(user) && !empty(user.phoneNumber2) ? user.phoneNumber2 : "";
  const ownerPhone =
    !empty(user) && !empty(user.ownerPhone) ? user.ownerPhone : "";
  const closingDate =
    !empty(user) && !empty(user.closingDate) ? user.closingDate : "";
  const openingDate =
    !empty(user) && !empty(user.openingDate) ? user.openingDate : "";

  useEffect(() => {
    // fetch student
    try {
      if (!empty(studentId) && !empty(sessionId) && !empty(termId)) {
        getStudent();
      } else {
        navigate("/404");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
    }
  }, [user]);

  useEffect(() => {
    if (hasPaid) {
      getTermDetails();
      getSessionDetails();
      getSubjectsInClass();
      getClassDetails();
      getRatingIndices();
    }
  }, [user, hasPaid]);

  useEffect(() => {
    if (hasPaid) {
      assessmentData();
    }
  }, [studentData, classData]);

  // function to get all student
  const getStudent = async (returnValue = false) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      let response;
      response = await studentApi.getSingleStudent(studentId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData({});
      } else {
        const returnData = !empty(response_data.response)
          ? response_data.response
          : [];
        if (returnValue) {
          return returnData;
        } else {
          const hasPaid =
            !empty(returnData) &&
            !empty(returnData.paid) &&
            returnData.paid === "Yes"
              ? true
              : false;
          setHasPaid(hasPaid);
          return setStudentData(returnData);
        }
      }
    } catch (error) {
      responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching student students.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getClassDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSingleClass(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassData({});
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : {};
        const classTeacherName =
          !empty(data) && !empty(data.classTeacher) ? data.classTeacher : "";
        setClassData(data);
        return setClassTeacherName(classTeacherName);
      }
    } catch (error) {
      return responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching class details.`
      );
    }
  };

  const getTermDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await termApi.getSingleTerm(schoolId, termId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setTermData({});
      } else {
        return setTermData(response_data.response);
      }
    } catch (error) {
      responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching terms.`
      );
    }
  };

  const getRatingIndices = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await assessmentApi.getRatings(schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setRatingIndices({});
      } else {
        return setRatingIndices(response_data.response);
      }
    } catch (error) {
      responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching rating indices.`
      );
    }
  };

  const getSubjectsInClass = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        1000
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setReIndexedSubjects({});
      } else {
        const classSubjects = response_data.response;
        const reIndexedSubjects = reIndex(classSubjects, "_id");
        return setReIndexedSubjects(reIndexedSubjects);
      }
    } catch (error) {
      return responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching subjects in class.`
      );
    }
  };

  const getSessionDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await sessionApi.getSession(sessionId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setSessionData({});
      } else {
        return setSessionData(response_data.response);
      }
    } catch (error) {
      return responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching session details.`
      );
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const assessmentData = () => {
    try {
      const studentAssessmentData =
        !empty(studentData) && !empty(studentData.assessment)
          ? studentData.assessment
          : [];
      const studentReport =
        !empty(studentData) && !empty(studentData.reports)
          ? studentData.reports
          : [];
      const classReports =
        !empty(classData) && !empty(classData.reports) ? classData.reports : [];
      let classReport = {};
      for (let i = 0; i < classReports.length; i++) {
        const studentData = !empty(classReports[i]) ? classReports[i] : {};
        const reportSessionId = !empty(studentData.sessionId)
          ? studentData.sessionId
          : "";
        const reportTermId = !empty(studentData.termId)
          ? studentData.termId
          : "";
        if (reportSessionId === sessionId && reportTermId === termId) {
          classReport = studentData;
          break;
        }
      }
      const selectedSubjectIds =
        !empty(classReport) && !empty(classReport.subjectsToAssess)
          ? classReport.subjectsToAssess
          : [];
      const currentStudentAssessmentData = studentAssessmentData.filter(
        (item) => {
          const itemSessionId =
            !empty(item) && !empty(item.sessionId) ? item.sessionId : "";
          const itemTermId =
            !empty(item) && !empty(item.termId) ? item.termId : "";
          const itemSubjectId =
            !empty(item) && !empty(item.subjectId) ? item.subjectId : "";
          if (
            itemSessionId === sessionId &&
            itemTermId === termId &&
            selectedSubjectIds.includes(itemSubjectId)
          ) {
            const subjectId =
              !empty(item) && !empty(item.subjectId) ? item.subjectId : "";
            item.subjectCode =
              !empty(reIndexedSubjects) &&
              !empty(reIndexedSubjects[subjectId]) &&
              !empty(reIndexedSubjects[subjectId].code)
                ? reIndexedSubjects[subjectId].code
                : "";
            return (item.subjectTitle =
              !empty(reIndexedSubjects) &&
              !empty(reIndexedSubjects[subjectId]) &&
              !empty(reIndexedSubjects[subjectId].title)
                ? reIndexedSubjects[subjectId].title
                : "");
          }
        }
      );

      let currentReport = {};
      for (let i = 0; i < studentReport.length; i++) {
        const itemSessionId =
          !empty(studentReport[i]) && !empty(studentReport[i].sessionId)
            ? studentReport[i].sessionId
            : "";
        const itemTermId =
          !empty(studentReport[i]) && studentReport[i].termId
            ? studentReport[i].termId
            : "";
        if (itemSessionId === sessionId && itemTermId === termId) {
          currentReport = !empty(studentReport[i]) ? studentReport[i] : {};

          studentData.psychomotor =
            !empty(studentReport[i]) && !empty(studentReport[i].psychomotor)
              ? studentReport[i].psychomotor
              : {};
          studentData.affectiveDomain =
            !empty(studentReport[i]) && !empty(studentReport[i].affectiveDomain)
              ? studentReport[i].affectiveDomain
              : {};
          break;
        }
      }
      setStudentReport(currentReport);
      setStudentAssessment(currentStudentAssessmentData);
      setActiveDetails(studentData);
      // set chart data
      setChartData({
        labels: currentStudentAssessmentData.map((data) => {
          const subjectCode =
            !empty(data) && !empty(data.subjectCode) ? data.subjectCode : "";
          return subjectCode;
        }),
        datasets: [
          {
            label: "Subject Performance",
            data: currentStudentAssessmentData.map((data) => {
              const sum = !empty(data) && !empty(data.total) ? data.total : 0;
              return sum;
            }),
            backgroundColor: colors.primary,
            borderRadius: 12,
          },
          {
            label: `Class Average`,
            data: currentStudentAssessmentData.map((data) => {
              const average =
                !empty(data) && !empty(data.classAverage)
                  ? data.classAverage
                  : 0;
              return average;
            }),
            backgroundColor: colors.orange,
            borderRadius: 12,
          },
        ],
      });
    } catch (error) {}
  };

  // function to show result sheet
  const getStudentReportSheet = (data) => {
    const hasPaid =
      !empty(data) && !empty(data.paid) && data.paid === "Yes" ? true : false;
    setHasPaid(hasPaid);
    assessmentData(data);
  };

  // hide activation modal
  const hideActivationModal = () => {
    setActivationModalOpen(false);
  };

  //function to activate student on payment
  const activateStudent = async () => {
    try {
      if (activationModalOpen) setActivationModalOpen(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(studentData) && !empty(studentData._id) ? studentData._id : "";
      const response = await studentApi.activateStudent([studentId], schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to activate student`;
        return responseDailog("error", "Error Alert", error_response);
      }

      const student = await getStudent(true);
      getStudentReportSheet(student, false);
      return responseDailog(
        "success",
        "Success",
        `Activated student successfully!`
      );
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const activateStudentDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideActivationModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={() => {
          activateStudent();
        }}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader title="Result Sheet Display" />
            {/* end of header */}
            <div className="report-sheet-display">
              {hasPaid ? (
                <div className="report-body" ref={printRef}>
                  {/* report header */}
                  <div className="report-header">
                    <div style={{ width: 100, height: 100 }}>
                      <img
                        src={!empty(schoolAvatar) ? schoolAvatar : ""}
                        alt="non"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div>
                      <p className="report-title">
                        {!empty(schoolName) ? schoolName : "NA"}
                      </p>
                      <p className="report-subtitle">
                        {!empty(address) ? address : "NA"}
                      </p>
                      <p className="report-subtitle fs-14">
                        {`TEL.: ${phoneNumber1}${
                          !empty(phoneNumber2) ? ", " + phoneNumber2 : ""
                        }${!empty(ownerPhone) ? ", " + ownerPhone : ""}`}
                      </p>
                      <p className="report-subtitle fs-14 mb-10">
                        {`Email: ${email}`}
                      </p>
                    </div>
                    <div style={{ width: 100, height: 100 }}>
                      {!empty(activeDetails) && !empty(activeDetails.avatar) ? (
                        <img
                          src={activeDetails.avatar}
                          alt="student"
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        <img
                          src={!empty(schoolAvatar) ? schoolAvatar : ""}
                          alt="non"
                          style={{ width: "100%", height: "100%" }}
                        />
                      )}
                    </div>
                  </div>
                  {/* end of report header */}

                  {/* report top section */}
                  <div className="report-details-header">
                    <p className="report-term-title">
                      {!empty(sessionData) && !empty(sessionData.title)
                        ? sessionData.title
                        : ""}{" "}
                      {!empty(termData) &&
                      !empty(termData.title) &&
                      isString(termData.title)
                        ? termData.title.toUpperCase()
                        : ""}{" "}
                      TERM STUDENT'S PERFORMANCE SHEET
                    </p>
                    <div className="report-detail-group">
                      <p className="report-detail-group-item">
                        <span className="report-detail-group-title">NAME:</span>
                        <span className="report-detail-group-value">
                          <NavLink
                            to={`/student/${studentId}/profile`}
                            style={{ textDecoration: "none", color: "#000000" }}
                          >
                            {!empty(activeDetails) &&
                            !empty(activeDetails.fullName)
                              ? _.toUpper(activeDetails.fullName)
                              : ""}
                          </NavLink>
                        </span>
                      </p>
                      <p className="report-detail-group-item">
                        <span className="report-detail-group-title">
                          GENDER:
                        </span>
                        <span className="report-detail-group-value">
                          {!empty(activeDetails) && !empty(activeDetails.gender)
                            ? _.toUpper(activeDetails.gender)
                            : "N/A"}
                        </span>
                      </p>
                      <p className="report-detail-group-item">
                        <span className="report-detail-group-title">
                          CLASS:
                        </span>
                        <span className="report-detail-group-value">
                          {!empty(classData) && !empty(classData.title)
                            ? _.toUpper(classData.title)
                            : ""}
                        </span>
                      </p>
                    </div>
                    <div className="report-detail-group">
                      <p className="report-detail-group-item">
                        <span className="report-detail-group-title">
                          ADMISSION NO.:
                        </span>
                        <span className="report-detail-group-value">
                          {!empty(activeDetails) && !empty(activeDetails.regNo)
                            ? _.toUpper(activeDetails.regNo)
                            : ""}
                        </span>
                      </p>
                      <p className="report-detail-group-item">
                        <span className="report-detail-group-title">
                          ROLL NO.:
                        </span>
                        <span className="report-detail-group-value">
                          {!empty(activeDetails) &&
                          !empty(activeDetails.rollNumber)
                            ? _.toUpper(activeDetails.rollNumber)
                            : "N/A"}
                        </span>
                      </p>
                    </div>

                    <div className="report-rating-box">
                      <table className="grade-rating-table">
                        <thead>
                          <tr>
                            <th>GRADE</th>
                            <th>A</th>
                            <th>B</th>
                            <th>C</th>
                            <th>D</th>
                            <th>E</th>
                            <th>F</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>NO</th>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.noOfAs)
                                ? _.toUpper(studentReport.noOfAs)
                                : 0}
                            </td>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.noOfBs)
                                ? _.toUpper(studentReport.noOfBs)
                                : 0}
                            </td>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.noOfCs)
                                ? _.toUpper(studentReport.noOfCs)
                                : 0}
                            </td>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.noOfDs)
                                ? _.toUpper(studentReport.noOfDs)
                                : 0}
                            </td>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.noOfEs)
                                ? _.toUpper(studentReport.noOfEs)
                                : 0}
                            </td>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.noOfFs)
                                ? _.toUpper(studentReport.noOfFs)
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan={6}>NUMBER OF SUBJECTS OFFERED</th>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.noOfSubjectsOffered)
                                ? studentReport.noOfSubjectsOffered
                                : "NA"}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="score-rating-table">
                        <tbody>
                          <tr>
                            <th>AVERAGE</th>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.average)
                                ? studentReport.average
                                : "NA"}
                            </td>
                          </tr>
                          <tr>
                            <th>GRADE</th>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.grade) &&
                              !empty(studentReport.remark)
                                ? studentReport.grade +
                                  " - " +
                                  _.toUpper(studentReport.remark)
                                : "NA"}
                            </td>
                          </tr>
                          <tr>
                            <th>POSITION</th>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.position)
                                ? _.toUpper(studentReport.position)
                                : "NA"}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="score-rating-table">
                        <tbody>
                          <tr>
                            <td>Number of Students in class</td>
                            <td>
                              {!empty(studentData) &&
                              !empty(studentData.numberOfStudents)
                                ? studentData.numberOfStudents
                                : "NA"}
                            </td>
                          </tr>
                          <tr>
                            <td>Marks Obtained</td>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.cumulativeScore)
                                ? studentReport.cumulativeScore
                                : "NA"}
                            </td>
                          </tr>
                          <tr>
                            <td>Marks Obtainable</td>
                            <td>
                              {!empty(studentReport) &&
                              !empty(studentReport.noOfSubjectsOffered)
                                ? studentReport.noOfSubjectsOffered * 100
                                : "NA"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* end of report top secit */}

                  <div className="report-containter">
                    <table className="report-sheet-area">
                      <thead>
                        <tr>
                          <th rowSpan={3}>COGNITIVE DOMAIN</th>
                          <th rowSpan={4}>
                            <span className="report-title-rotate">CODE</span>
                          </th>
                          <th rowSpan={2} colSpan={2}>
                            CA
                          </th>
                          <th rowSpan={3}>
                            <span className="report-title-rotate">EXAM</span>
                          </th>
                          <th rowSpan={3}>
                            <span className="report-title-rotate">TOTAL</span>
                          </th>
                          <th rowSpan={4}>
                            <span className="report-title-rotate">GRADE</span>
                          </th>
                          <th rowSpan={4}>
                            <span className="report-title-rotate">
                              POSITION
                            </span>
                          </th>
                          <th rowSpan={4}>
                            <span className="report-title-rotate">REMARKS</span>
                          </th>
                          <th rowSpan={4}>
                            <span className="report-title-rotate">
                              CLASS MIN.
                            </span>
                          </th>
                          <th rowSpan={4}>
                            <span className="report-title-rotate">
                              CLASS MAX.
                            </span>
                          </th>
                          <th rowSpan={4}>
                            <span className="report-title-rotate">
                              CLASS AVG.
                            </span>
                          </th>
                        </tr>
                        <tr></tr>
                        <tr>
                          <th>1st</th>
                          <th>2nd</th>
                        </tr>
                        <tr>
                          <th>SUBJECTS</th>
                          <th>20</th>
                          <th>20</th>
                          <th>60</th>
                          <th>100</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!empty(studentAssessment) ? (
                          studentAssessment.map((data) => {
                            return (
                              <tr
                                key={
                                  !empty(data) && !empty(data.subjectId)
                                    ? data.subjectId
                                    : ""
                                }
                              >
                                <td>
                                  {!empty(data) && !empty(data.subjectTitle)
                                    ? _.toUpper(data.subjectTitle)
                                    : ""}
                                </td>
                                <td>
                                  {!empty(data) && !empty(data.subjectCode)
                                    ? data.subjectCode
                                    : 0}
                                </td>
                                <td>
                                  {!empty(data) && !empty(data.ca1)
                                    ? data.ca1
                                    : 0}
                                </td>
                                <td>
                                  {!empty(data) && !empty(data.ca2)
                                    ? data.ca2
                                    : 0}
                                </td>
                                <td>
                                  {!empty(data) && !empty(data.exam)
                                    ? data.exam
                                    : 0}
                                </td>
                                <td>
                                  {!empty(data) && !empty(data.total)
                                    ? data.total
                                    : 0}
                                </td>
                                <td>
                                  {!empty(data) && !empty(data.grade)
                                    ? data.grade
                                    : ""}
                                </td>
                                <td>
                                  {!empty(data.position) ? data.position : "NA"}
                                </td>
                                <td>
                                  {!empty(data) && !empty(data.remark)
                                    ? _.toUpper(data.remark)
                                    : ""}
                                </td>
                                <td>
                                  {!empty(data) &&
                                  !isUndefined(data.classMinValue)
                                    ? data.classMinValue
                                    : ""}
                                </td>
                                <td>
                                  {!empty(data) &&
                                  !isUndefined(data.classMaxValue)
                                    ? data.classMaxValue
                                    : ""}
                                </td>
                                <td>
                                  {!empty(data) &&
                                  !isUndefined(data.classAverage)
                                    ? data.classAverage
                                    : ""}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr></tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="psycho_area">
                    {!empty(activeDetails) &&
                    !empty(activeDetails.psychomotor) ? (
                      <div className="pyschomotor_box">
                        <table className="psycho-table">
                          <thead>
                            <tr>
                              <th>Psychomotor</th>
                              <th>Rating</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isObject(activeDetails.psychomotor) &&
                              Object.entries(activeDetails.psychomotor).map(
                                ([key, value]) => (
                                  <tr key={key}>
                                    <td>
                                      {!empty(key) ? toNormalCase(key) : ""}
                                    </td>
                                    <td>{!empty(value) ? value : ""}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                    {!empty(activeDetails) &&
                    !empty(activeDetails.affectiveDomain) ? (
                      <div className="affective_domain_box">
                        <table className="psycho-table">
                          <thead>
                            <tr>
                              <th>Affective Domain</th>
                              <th>Rating</th>
                            </tr>
                          </thead>
                          <tbody>
                            {isObject(activeDetails.affectiveDomain) &&
                              Object.entries(activeDetails.affectiveDomain).map(
                                ([key, value]) => (
                                  <tr key={key}>
                                    <td>
                                      {!empty(key) ? toNormalCase(key) : ""}
                                    </td>
                                    <td>{!empty(value) ? value : ""}</td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                    {!empty(activeDetails) &&
                    (!empty(activeDetails.affectiveDomain) ||
                      !empty(activeDetails.psychomotor)) &&
                    !empty(ratingIndices) &&
                    isArray(ratingIndices) ? (
                      <div className="mt-10 rating_indicators">
                        <strong>Rating Indicators</strong>
                        <ul style={{ listStyle: "none" }}>
                          {ratingIndices.map((item, index) => {
                            const value =
                              !empty(item) && !empty(item.value)
                                ? item.value
                                : "";
                            const description =
                              !empty(item) && !empty(item.description)
                                ? item.description
                                : "";
                            return (
                              <li className="mt-5" key={index}>
                                <strong>{value}</strong> - {description}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                    {!empty(activeDetails) &&
                    (!empty(activeDetails.affectiveDomain) ||
                      !empty(activeDetails.psychomotor)) &&
                    !empty(ratingIndices) &&
                    isArray(ratingIndices) ? (
                      <div
                        style={{
                          width: "50%",
                          height: 150,
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: 10,
                        }}
                      >
                        <Barchart data={chartData} />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: 220,
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: 10,
                        }}
                      >
                        <Barchart data={chartData} />
                      </div>
                    )}
                  </div>

                  <div className="report-summary-sheet">
                    <table className="report-summary-table">
                      <tbody>
                        <tr>
                          <td>
                            <strong>Remarks from teacher</strong>
                          </td>
                          <td colSpan={3}>
                            {!empty(studentReport) &&
                            !empty(studentReport.comment)
                              ? studentReport.comment
                              : !empty(studentReport) &&
                                !empty(studentReport.teachersComment)
                              ? studentReport.teachersComment
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Teacher's Name</td>
                          <td colSpan={3}>{classTeacherName}</td>
                        </tr>
                        <tr>
                          <td>Principal's Remark</td>
                          <td colSpan={3}>
                            {!empty(studentReport) &&
                            !empty(studentReport.principalComments)
                              ? studentReport.principalComments
                              : "NA"}
                          </td>
                        </tr>
                        <tr>
                          <td>Director's Name</td>
                          <td>Mr. Ukoha Orji Okoro</td>
                          <td colSpan={2}>
                            Sign: <em>Management</em>
                          </td>
                        </tr>
                        <tr>
                          <td>Next Term Begins</td>
                          <td>{!empty(openingDate) ? openingDate : "NA"}</td>
                          <td>Date: </td>
                          <td>{!empty(closingDate) ? closingDate : "NA"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="mt-50 assessment_not_paid">
                  <FaExclamationTriangle color="red" size={25} />
                  <p className="mt-10 fs-18 mb-5">
                    <strong>Result Sheet Unavailable</strong>
                  </p>
                  <p>This student has an outstanding portal levy</p>
                  <div>
                    <ButtonIcon
                      height={45}
                      color={colors.primary}
                      backgroundColor="transparent"
                      width={220}
                      fontWeight="bold"
                      borderColor="transparent"
                      buttonText="Active Student"
                      onClick={() => setActivationModalOpen(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={activationModalOpen}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={activateStudentDialogFooter}
        onHide={hideActivationModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <>
              <p>
                Are you sure you want to <strong>activate</strong> this student?{" "}
                <br />
                <br />
                By clicking "Yes", you are confirming that{" "}
                <strong>
                  {!empty(studentData) && !empty(studentData.fullName)
                    ? studentData.fullName
                    : ""}
                </strong>{" "}
                made portal payment for this term.
                <br />
                <br />
                <em style={{ color: colors.danger }}>
                  <strong>Note:</strong> This action cannot be undone.
                </em>
              </p>
            </>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default DisplayReportSheet;
