import { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import { FaUserCheck, FaUserTie, FaUserTimes } from "react-icons/fa";

// api
import attendanceApi from "../../api/Attendance";

//css
import "../dashboard/Dashboard.css";

//components
import Card from "../../components/card/Card";
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import Barchart from "../../components/chart/barchart/Barchart";
import StatCard from "../../components/statcard/StatCard";

//data
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import BoxLoading from "../../components/skeleton/BoxLoading";
import BarChartLoading from "../../components/skeleton/BarChartLoading";
import colors from "../../config/colors";
import AttendanceWrapper from "../../components/appWrapper/AttendanceWrapper";
import { Form, Formik } from "formik";
import InputField from "../../components/form/InputField";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";

const validationSchema = Yup.object().shape({
  date: Yup.string().optional(),
});

const initialValues = {
  date: "",
};
const AttendanceDashboard = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [noOfStudentsPresent, setNumberOfStudentsPresent] = useState("0/0");
  const [noOfStudentsAbsent, setNumberOfStudentsAbsent] = useState("0/0");
  const [noOfStaffAbsent, setNumberOfStaffAbsent] = useState("0/0");
  const [noOfStaffPresent, setNumberOfStaffPresent] = useState("0/0");
  const [percentageOfStaffAbsent, setPercentageOfStaffAbsent] = useState(0);
  const [percentageOfStaffPresent, setPercentageOfStaffPresent] = useState(0);
  const [percentageOfStudentsAbsent, setPercentageOfStudentsAbsent] =
    useState(0);
  const [percentageOfStudentsPresent, setPercentageOfStudentsPresent] =
    useState(0);

  // stat status
  const [numberOfStudentsAbsentLoading, setNumberOfStudentsAbsentLoading] =
    useState(false);
  const [numberOfStudentsPresentLoading, setNumberOfStudentsPresentLoading] =
    useState(false);
  const [numberOfStaffAbsentLoading, setNumberOfStaffAbsentLoading] =
    useState(false);
  const [numberOfStaffPresentLoading, setNumberOfStaffPresentLoading] =
    useState(false);
  const [assessmentDataLoading, setAssessmentDataLoading] = useState(false);

  const assessmentData = [];
  const [chartData, setChartData] = useState({
    labels: assessmentData.map((data) => {
      const classTitle = !empty(data) && !empty(data.title) ? data.title : "";
      return classTitle;
    }),
    datasets: [
      {
        label: "Class Performance",
        data: assessmentData.map((data) => {
          const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
          return sum;
        }),
        backgroundColor: colors.charcoal,
        borderRadius: 12,
      },
    ],
  });
  const toastTR = useRef(null);

  useEffect(() => {
    getNoOfStudentsPresent();
    getNumberOfStudentsAbsent();
    getNumberOfStaffAbsent();
    getNumberOfStaffPresent();
    getClassAttendanceStat();
  }, [user]);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getNoOfStudentsPresent = async (params = {}) => {
    setNumberOfStudentsPresentLoading(true);
    params.type = "CHECK-IN";
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await attendanceApi.getNoOfStudentsStat(
        schoolId,
        params
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Failed to fetch number of students!"
            : "Failed to fetch number of students"
        );
      }

      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const statValue =
        !empty(data) && !empty(data.statValue) ? data.statValue : 0;
      const userCount =
        !empty(data) &&
        !empty(data.userCount) &&
        !isNaN(parseInt(data.userCount))
          ? data.userCount
          : 0;
      const percentage =
        !empty(data) &&
        !empty(data.percentage) &&
        !isNaN(parseInt(data.percentage))
          ? data.percentage
          : 0;

      setNumberOfStudentsPresent(statValue + "/" + userCount);
      return setPercentageOfStudentsPresent(percentage);
    } catch (error) {
      return responseDailog(
        "error",
        "Students Present",
        "Something went wrong."
      );
    } finally {
      setNumberOfStudentsPresentLoading(false);
    }
  };

  const getNumberOfStudentsAbsent = async (params = {}) => {
    setNumberOfStudentsAbsentLoading(true);
    params.type = "CHECK-OUT";
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await attendanceApi.getNoOfStudentsStat(
        schoolId,
        params
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        return;
      }

      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const statValue =
        !empty(data) && !empty(data.statValue) ? data.statValue : 0;
      const userCount =
        !empty(data) &&
        !empty(data.userCount) &&
        !isNaN(parseInt(data.userCount))
          ? data.userCount
          : 0;
      const percentage =
        !empty(data) &&
        !empty(data.percentage) &&
        !isNaN(parseInt(data.percentage))
          ? data.percentage
          : 0;

      setNumberOfStudentsAbsent(statValue + "/" + userCount);
      return setPercentageOfStudentsAbsent(percentage);
    } catch (error) {
      responseDailog("error", "Student Absent", "Something went wrong.");
    } finally {
      setNumberOfStudentsAbsentLoading(false);
    }
  };

  const getNumberOfStaffAbsent = async (params = {}) => {
    setNumberOfStaffAbsentLoading(true);
    params.type = "CHECK-OUT";
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await attendanceApi.getNoOfStaffStat(schoolId, params);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        return;
      }

      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const statValue =
        !empty(data) && !empty(data.statValue) ? data.statValue : 0;
      const userCount =
        !empty(data) &&
        !empty(data.userCount) &&
        !isNaN(parseInt(data.userCount))
          ? data.userCount
          : 0;
      const percentage =
        !empty(data) &&
        !empty(data.percentage) &&
        !isNaN(parseInt(data.percentage))
          ? data.percentage
          : 0;

      setNumberOfStaffAbsent(statValue + "/" + userCount);
      return setPercentageOfStaffAbsent(percentage);
    } catch (error) {
      return responseDailog("error", "Staff Absent", "Something went wrong.");
    } finally {
      return setNumberOfStaffAbsentLoading(false);
    }
  };

  const getNumberOfStaffPresent = async (params = {}) => {
    setNumberOfStaffPresentLoading(true);
    params.type = "CHECK-IN";
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await attendanceApi.getNoOfStaffStat(schoolId, params);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        return;
      }

      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const statValue =
        !empty(data) && !empty(data.statValue) ? data.statValue : 0;
      const userCount =
        !empty(data) && !empty(data.userCount) ? data.userCount : 0;
      const percentage =
        !empty(data) && !empty(data.percentage) ? data.percentage : 0;

      setNumberOfStaffPresent(statValue + "/" + userCount);
      return setPercentageOfStaffPresent(percentage);
    } catch (error) {
      return responseDailog("error", "Staff Present", "Something went wrong.");
    } finally {
      return setNumberOfStaffPresentLoading(false);
    }
  };

  const getClassAttendanceStat = async (params = {}) => {
    setAssessmentDataLoading(true);
    params.type = "CHECK-IN";
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await attendanceApi.getClassAssessmentStat(
        schoolId,
        params
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.success
      ) {
        return;
      }
      const attendanceData =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : [];
      setChartData({
        labels: attendanceData.map((data) => {
          const classTitle =
            !empty(data) && !empty(data.classTitle) ? data.classTitle : "";
          return classTitle;
        }),
        datasets: [
          {
            label: `Students Present`,
            data: attendanceData.map((data) => {
              const studentsPresent =
                !empty(data) && !empty(data.studentsPresent)
                  ? data.studentsPresent
                  : 0;
              return studentsPresent;
            }),
            backgroundColor: colors.primary,
            borderRadius: 12,
          },
          {
            label: `Students Absent`,
            data: attendanceData.map((data) => {
              const studentsPresent =
                !empty(data) && !empty(data.studentsPresent)
                  ? data.studentsPresent
                  : 0;
              const noOfStudents =
                !empty(data) && !empty(data.noOfStudents)
                  ? data.noOfStudents
                  : 0;
              return noOfStudents - studentsPresent;
            }),
            backgroundColor: colors.orange,
            borderRadius: 12,
          },
        ],
      });
    } catch (error) {
      responseDailog("error", "Attendance Chart Data", "Something went wrong.");
    } finally {
      setAssessmentDataLoading(false);
    }
  };

  const searchConstraints = (values) => {
    if (empty(values)) {
      responseDailog(
        "error",
        "Something went wrong.",
        "Please refresh page or try again later."
      );
    }
    const date = !empty(values.start_date) ? values.start_date : "";
    const payload = { date };

    getNoOfStudentsPresent(payload);
    getNumberOfStaffAbsent(payload);
    getNumberOfStaffPresent(payload);
    getNumberOfStudentsAbsent(payload);
    getClassAttendanceStat(payload);
  };

  return (
    <>
      <AttendanceWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Attendance" />
          {/* end menu header */}
          <div className="mb-20" style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={searchConstraints}
            >
              <Form>
                <div className="attendance-dashboard-filter">
                  <InputField
                    placeholder="Enter Start Date"
                    name="start_date"
                    height={50}
                    type="date"
                    labelTitle="Start Date"
                  />
                  <ButtonIcon
                    height={45}
                    marginTop={6}
                    color="#ffffff"
                    backgroundColor="#633ccd"
                    width={280}
                    borderColor="#633ccd"
                    buttonText="Generate"
                    type="submit"
                  />
                </div>
              </Form>
            </Formik>
          </div>
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div className="w-100pc">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    !numberOfStudentsPresentLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Students Present"
                        entry={noOfStudentsPresent}
                        icon={
                          <FaUserCheck size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !numberOfStudentsAbsentLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Students Absent"
                        entry={noOfStudentsAbsent}
                        icon={
                          <FaUserTimes size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !numberOfStaffPresentLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Staff Present"
                        entry={noOfStaffPresent}
                        icon={
                          <FaUserTie size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !numberOfStaffAbsentLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Staff Absent"
                        entry={noOfStaffAbsent}
                        icon={
                          <FaUserTimes size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              <div className="top_stat_box">
                <Card
                  children={
                    !numberOfStudentsPresentLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Percentage of Students Present"
                        entry={percentageOfStudentsPresent + "%"}
                        icon={
                          <FaUserCheck size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !numberOfStudentsAbsentLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Percentage of Students Absent"
                        entry={percentageOfStudentsAbsent + "%"}
                        icon={
                          <FaUserTimes size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !numberOfStaffPresentLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Percentage of Staff Present"
                        entry={percentageOfStaffPresent + "%"}
                        icon={
                          <FaUserTie size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !numberOfStaffAbsentLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor={colors.primary}
                        title="Percentage of Staff Absent"
                        entry={percentageOfStaffAbsent + "%"}
                        icon={
                          <FaUserTimes size={14} className="stat_card_icon" />
                        }
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader title="Attendance by Class" />
                      {!assessmentDataLoading ? (
                        <Barchart data={chartData} />
                      ) : (
                        <BarChartLoading />
                      )}
                    </>
                  }
                  addStyle="barchart"
                />
              </div>
              {/* end chart */}
            </div>
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AttendanceWrapper>
    </>
  );
};

export default AttendanceDashboard;
