import React from "react";
import { Skeleton } from "primereact/skeleton";

export default function TableLoading({ rows = 50, cols = 4 }) {
  return (
    <div className="app-skeleton-container border-round border-1 surface-border p-4 surface-card">
      {/* Table Header */}
      <div className="flex justify-content-between mt-3 mb-10">
        {Array(cols)
          .fill(0)
          .map((_, colIndex) => (
            <Skeleton
              key={colIndex}
              width={`${100 / cols}%`}
              height="2rem"
              className={colIndex < cols - 1 ? "mr-10" : ""}
            ></Skeleton>
          ))}
      </div>

      {/* Table Rows */}
      {Array(rows)
        .fill(0)
        .map((_, rowIndex) => (
          <div key={rowIndex} className="flex justify-content-between mt-3">
            {Array(cols)
              .fill(0)
              .map((_, colIndex) => (
                <Skeleton
                  key={colIndex}
                  width={`${100 / cols}%`}
                  height="2rem"
                  className={colIndex < cols - 1 ? "mr-10 mb-10" : "mb-10"}
                ></Skeleton>
              ))}
          </div>
        ))}
    </div>
  );
}
