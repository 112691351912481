import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";
import staffApi from "../../api/Staff";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { FaMinus, FaPlus } from "react-icons/fa";
import TableLoading from "../../components/skeleton/TableLoading";

const AssignClassToStaff = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [subjectData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [staffClasses, setStaffClasses] = useState([]);
  const [selectedStaffIds, setSelectedClassIds] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [staffTitle, setStaffTitle] = useState("");
  const [checked, setChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const { staffId } = useParams();
  if (!staffId) {
    navigate("/404");
  }

  useEffect(() => {
    // fetch subject
    getStaffDetails();
  }, [user]);

  useEffect(() => {
    if (empty(selectedStaffIds)) {
      setShowAddButton(false);
    } else {
      setShowAddButton(true);
    }
  }, [selectedStaffIds]);

  const getClasses = async (staffClasses) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        setClassData([]);
      } else {
        const results =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        let updatedClassIds = [];
        let updatedResults = [];
        for (let i = 0; i < results.length; i++) {
          const subjectId =
            !empty(results[i]) && !empty(results[i]._id) ? results[i]._id : "";
          updatedClassIds.push(subjectId);
          results[i].added = staffClasses.includes(subjectId);
          updatedResults.push(results[i]);
        }
        setClassIds(updatedClassIds);
        setClassData(updatedResults);
      }

      if (location.state) {
        const { subjectAdded = false, subjectUpdated = false } = location.state;
        if (subjectAdded || subjectUpdated) {
          const actionType = subjectAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Subject ${actionType} successfully!`
          );
          navigate(location.pathname, {
            replace: true,
            state: { subjectAdded: false, subjectUpdated: false },
          });
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    } finally {
      setIsLoading(false);
    }
  };

  const getStaffDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getSingleStaff(staffId, schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.response
      ) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to fetch staff details"
        );
      } else {
        const staffTitle =
          !empty(response_data.response) && !empty(response_data.response.title)
            ? response_data.response.title
            : "";
        const staffLastName =
          !empty(response_data.response) &&
          !empty(response_data.response.lastName)
            ? response_data.response.lastName
            : "";
        const staffFirstName =
          !empty(response_data.response) &&
          !empty(response_data.response.firstName)
            ? response_data.response.firstName
            : "";
        const staffClasses =
          !empty(response_data.response) &&
          !empty(response_data.response.classes)
            ? response_data.response.classes
            : [];
        setStaffClasses(staffClasses);
        setStaffTitle(staffTitle);
        setFirstName(staffFirstName);
        setLastName(staffLastName);

        getClasses(staffClasses);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    }
  };

  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const updateSelectedClassIds = (
    subjectId,
    sideAction = false,
    add = false
  ) => {
    try {
      let updatedSubjects = [];
      if (sideAction) {
        let action = "add";
        if (add) {
          updatedSubjects = [...staffClasses, subjectId];
        } else {
          action = "remove";
          const index = staffClasses.indexOf(subjectId);
          if (index > -1) {
            updatedSubjects = staffClasses.filter((id) => id !== subjectId);
          }
        }
        toggleStaffClasses(action, updatedSubjects, sideAction);
      } else {
        const index = selectedStaffIds.indexOf(subjectId);
        if (index > -1) {
          updatedSubjects = selectedStaffIds.filter((id) => id !== subjectId);
        } else {
          updatedSubjects = [...selectedStaffIds, subjectId];
        }
        setSelectedClassIds(updatedSubjects);
        if (updatedSubjects.length !== classIds.length) {
          setChecked(false);
        } else {
          setChecked(true);
        }
      }
    } catch (error) {}
  };

  const toggleStaffClasses = async (
    action = "add",
    updatedSubjects = [],
    sideAction = false
  ) => {
    try {
      if (!actionLoading) setActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      let dataToUpdate = [];
      if (!sideAction) {
        if (action === "add") {
          if (selectedStaffIds.every((item) => staffClasses.includes(item))) {
            dataToUpdate = selectedStaffIds;
          } else {
            dataToUpdate = [...new Set([...staffClasses, ...selectedStaffIds])];
          }
        } else {
          const filteredArray = staffClasses.filter(
            (item) => !selectedStaffIds.includes(item)
          );
          dataToUpdate = filteredArray;
        }
      } else {
        dataToUpdate = updatedSubjects;
      }
      const response = await classApi.assignClassesToStaff(
        dataToUpdate,
        staffId,
        schoolId
      );
      const response_data = prepareResponseData(response);
      setSelectedClassIds([]);
      setChecked(false);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Something went wrong",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Operation failed"
        );
      } else {
        await getStaffDetails();
        responseDailog("success", "Success", "Operation successful");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setActionLoading(false);
    }
  };

  const actionBodyTemplate = (rowData) => {
    const added =
      !empty(rowData) && !empty(rowData.added) && rowData.added ? true : false;
    const subjectId =
      !empty(rowData) && !empty(rowData._id) && rowData._id
        ? rowData._id
        : false;
    return (
      <Button
        icon={added ? "pi pi-minus" : "pi pi-plus"}
        style={{
          backgroundColor: "transparent",
          color: added ? "#e65061" : "#389d17",
          borderColor: added ? "#e65061" : "#389d17",
          borderWidth: 1,
          borderRadius: "50%",
        }}
        onClick={() => {
          updateSelectedClassIds(subjectId, true, added ? false : true);
        }}
      />
    );
  };

  const checkBoxBodyTemplate = (rowData) => {
    const subjectId = !empty(rowData) && !empty(rowData._id) ? rowData._id : "";
    return (
      <Checkbox
        checked={selectedStaffIds.includes(subjectId)}
        onChange={() => updateSelectedClassIds(subjectId)}
      />
    );
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            <MainHeader
              children={
                showAddButton ? (
                  <>
                    <ButtonIcon
                      borderColor="#389d17"
                      backgroundColor="#389d17"
                      color="#ffffff"
                      icon={<FaPlus style={{ fontSize: 12 }} />}
                      buttonText="Add"
                      marginRight={1}
                      width={70}
                      height={30}
                      onClick={() => toggleStaffClasses("add")}
                    />
                    <ButtonIcon
                      borderColor="red"
                      backgroundColor="red"
                      color="#ffffff"
                      icon={<FaMinus style={{ fontSize: 12 }} />}
                      buttonText="Remove"
                      width={100}
                      height={30}
                      onClick={() => toggleStaffClasses("remove")}
                    />
                  </>
                ) : (
                  ""
                )
              }
              redirect={false}
              title={`Add/Remove Subject(s) from ${staffTitle} ${lastName} ${firstName}`}
            />
            <div style={{ marginBottom: 10 }}>
              <span style={{ marginRight: 40 }}>
                <strong>No. of staff assigned classes: </strong>
                {staffClasses.length}
              </span>
              <span>
                <strong>No. of selected classes: </strong>
                {selectedStaffIds.length}
              </span>
            </div>
            {!isLoading ? (
              <DataTable value={subjectData} tableStyle={{ minWidth: "30rem" }}>
                <Column
                  field="_id"
                  header={
                    <Checkbox
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.checked);
                        if (e.checked) {
                          setShowAddButton(true);
                          setSelectedClassIds(classIds);
                        } else {
                          setSelectedClassIds([]);
                        }
                      }}
                    />
                  }
                  body={checkBoxBodyTemplate}
                />
                <Column field="title" header="Title" />
                <Column
                  field="added"
                  header="Action"
                  body={actionBodyTemplate}
                />
              </DataTable>
            ) : (
              <TableLoading rows={20} cols={3} />
            )}
          </div>
        </main>
        {actionLoading && <FullPageLoader visible={actionLoading} />}
      </AppWrapper>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default AssignClassToStaff;
