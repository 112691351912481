import { useContext, useState } from "react";
import {
  FaBars,
  FaRegBell,
  FaCaretDown,
  FaRegEnvelope,
  FaMoneyBill,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty, toCurrency } from "../../Utilities/utils";

// css
import "./Navbar.css";

// images
import appLogo from "../../assets/logo/blue-logo.png";
import colors from "../../config/colors";
import Card from "../card/Card";

const Navbar = ({ toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const name = !empty(user) && !empty(user.name) ? user.name : "NA";
  const creditBalance =
    !empty(user) && !empty(user.credits) ? user.credits : "NA";
  const schoolAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";

  const openProfileMenu = () => {
    setShowAvatarMenu(!showAvatarMenu);
  };

  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={toggleSidebar}>
        <FaBars className="menu_bar" />
      </div>
      <div className="navbar_left">
        <span style={{ fontWeight: "bold", fontSize: 20 }}>{name}</span>
      </div>
      <div className="navbar_right">
        <div className="credit-nav-box">
          Credit: &nbsp;{" "}
          <FaMoneyBill
            style={{ marginTop: 2 }}
            size={14}
            color={colors.primary}
          />
          &nbsp;&nbsp;<strong>{toCurrency(creditBalance)}</strong>
        </div>
        <NavLink
          to="/inbox"
          style={{ textDecoration: "none" }}
          className="visible-lg"
        >
          <div className="icon_box">
            <FaRegEnvelope />
          </div>
        </NavLink>
        <NavLink
          to="/notification"
          style={{ textDecoration: "none" }}
          className="visible-lg"
        >
          <div className="icon_box">
            <FaRegBell />
          </div>
        </NavLink>
        <div className="navbar_avatar_container">
          <div className="navbar_avatar_box" onClick={() => openProfileMenu()}>
            <NavLink to="/profile" style={{ textDecoration: "none" }}>
              <img
                className="navbar_avatar_box_img"
                src={!empty(schoolAvatar) ? schoolAvatar : appLogo}
                alt="avatar"
              />
            </NavLink>
            <span className="navbar_user_name">{name}</span>
            <span>
              <FaCaretDown />
            </span>
          </div>
          <Card
            addStyle={`avatar_dropdown_menu ${
              showAvatarMenu ? "flex" : "hide"
            }`}
          >
            <ul className="avatar_main_menu">
              <li>
                <NavLink
                  to="/settings/profile"
                  style={{ textDecoration: "none", color: colors.primary }}
                >
                  Account
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  style={{ textDecoration: "none", color: colors.primary }}
                >
                  Message
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="#"
                  style={{ textDecoration: "none", color: colors.primary }}
                >
                  Notifications
                </NavLink>
              </li>
            </ul>
            <hr />
            <ul className="avatar_settings_menu">
              <li>
                <NavLink
                  to="/settings"
                  style={{ textDecoration: "none", color: colors.primary }}
                >
                  Settings
                </NavLink>
              </li>
              <hr />
              <li
                style={{
                  marginTop: 10,
                }}
              >
                <NavLink
                  to="/logout"
                  style={{
                    textDecoration: "none",
                    color: colors.danger,
                  }}
                >
                  Log Out
                </NavLink>
              </li>
            </ul>
          </Card>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
