import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import subjectApi from "../../api/Subject";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import SubjectTable from "../../components/tables/primeTable/subject/SubjectTable";
import TableLoading from "../../components/skeleton/TableLoading";
import { FaPlus } from "react-icons/fa";

const Subjects = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [subjectData, setSubjectData] = useState([]);
  const [subjectModalVisibility, setSubjectModalVisibility] = useState(false);
  const [selectedSubjectDetail, setSelectedSubjectDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [modalType, setModalType] = useState("update");

  useEffect(() => {
    if (!empty(location) && !empty(location.state)) {
      const paramState = !empty(location.state) ? location.state : {};
      let subjectAdded = !empty(paramState.subjectAdded)
        ? paramState.subjectAdded
        : false;
      let subjectUpdated = !empty(paramState.subjectUpdated)
        ? paramState.subjectUpdated
        : false;
      if (subjectAdded === true || subjectUpdated === true) {
        const actionType = subjectAdded
          ? "added"
          : subjectUpdated
          ? "updated"
          : "";
        paramState.subjectAdded = false;
        paramState.subjectUpdated = false;
        paramState.studentDeleted = false;
        responseDailog(
          "success",
          "Success",
          `Subject ${actionType} successfully!`
        );
        if (!empty(location) && !empty(location.state)) {
          navigate(null, {
            replace: true,
            state: {
              subjectAdded: false,
              subjectUpdated: false,
            },
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    // fetch subject
    try {
      getSubjects();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load subjects. Please try again later."
      );
    } finally {
      navigate(null, {
        replace: true,
        state: { subjectAdded: false, subjectUpdated: false },
      });
    }
  }, [user]);

  // function to get subjects
  const getSubjects = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setSubjectData([]);
      } else {
        return setSubjectData(
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : []
        );
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  //function to toggle subject visibility
  const toggleSubjectVisiblity = async () => {
    try {
      if (subjectModalVisibility) setSubjectModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const active =
        !empty(selectedSubjectDetail) && !empty(selectedSubjectDetail.active)
          ? "Yes"
          : "No";
      const subjectId =
        !empty(selectedSubjectDetail) && !empty(selectedSubjectDetail._id)
          ? selectedSubjectDetail._id
          : "";
      const response = await subjectApi.toggleSubjectVisibility(
        subjectId,
        active,
        schoolId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to ${
                !empty(selectedSubjectDetail) &&
                !empty(selectedSubjectDetail.active) &&
                selectedSubjectDetail.active === false
                  ? "enable"
                  : "disable"
              } subject with title, ${
                !empty(selectedSubjectDetail.title)
                  ? selectedSubjectDetail.title
                  : ""
              }`;
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Subject, ${
          !empty(selectedSubjectDetail.title) ? selectedSubjectDetail.title : ""
        }, was ${
          !empty(selectedSubjectDetail) &&
          !empty(selectedSubjectDetail.active) &&
          selectedSubjectDetail.active === true
            ? "disabled"
            : "enabled"
        } successfully!`
      );
      return getSubjects();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  // delete subject
  const deleteSubject = async () => {
    try {
      if (subjectModalVisibility) setSubjectModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const subjectId =
        !empty(selectedSubjectDetail) && !empty(selectedSubjectDetail._id)
          ? selectedSubjectDetail._id
          : "";
      const response = await subjectApi.deleteSubject(subjectId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to delete subject";
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Subject, ${
          !empty(selectedSubjectDetail.title) ? selectedSubjectDetail.title : ""
        }, was deleted successfully!`
      );
      return getSubjects();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to show confirm modal
  const showDisableConfirm = (data) => {
    setModalType("update");
    setSelectedSubjectDetail(data);
    setSubjectModalVisibility(true);
  };

  //function to hide confirm modal
  const hideSubjectModal = () => {
    setSubjectModalVisibility(false);
  };

  //go to update subject page
  const gotToUpdateSubject = (data) => {
    const subjectId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/subject/${subjectId}/update`);
  };

  // delete subject
  const showDeleteSubjectModal = (data) => {
    setModalType("delete");
    setSelectedSubjectDetail(data);
    setSubjectModalVisibility(true);
  };

  const subjectModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideSubjectModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={() =>
          modalType === "update" ? toggleSubjectVisiblity() : deleteSubject()
        }
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button
              buttonText="Add Subject"
              icon={<FaPlus />}
              iconMarginRight={8}
              link="/subject/add"
              title="Subject"
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <SubjectTable
                subjects={subjectData}
                confirmDisableSelected={showDisableConfirm}
                goToUpdateSubjectScreen={gotToUpdateSubject}
                goToDeleteSubjectScreen={showDeleteSubjectModal}
              />
            ) : (
              <TableLoading cols={4} rows={20} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={subjectModalVisibility}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={subjectModalFooter}
        onHide={hideSubjectModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to{" "}
              {modalType === "update" ? (
                !empty(selectedSubjectDetail) &&
                !empty(selectedSubjectDetail.active) &&
                selectedSubjectDetail.active ? (
                  <span style={{ color: "red" }}>
                    <strong>disable</strong>
                  </span>
                ) : (
                  <span style={{ color: "green" }}>
                    <strong>enable</strong>
                  </span>
                )
              ) : (
                <span style={{ color: "red" }}>
                  <strong>delete</strong>
                </span>
              )}{" "}
              the selected subject -{" "}
              <strong>
                {!empty(selectedSubjectDetail) &&
                !empty(selectedSubjectDetail.title)
                  ? selectedSubjectDetail.title
                  : ""}
              </strong>
              {selectedSubjectDetail && (
                <b>
                  {!empty(selectedSubjectDetail.name)
                    ? selectedSubjectDetail.name
                    : ""}
                </b>
              )}
              ?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Subjects;
