import { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "./Students.css";

// api
import studentApi from "../../api/Student";
import classApi from "../../api/Classes";

// data
import { yesNo } from "../../data/yesNo";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import Underline from "../../components/others/Underline";
import FullPageLoader from "../../components/loader/FullPageLoader";
import InputField from "../../components/form/InputField";
import { genderData } from "../../data/genderData";
import { NigeriaStatesAndLGA } from "../../data/NigeriaStatesAndLGA";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required(required),
  last_name: Yup.string().required(required),
  middle_name: Yup.string().optional(),
  gender: Yup.string().required(required),
  address: Yup.string().optional(),
  entry_class: Yup.string().required(required),
  previous_school: Yup.string().optional(),
  state_of_residence: Yup.string().required(required),
  lga_of_residence: Yup.string().required(required),
  state_of_origin: Yup.string().optional(),
  lga_of_origin: Yup.string().optional(),
  nationality: Yup.string().optional(),
  email: Yup.string().email("Invalid email").optional(),
  phone_number: Yup.string().optional(),
  active: Yup.string().required(required),
  religion: Yup.string().optional(),
  date_of_birth: Yup.string().optional(),
});

const AddUpdateStudent = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
  const [classes, setClasses] = useState([]);
  const [hasPaid, setHasPaid] = useState("No");
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    gender: "",
    address: "",
    entry_class: "",
    previous_school: "",
    state_of_residence: "",
    lga_of_residence: "",
    state_of_origin: "",
    lga_of_origin: "",
    nationality: "",
    email: "",
    phone_number: "",
    religion: "",
    date_of_birth: "",
    active: "",
  });
  const [selectedStateLGA, setSelectedStateLGA] = useState([]);
  const [selectedStateLGAResidence, setSelectedStateLGAResidence] = useState(
    []
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { studentId } = useParams();
  const toastTR = useRef(null);

  useEffect(() => {
    if (studentId) {
      getStudentDetails();
    }
  }, [studentId]);

  useEffect(() => {
    getClasses();
  }, []);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getStudentDetails = async () => {
    setIsLoading(true);
    try {
      const response = await studentApi.getSingleStudent(studentId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const userData = response_data.response;
        const hasPaid = !empty(userData.paid) ? userData.paid : "No";
        setHasPaid(hasPaid);
        setInitialValues({
          first_name: !empty(userData.firstName) ? userData.firstName : "",
          last_name: !empty(userData.lastName) ? userData.lastName : "",
          middle_name: !empty(userData.middleName) ? userData.middleName : "",
          gender: !empty(userData.gender) ? userData.gender : "",
          address: !empty(userData.address) ? userData.address : "",
          entry_class: !empty(userData.entryClassId)
            ? userData.entryClassId
            : "",
          previous_school: !empty(userData.previousSchool)
            ? userData.previousSchool
            : "",
          state_of_residence: !empty(userData.stateOfResidence)
            ? userData.stateOfResidence
            : "",
          lga_of_residence: !empty(userData.lgaOfResidence)
            ? userData.lgaOfResidence
            : "",
          state_of_origin: !empty(userData.stateOfOrigin)
            ? userData.stateOfOrigin
            : "",
          lga_of_origin: !empty(userData.lgaOfOrigin)
            ? userData.lgaOfOrigin
            : "",
          nationality: !empty(userData.nationality) ? userData.nationality : "",
          email: !empty(userData.email) ? userData.email : "",
          phone_number: !empty(userData.phoneNumber)
            ? userData.phoneNumber
            : "",
          religion: !empty(userData.religion) ? userData.religion : "",
          date_of_birth: !empty(userData.dateOfBirth)
            ? userData.dateOfBirth
            : "",
          active:
            !empty(userData.active) && userData.active === true ? "Yes" : "No",
        });

        const stateOfOrigin = !empty(userData.stateOfOrigin)
          ? userData.stateOfOrigin
          : "";
        const stateOfResidence = !empty(userData.stateOfResidence)
          ? userData.stateOfResidence
          : "";
        const stateOfOiginLGAs =
          NigeriaStatesAndLGA.find(
            (stateObj) =>
              !empty(stateObj) &&
              !empty(stateObj.title) &&
              stateObj.title === stateOfOrigin
          )?.lgas || [];
        setSelectedStateLGA(stateOfOiginLGAs);
        const stateOfResidenceLGAs =
          NigeriaStatesAndLGA.find(
            (stateObj) =>
              !empty(stateObj) &&
              !empty(stateObj.title) &&
              stateObj.title === stateOfResidence
          )?.lgas || [];
        setSelectedStateLGAResidence(stateOfResidenceLGAs);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getClasses = async () => {
    const response = await classApi.getClasses(schoolId);
    const response_data = prepareResponseData(response);
    if (empty(response_data) || !response_data.success) {
      return responseDailog(
        "error",
        "Error Alert",
        !empty(response_data.response)
          ? typeof response_data.response === "string"
            ? response_data.response
            : "Something went wrong!"
          : "Something went wrong"
      );
    }

    setClasses(
      !empty(response_data) && !empty(response_data.response)
        ? response_data.response
        : []
    );
  };

  const handleStateOfOriginChange = (setFieldValue) => (e) => {
    const selectedState = e.target.value;
    const lgas =
      NigeriaStatesAndLGA.find(
        (stateObj) =>
          !empty(stateObj) &&
          !empty(stateObj.title) &&
          stateObj.title === selectedState
      )?.lgas || [];
    setFieldValue("state_of_origin", selectedState);
    // setFieldValue("lga_of_origin", lgas);
    setSelectedStateLGA(lgas);
  };

  const handleStateOfResidenceChange = (setFieldValue) => (e) => {
    const selectedState = e.target.value;
    const lgas =
      NigeriaStatesAndLGA.find(
        (stateObj) =>
          !empty(stateObj) &&
          !empty(stateObj.title) &&
          stateObj.title === selectedState
      )?.lgas || [];
    setFieldValue("state_of_residence", selectedState);
    setSelectedStateLGAResidence(lgas);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }

      const fields = [
        "first_name",
        "last_name",
        "middle_name",
        "gender",
        "address",
        "entry_class",
        "previous_school",
        "state_of_residence",
        "lga_of_residence",
        "state_of_origin",
        "lga_of_origin",
        "nationality",
        "email",
        "phone_number",
        "religion",
        "date_of_birth",
        "active",
      ];
      const studentDetails = {};
      if (!empty(values)) {
        _.forEach(fields, (data) => {
          studentDetails[data] =
            !empty(data) && !empty(values[data]) ? values[data] : "";
        });
      }
      let response;
      let studentAdded = false;
      let studentUpdated = false;
      if (!studentId) {
        response = await studentApi.addStudent(
          schoolId,
          ...Object.values(studentDetails)
        );
        studentAdded = true;
      } else {
        response = await studentApi.updateStudent(
          studentId,
          schoolId,
          ...Object.values(studentDetails)
        );
        studentUpdated = true;
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        if (
          !empty(response_data) &&
          !empty(response_data.statusCodeType) &&
          response_data.statusCodeType === "unauthorized"
        ) {
          return navigate("/");
        }
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      let _studentId = "";
      if (!empty(data)) {
        _studentId = !empty(data.studentId) ? data.studentId : "";
        return navigate(`/student/${_studentId}/profile`, {
          state: {
            studentAdded,
            studentUpdated,
          },
        });
      }

      navigate(`/students`, {
        state: { studentAdded, studentUpdated },
      });
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader
              title={!empty(studentId) ? "Update Student" : "Add Student"}
            />
            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, handleChange, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div>
                      <em>
                        <strong>Note: *</strong> Please enter the correct
                        details for the student
                      </em>
                    </div>
                    <div className="form-section-title">
                      Personal Information
                      <Underline />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter First Name"
                        name="first_name"
                        height={50}
                        labelTitle="First Name"
                        required={true}
                      />
                      <InputField
                        placeholder="Enter Last Name"
                        name="last_name"
                        height={50}
                        labelTitle="Last Name"
                        required={true}
                      />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Middle Name"
                        name="middle_name"
                        height={50}
                        labelTitle="Middle Name"
                      />
                      <SelectField
                        labelTitle="Gender"
                        placeholder="Select Gender"
                        name="gender"
                        options={genderData}
                        height={50}
                        required={true}
                        valueKey="gender"
                        selectedOption={values.gender}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Date of Birth"
                        name="date_of_birth"
                        height={50}
                        labelTitle="Date of Birth"
                      />
                      <InputField
                        placeholder="Address"
                        name="address"
                        height={50}
                        labelTitle="Address"
                        required={true}
                      />
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="State of Residence"
                        required={true}
                        placeholder="Select State of Residence"
                        name="state_of_residence"
                        options={NigeriaStatesAndLGA}
                        height={50}
                        valueKey="title"
                        selectedOption={values.state_of_residence}
                        handleChangeFunc={handleStateOfResidenceChange(
                          setFieldValue
                        )}
                      />
                      <SelectField
                        labelTitle="L.G.A of Residence"
                        required={true}
                        placeholder="Select L.G.A of Residence"
                        name="lga_of_residence"
                        options={selectedStateLGAResidence}
                        height={50}
                        valueKey="title"
                        selectedOption={values.lga_of_residence}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="State of Origin"
                        placeholder="Select State of Origin"
                        name="state_of_origin"
                        options={NigeriaStatesAndLGA}
                        height={50}
                        valueKey="title"
                        selectedOption={values.state_of_origin}
                        handleChangeFunc={handleStateOfOriginChange(
                          setFieldValue
                        )}
                      />
                      <SelectField
                        labelTitle="L.G.A of Origin"
                        placeholder="Select L.G.A of Origin"
                        name="lga_of_origin"
                        options={selectedStateLGA}
                        height={50}
                        valueKey="title"
                        selectedOption={values.lga_of_origin}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Nationality"
                        name="nationality"
                        height={50}
                        labelTitle="Nationality"
                      />
                      <InputField
                        placeholder="Enter Religion"
                        name="religion"
                        height={50}
                        required={true}
                        labelTitle="Religion"
                      />
                    </div>
                    <div className="form-section-title">
                      Contact Information
                      <Underline />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Phone Number"
                        name="phone_number"
                        height={50}
                        labelTitle="Phone Number"
                      />
                      <InputField
                        placeholder="Enter Email"
                        name="email"
                        height={50}
                        labelTitle="Email"
                      />
                    </div>
                    <div className="form-section-title">
                      Academic Information
                      <Underline />
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="Entry Class"
                        required={true}
                        placeholder="Select Entry Class"
                        name="entry_class"
                        options={classes}
                        height={50}
                        valueKey="id"
                        selectedOption={values.entry_class}
                        handleChangeFunc={handleChange}
                      />
                      <InputField
                        placeholder="Previous School"
                        name="previous_school"
                        height={50}
                        labelTitle="Previous School"
                      />
                    </div>
                    <div className="form-section-title">
                      Other Action
                      <Underline />
                    </div>
                    <div className="app_input_group md-w-50pc">
                      <SelectField
                        labelTitle="Activate Student"
                        required={true}
                        placeholder="Activate this Student?"
                        name="active"
                        options={yesNo}
                        height={50}
                        valueKey="title"
                        selectedOption={values.active}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    {!empty(studentId) && hasPaid === "Yes" && (
                      <div className="form-button-container">
                        <ButtonIcon
                          height={45}
                          marginTop={5}
                          color="#ffffff"
                          backgroundColor="#633ccd"
                          width={300}
                          borderColor="#633ccd"
                          buttonText={
                            studentId ? "Update Student" : "Add Student"
                          }
                          type="submit"
                        />
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default AddUpdateStudent;
