import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import sessionApi from "../../api/Session";

// data
import { yearsData } from "../../data/years";
import { yesNo } from "../../data/yesNo";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import Underline from "../../components/others/Underline";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { AuthContext } from "../Root/ProtectedRoute";

const validationSchema = Yup.object().shape({
  start_year: Yup.string().required("This field is required!"),
  end_year: Yup.string().required("This field is required!"),
  active: Yup.string().required("This field is required!"),
});

const AddUpdateSession = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [initialValues, setInitialValues] = useState({
    start_year: "",
    end_year: "",
    active: "No",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const urlParams = useParams();
  const sessionId =
    !empty(urlParams) && !empty(urlParams.sessionId) ? urlParams.sessionId : "";

  const toastTR = useRef(null);

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    if (!empty(sessionId)) {
      getSessionDetails();
    }
  }, [sessionId]);

  const getSessionDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await sessionApi.getSession(sessionId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const response_error = !empty(response_data.response)
          ? response_data.response
          : "Something went wrong!";
        return responseDailog("error", "Error Alert", response_error);
      }

      if (!empty(response_data.response)) {
        const title = !empty(response_data.response.title)
          ? response_data.response.title
          : "";
        const active =
          !empty(response_data.response.active) && response_data.response.active
            ? true
            : false;
        const years = !empty(title) ? title.split("/") : [];
        const _start_year = !empty(years[0]) ? years[0] : "";
        const _end_year = !empty(years[1]) ? years[1] : "";
        setInitialValues({
          start_year: _start_year,
          end_year: _end_year,
          active: active ? "Yes" : "No",
        });
      }
    } catch (error) {
      return responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        const response_error = "Something went wrong! Try again later.";
        return responseDailog("error", "Error Alert", response_error);
      }
      const start_year = !empty(values.start_year) ? values.start_year : "";
      const end_year = !empty(values.end_year) ? values.end_year : "";
      const active = !empty(values.active) ? values.active : "";
      const title = `${start_year}/${end_year}`;
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      let response;
      let sessionAdded = false;
      let sessionUpdated = false;
      if (!sessionId) {
        response = await sessionApi.addSession(schoolId, title, active);
        sessionAdded = true;
      } else {
        response = await sessionApi.updateSession(
          sessionId,
          schoolId,
          title,
          active
        );
        sessionUpdated = true;
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const response_error = !empty(response_data.response)
          ? response_data.response
          : "Something went wrong!";
        return responseDailog("error", "Error Alert", response_error);
      }

      return navigate("/sessions", {
        state: { sessionAdded, sessionUpdated },
      });
    } catch (error) {
      return responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            {/*  header start */}
            <MainHeader
              title={!empty(sessionId) ? "Update Session" : "Add Session"}
            />
            {/* end of header */}

            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, handleChange }) => (
                  <Form style={{ width: "100%" }}>
                    <div>
                      <em>
                        <strong>Note: *</strong> Please enter the correct
                        details for the session
                      </em>
                    </div>
                    <div
                      style={{ marginTop: 30, fontSize: 28, color: "#633ccd" }}
                    >
                      <strong>Session Details</strong>
                      <Underline />
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        placeholder="Select Start Year"
                        name="start_year"
                        options={yearsData}
                        height={50}
                        valueKey="start_year"
                        selectedOption={values.start_year}
                        handleChangeFunc={handleChange}
                      />
                      <SelectField
                        placeholder="Select End Year"
                        name="end_year"
                        options={yearsData}
                        height={50}
                        valueKey="end_year"
                        selectedOption={values.end_year}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="app_input_group md-w-50pc">
                      <SelectField
                        placeholder="Activate this Session?"
                        name="active"
                        options={yesNo}
                        height={50}
                        valueKey="active"
                        selectedOption={values.active}
                        handleChangeFunc={handleChange}
                      />
                    </div>

                    <div className="form-button-container">
                      <ButtonIcon
                        height={45}
                        marginTop={5}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={300}
                        borderColor="#633ccd"
                        buttonText={
                          sessionId ? "Update Session" : "Add Session"
                        }
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}

        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default AddUpdateSession;
