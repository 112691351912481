import client from "./Client";

const recordAttendance = (school_id, student_id, creator_id, type) =>
  client.post("/attendance/record", {
    school_id,
    student_id,
    creator_id,
    type,
  });

const getStudentAttendanceDetails = (school_id, reg_no) =>
  client.get(`${school_id}/attendance/student/${reg_no}/details`);

const getNoOfStaffStat = (school_id, params = {}) => {
  return client.get(`/${school_id}/attendance/stat/staff`, {
    params,
  });
};

const getNoOfStudentsStat = (school_id, params = {}) => {
  return client.get(`/${school_id}/attendance/stat/students`, {
    params,
  });
};

const getClassAssessmentStat = (school_id, params = {}) => {
  return client.get(`/${school_id}/class/attendance/stat/percentage`, {
    params,
  });
};

export default {
  recordAttendance,
  getStudentAttendanceDetails,
  getNoOfStudentsStat,
  getNoOfStaffStat,
  getClassAssessmentStat,
};
