import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { forEach } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { empty, isArray, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import subjectApi from "../../api/Subject";
import disciplineApi from "../../api/Disciplines";
import classApi from "../../api/Classes";

// data
import { yesNo } from "../../data/yesNo";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import FullPageLoader from "../../components/loader/FullPageLoader";
import InputField from "../../components/form/InputField";
import { AuthContext } from "../Root/ProtectedRoute";
import MultiSelectField from "../../components/form/MultiSelectField";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  title: Yup.string().required(required),
  active: Yup.string().required(required),
  discipline_id: Yup.string().required(required),
  code: Yup.string().required(required),
  categories: Yup.array().required(required),
});

const AddUpdateSubject = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [initialValues, setInitialValues] = useState({
    title: "",
    code: "",
    active: "",
    discipline_id: "",
    categories: "",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [disciplineData, setDisciplineData] = useState([]);
  const { subjectId } = useParams();
  const [categories, setClassCategories] = useState([]);
  const toastTR = useRef(null);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    if (subjectId) {
      getSubjectDetails();
    }
  }, [subjectId]);

  useEffect(() => {
    getDisciplines();
    getClassCategories();
  }, []);

  // function to get all disciplines
  const getDisciplines = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await disciplineApi.getDisciplines(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setDisciplineData([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setDisciplineData(data);
      }
    } catch (error) {
      responseDailog("error", "Internal server error", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getClassCategories = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClassCategories(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassCategories([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setClassCategories(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjectDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSingleSubject(subjectId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const subjectData = response_data.response;
        setInitialValues({
          title: !empty(subjectData.title) ? subjectData.title : "",
          code: !empty(subjectData.code) ? subjectData.code : "",
          discipline_id: !empty(subjectData.discipline_id)
            ? subjectData.discipline_id
            : "",
          active:
            !empty(subjectData.active) && subjectData.active === true
              ? "Yes"
              : "No",
          categories: !empty(subjectData.categories)
            ? subjectData.categories
            : [],
        });
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    if (!isLoading) setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";

      const fields = ["title", "code", "active", "discipline_id", "categories"];
      const subject_details = {};
      if (!empty(values)) {
        forEach(fields, (data) => {
          subject_details[data] =
            !empty(data) && !empty(values[data]) ? values[data] : "";
        });
      }
      let response;
      let subjectAdded = false;
      let subjectUpdated = false;
      if (!subjectId) {
        response = await subjectApi.addSubject(
          schoolId,
          ...Object.values(subject_details)
        );
        subjectAdded = true;
      } else {
        response = await subjectApi.updateSubject(
          subjectId,
          schoolId,
          ...Object.values(subject_details)
        );
        subjectUpdated = true;
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        if (
          !empty(response_data) &&
          !empty(response_data.statusCodeType) &&
          response_data.statusCodeType === "unauthorized"
        ) {
          return navigate("/");
        }
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      navigate("/subjects", {
        state: { subjectAdded, subjectUpdated },
      });
    } catch (error) {
      responseDailog("error", "Internal server error", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClassCategoryOnChange = (name, newValue, setFieldValue) => {
    const categoryIds = !empty(newValue) && isArray(newValue) ? newValue : [];
    setFieldValue(name, categoryIds);
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader
              title={!empty(subjectId) ? "Update Subject" : "Add Subject"}
            />
            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, handleChange, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div>
                      <em>
                        <strong>Note: *</strong> Please enter the correct
                        details for the subject
                      </em>
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Subject Title"
                        name="title"
                        height={50}
                        labelTitle="Title"
                        required={true}
                      />
                      <InputField
                        placeholder="Enter Subject Code E.g MTH, CRS etc"
                        name="code"
                        height={50}
                        required={true}
                        labelTitle="Subject Code"
                      />
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="Discipline"
                        placeholder="Select Discipline"
                        name="discipline_id"
                        required={true}
                        options={disciplineData}
                        height={50}
                        valueKey="id"
                        selectedOption={values.discipline_id}
                        handleChangeFunc={handleChange}
                      />
                      <SelectField
                        labelTitle="Activate Subject"
                        placeholder="Activate this Subject?"
                        name="active"
                        required={true}
                        options={yesNo}
                        height={50}
                        valueKey="active"
                        selectedOption={values.active}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <MultiSelectField
                        name="categories"
                        required={true}
                        labelTitle="Select Class Categories"
                        placeholder="Select Class Categories"
                        options={categories}
                        valueKey="title"
                        selectedOptions={values.categories}
                        onChange={(e) =>
                          handleClassCategoryOnChange(
                            "categories",
                            e.value,
                            setFieldValue
                          )
                        }
                      />
                    </div>
                    <div className="form-button-container">
                      <ButtonIcon
                        height={45}
                        marginTop={5}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={300}
                        borderColor="#633ccd"
                        buttonText={
                          subjectId ? "Update Subject" : "Add Subject"
                        }
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default AddUpdateSubject;
