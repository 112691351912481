import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import staffApi from "../../api/Staff";

// data
import { yesNo } from "../../data/yesNo";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import Underline from "../../components/others/Underline";
import FullPageLoader from "../../components/loader/FullPageLoader";
import InputField from "../../components/form/InputField";
import { genderData } from "../../data/genderData";
import { NigeriaStatesAndLGA } from "../../data/NigeriaStatesAndLGA";
import { titleData } from "../../data/TitleData";
import { AuthContext } from "../Root/ProtectedRoute";
import colors from "../../config/colors";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required(required),
  last_name: Yup.string().required(required),
  middle_name: Yup.string().optional(),
  gender: Yup.string().required(required),
  title: Yup.string().required(required),
  address: Yup.string().required(required),
  state_of_residence: Yup.string().required(required),
  lga_of_residence: Yup.string().required(required),
  state_of_origin: Yup.string().optional(),
  lga_of_origin: Yup.string().optional(),
  nationality: Yup.string().required(required),
  email: Yup.string().email("Invalid email").required(required),
  phone_number1: Yup.string().required(required),
  phone_number2: Yup.string().optional(),
  qualification: Yup.string().required(required),
  course_studied: Yup.string().required(required),
  active: Yup.string().required(required),
  religion: Yup.string().required(required),
  date_of_birth: Yup.string().required(required),
});

const AddUpdateSchoolStaff = ({ ...props }) => {
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    middle_name: "",
    gender: "",
    title: "",
    address: "",
    state_of_residence: "",
    lga_of_residence: "",
    state_of_origin: "",
    lga_of_origin: "",
    nationality: "",
    email: "",
    phone_number1: "",
    phone_number2: "",
    qualification: "",
    course_studied: "",
    religion: "",
    date_of_birth: "",
    active: "",
  });
  const [selectedStateLGA, setSelectedStateLGA] = useState([]);
  const [selectedStateLGAResidence, setSelectedStateLGAResidence] = useState(
    []
  );
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { staffId } = useParams();
  const toastTR = useRef(null);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    if (staffId) {
      const getStaffDetails = async () => {
        setIsLoading(true);
        try {
          const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
          const response = await staffApi.getSingleStaff(staffId, schoolId);
          const response_data = prepareResponseData(response);
          if (empty(response_data) || !response_data.success) {
            return responseDailog(
              "error",
              "Error Alert",
              !empty(response_data.response)
                ? typeof response_data.response === "string"
                  ? response_data.response
                  : "Something went wrong!"
                : "Something went wrong"
            );
          }

          if (!empty(response_data) && !empty(response_data.response)) {
            const userData = response_data.response;
            setInitialValues({
              first_name: !empty(userData.firstName) ? userData.firstName : "",
              last_name: !empty(userData.lastName) ? userData.lastName : "",
              middle_name: !empty(userData.middleName)
                ? userData.middleName
                : "",
              gender: !empty(userData.gender) ? userData.gender : "",
              title: !empty(userData.title) ? userData.title : "",
              address: !empty(userData.address) ? userData.address : "",
              state_of_residence: !empty(userData.stateOfResidence)
                ? userData.stateOfResidence
                : "",
              lga_of_residence: !empty(userData.lgaOfResidence)
                ? userData.lgaOfResidence
                : "",
              state_of_origin: !empty(userData.stateOfOrigin)
                ? userData.stateOfOrigin
                : "",
              lga_of_origin: !empty(userData.lgaOfOrigin)
                ? userData.lgaOfOrigin
                : "",
              nationality: !empty(userData.nationality)
                ? userData.nationality
                : "",
              email: !empty(userData.email) ? userData.email : "",
              phone_number1: !empty(userData.phoneNumber1)
                ? userData.phoneNumber1
                : "",
              phone_number2: !empty(userData.phoneNumber2)
                ? userData.phoneNumber2
                : "",
              qualification: !empty(userData.qualification)
                ? userData.qualification
                : "",
              course_studied: !empty(userData.courseStudied)
                ? userData.courseStudied
                : "",
              religion: !empty(userData.religion) ? userData.religion : "",
              date_of_birth: !empty(userData.dateOfBirth)
                ? userData.dateOfBirth
                : "",
              active:
                !empty(userData.active) && userData.active === true
                  ? "Yes"
                  : "No",
            });
            const stateOfOrigin = !empty(userData.stateOfOrigin)
              ? userData.stateOfOrigin
              : "";
            const stateOfResidence = !empty(userData.stateOfResidence)
              ? userData.stateOfResidence
              : "";
            const stateOfOiginLGAs =
              NigeriaStatesAndLGA.find(
                (stateObj) =>
                  !empty(stateObj) &&
                  !empty(stateObj.title) &&
                  stateObj.title === stateOfOrigin
              )?.lgas || [];
            setSelectedStateLGA(stateOfOiginLGAs);
            const stateOfResidenceLGAs =
              NigeriaStatesAndLGA.find(
                (stateObj) =>
                  !empty(stateObj) &&
                  !empty(stateObj.title) &&
                  stateObj.title === stateOfResidence
              )?.lgas || [];
            setSelectedStateLGAResidence(stateOfResidenceLGAs);
          }
        } catch (error) {
          responseDailog("error", "Error Alert", "Something went wrong.");
        } finally {
          setIsLoading(false);
        }
      };

      getStaffDetails();
    }
  }, [staffId]);

  const handleStateOfOriginChange = (setFieldValue) => (e) => {
    const selectedState = e.target.value;
    const lgas =
      NigeriaStatesAndLGA.find(
        (stateObj) =>
          !empty(stateObj) &&
          !empty(stateObj.title) &&
          stateObj.title === selectedState
      )?.lgas || [];
    setFieldValue("state_of_origin", selectedState);
    setSelectedStateLGA(lgas);
  };

  const handleStateOfResidenceChange = (setFieldValue) => (e) => {
    const selectedState = e.target.value;
    const lgas =
      NigeriaStatesAndLGA.find(
        (stateObj) =>
          !empty(stateObj) &&
          !empty(stateObj.title) &&
          stateObj.title === selectedState
      )?.lgas || [];
    setFieldValue("state_of_residence", selectedState);
    setSelectedStateLGAResidence(lgas);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const fields = [
        "first_name",
        "last_name",
        "middle_name",
        "gender",
        "title",
        "address",
        "state_of_residence",
        "lga_of_residence",
        "state_of_origin",
        "lga_of_origin",
        "nationality",
        "email",
        "phone_number1",
        "phone_number2",
        "qualification",
        "course_studied",
        "religion",
        "date_of_birth",
        "active",
      ];
      const staff_details = {};
      if (!empty(values)) {
        _.forEach(fields, (data) => {
          staff_details[data] =
            !empty(data) && !empty(values[data]) ? values[data] : "";
        });
      }
      let response;
      let staffAdded = false;
      let staffUpdated = false;
      if (!staffId) {
        response = await staffApi.addStaff(
          schoolId,
          ...Object.values(staff_details)
        );
        staffAdded = true;
      } else {
        response = await staffApi.updateStaff(
          staffId,
          schoolId,
          ...Object.values(staff_details)
        );
        staffUpdated = true;
      }
      const response_data = prepareResponseData(response);
      if (!empty(response_data) && !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }
      const data =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      let _staffId = "";
      if (!empty(data)) {
        _staffId = !empty(data.staffId) ? data.staffId : "";
        return navigate(`/staff/${_staffId}/profile`, {
          state: {
            staffAdded,
            staffUpdated,
          },
        });
      }

      navigate("/staff", {
        state: { staffAdded, staffUpdated },
      });
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader
              title={!empty(staffId) ? "Update Staff" : "Add Staff"}
            />
            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, handleChange, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div>
                      <em>
                        <strong>Note: *</strong> Please enter the correct
                        details for the staff
                      </em>
                    </div>
                    <div className="form-section-title">
                      Personal Information
                      <Underline />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter First Name"
                        name="first_name"
                        height={50}
                        labelTitle="First Name"
                        required={true}
                      />
                      <InputField
                        placeholder="Enter Last Name"
                        name="last_name"
                        height={50}
                        labelTitle="Last Name"
                        required={true}
                      />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Middle Name"
                        name="middle_name"
                        height={50}
                        labelTitle="Middle Name"
                      />
                      <SelectField
                        labelTitle="Gender"
                        required={true}
                        placeholder="Select Gender"
                        name="gender"
                        options={genderData}
                        height={50}
                        valueKey="gender"
                        selectedOption={values.gender}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="Title"
                        required={true}
                        placeholder="Select title"
                        name="title"
                        options={titleData}
                        height={50}
                        valueKey="title"
                        selectedOption={values.title}
                        handleChangeFunc={handleChange}
                      />
                      <InputField
                        placeholder="Address"
                        name="address"
                        height={50}
                        labelTitle="Address"
                        required={true}
                      />
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="State of Residence"
                        required={true}
                        placeholder="Select State of Residence"
                        name="state_of_residence"
                        options={NigeriaStatesAndLGA}
                        height={50}
                        valueKey="state_of_residence"
                        selectedOption={values.state_of_residence}
                        handleChangeFunc={handleStateOfResidenceChange(
                          setFieldValue
                        )}
                      />
                      <SelectField
                        labelTitle="L.G.A of Residence"
                        required={true}
                        placeholder="Select L.G.A of Residence"
                        name="lga_of_residence"
                        options={selectedStateLGAResidence}
                        height={50}
                        valueKey="lga_of_residence"
                        selectedOption={values.lga_of_residence}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="State of Origin"
                        required={true}
                        placeholder="Select State of Origin"
                        name="state_of_origin"
                        options={NigeriaStatesAndLGA}
                        height={50}
                        valueKey="state_of_origin"
                        selectedOption={values.state_of_origin}
                        handleChangeFunc={handleStateOfOriginChange(
                          setFieldValue
                        )}
                      />
                      <SelectField
                        labelTitle="L.G.A of Origin"
                        required={true}
                        placeholder="Select L.G.A of Origin"
                        name="lga_of_origin"
                        options={selectedStateLGA}
                        height={50}
                        valueKey="lga_of_origin"
                        selectedOption={values.lga_of_origin}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Nationality"
                        name="nationality"
                        height={50}
                        labelTitle="Nationality"
                        required={true}
                      />
                      <InputField
                        placeholder="Enter Religion"
                        name="religion"
                        height={50}
                        labelTitle="Religion"
                        required={true}
                      />
                    </div>
                    <div className="app_input_group md-w-50pc">
                      <InputField
                        placeholder="Enter Date of Birth"
                        name="date_of_birth"
                        height={50}
                        labelTitle="Date of Birth"
                        required={true}
                        type="date"
                      />
                    </div>
                    <div className="form-section-title">
                      Contact Information
                      <Underline />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Phone Number 1"
                        name="phone_number1"
                        height={50}
                        labelTitle="Phone Number 1"
                        required={true}
                      />
                      <InputField
                        placeholder="Enter Phone Number 2"
                        name="phone_number2"
                        height={50}
                        labelTitle="Phone Number 2"
                      />
                    </div>
                    <div className="app_input_group md-w-50pc">
                      <InputField
                        placeholder="Enter Email"
                        name="email"
                        height={50}
                        labelTitle="Email"
                        required={true}
                      />
                    </div>
                    <div className="form-section-title">
                      Academic Information
                      <Underline />
                    </div>
                    <div className="app_input_group">
                      <InputField
                        placeholder="Enter Qualification"
                        name="qualification"
                        height={50}
                        labelTitle="Qualification"
                        required={true}
                      />
                      <InputField
                        placeholder="Enter Course Studied"
                        name="course_studied"
                        height={50}
                        labelTitle="Course Studied"
                      />
                    </div>
                    <div className="form-section-title">
                      Other Action
                      <Underline />
                    </div>
                    <div className="app_input_group md-w-50pc">
                      <SelectField
                        labelTitle="Activate Staff"
                        required={true}
                        placeholder="Activate this Staff?"
                        name="active"
                        options={yesNo}
                        height={50}
                        valueKey="active"
                        selectedOption={values.active}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="form-button-container">
                      <ButtonIcon
                        height={45}
                        marginTop={5}
                        color="#ffffff"
                        backgroundColor={colors.primary}
                        width={300}
                        borderColor={colors.primary}
                        buttonText={staffId ? "Update Staff" : "Add Staff"}
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default AddUpdateSchoolStaff;
