import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import assessmentApi from "../../api/Assessment";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import FullPageLoader from "../../components/loader/FullPageLoader";
import InputField from "../../components/form/InputField";
import { AuthContext } from "../Root/ProtectedRoute";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  value: Yup.number().required(required),
  description: Yup.string().required(required),
});

const AddUpdateRatingIndice = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [initialValues, setInitialValues] = useState({
    value: "",
    description: "",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { ratingId } = useParams();
  const toastTR = useRef(null);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    if (ratingId) {
      getRatingIndiceDetails();
    }
  }, [ratingId]);

  const getRatingIndiceDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await assessmentApi.getRatingDetails(schoolId, ratingId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Failed to get class details."
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const data = !empty(response_data.response)
          ? response_data.response
          : {};
        setInitialValues({
          value: !empty(data.value) ? data.value : "",
          description: !empty(data.description) ? data.description : "",
        });
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const value = !empty(values) && !empty(values.value) ? values.value : "";
      const description =
        !empty(values) && !empty(values.description) ? values.description : "";
      let response;
      let ratingAdded = false;
      let ratingUpdated = false;
      if (!ratingId) {
        response = await assessmentApi.addRating(schoolId, value, description);
        ratingAdded = true;
      } else {
        response = await assessmentApi.updateRating(
          schoolId,
          ratingId,
          value,
          description
        );
        ratingUpdated = true;
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      navigate("/assessment/rating-indices", {
        state: { ratingAdded, ratingUpdated },
      });
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader
              title={!empty(ratingId) ? "Update Rating" : "Add Rating"}
            />
            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form style={{ width: "100%" }}>
                  <div className="app_input_group">
                    <InputField
                      placeholder="Enter Rating value"
                      name="value"
                      type="number"
                      width={200}
                      height={50}
                      labelTitle="Rating Value"
                      required={true}
                    />
                  </div>
                  <div className="app_input_group">
                    <InputField
                      placeholder="Enter description"
                      name="description"
                      height={150}
                      as="textarea"
                      row={9}
                      cols={8}
                      required={true}
                      labelTitle="Description"
                    />
                  </div>
                  <div className="form-button-container">
                    <ButtonIcon
                      height={45}
                      marginTop={5}
                      color="#ffffff"
                      backgroundColor="#633ccd"
                      width={180}
                      borderColor="#633ccd"
                      buttonText={
                        ratingId ? "Update Rating Indice" : "Add Rating Indice"
                      }
                      type="submit"
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default AddUpdateRatingIndice;
