import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { forEach } from "lodash";
import * as Yup from "yup";

// css
import "../students/Students.css";
import "./Settings.css";

// api
import schoolApi from "../../api/School";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import FullPageLoader from "../../components/loader/FullPageLoader";
import Card from "../../components/card/Card";
import { Form, Formik } from "formik";
import InputField from "../../components/form/InputField";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { AuthContext } from "../Root/ProtectedRoute";
import { empty, prepareResponseData } from "../../Utilities/utils";
import SettingsWrapper from "../../components/appWrapper/SettingsWrapper";

const required = "This field is required.";
const validationUpdatePasswordSchema = Yup.object().shape({
  ca1_max: Yup.string().required(required),
  ca2_max: Yup.string().required(required),
  exam_max: Yup.string().required(required),
});

const AssessmentSettings = ({ ...props }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [assessmentMaxScores, setAssessmentMaxScores] = useState({});
  const toastTR = useRef(null);

  useEffect(() => {
    const _assessmentMaxScores =
      !empty(user) && !empty(user.assessmentMaxScores)
        ? user.assessmentMaxScores
        : {};
    setAssessmentMaxScores(_assessmentMaxScores);
  }, []);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const handleAssessmentSettingsSubmit = async (values) => {
    if (!isLoading) setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";

      const fields = ["ca1_max", "ca2_max", "exam_max"];
      const details = {};
      if (!empty(values)) {
        forEach(fields, (data) => {
          details[data] =
            !empty(data) && !empty(values[data]) ? values[data] : "";
        });
      }
      const response = await schoolApi.updateAssessmentMaxScores(
        schoolId,
        ...Object.values(details)
      );

      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        if (
          !empty(response_data) &&
          !empty(response_data.statusCodeType) &&
          response_data.statusCodeType === "unauthorized"
        ) {
          return navigate("/");
        }
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to updated assessment maximum scores!"
        );
      }

      return responseDailog(
        "success",
        "Successful",
        "Maximum assessment scores updated successfully."
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SettingsWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader title="Settings" />
            <div className="flex flex-direction-row col-g-12 w-100pc">
              <Card
                children={
                  <div>
                    <MainHeader titleColor="#633ccd" title="Update Password" />
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          ca1_max:
                            !empty(assessmentMaxScores) &&
                            !empty(assessmentMaxScores.ca1_max)
                              ? assessmentMaxScores.ca1_max
                              : "",
                          ca2_max:
                            !empty(assessmentMaxScores) &&
                            !empty(assessmentMaxScores.ca2_max)
                              ? assessmentMaxScores.ca2_max
                              : "",
                          exam_max:
                            !empty(assessmentMaxScores) &&
                            !empty(assessmentMaxScores.exam_max)
                              ? assessmentMaxScores.exam_max
                              : "",
                        }}
                        validationSchema={validationUpdatePasswordSchema}
                        onSubmit={handleAssessmentSettingsSubmit}
                      >
                        <Form style={{ width: "100%" }}>
                          <div className="app_input_group">
                            <InputField
                              placeholder="Enter ca1 maximum value"
                              name="ca1_max"
                              height={50}
                              required={true}
                              labelTitle="CA 1 Maximum Value"
                            />
                            <InputField
                              placeholder="Enter ca2 maximum value"
                              name="ca2_max"
                              height={50}
                              required={true}
                              labelTitle="CA 2 Maximum Value"
                            />
                            <InputField
                              placeholder="Enter exam maximum value"
                              name="exam_max"
                              height={50}
                              required={true}
                              labelTitle="Exam Maximum Value"
                            />
                          </div>
                          <div className="form-button-container">
                            <ButtonIcon
                              height={45}
                              marginTop={5}
                              color="#ffffff"
                              backgroundColor="#633ccd"
                              width={300}
                              borderColor="#633ccd"
                              buttonText="Update Password"
                              type="submit"
                            />
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                }
                addStyle="settings-body px-20"
              />
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </SettingsWrapper>
    </>
  );
};

export default AssessmentSettings;
