export const DaysOfTheWeek = [
  {
    _id: 0,
    value: "Monday",
  },
  {
    _id: 1,
    value: "Tuesday",
  },
  {
    _id: 2,
    value: "Wednesday",
  },
  {
    _id: 3,
    value: "Thursday",
  },
  {
    _id: 4,
    value: "Friday",
  },
  {
    _id: 5,
    value: "Saturday",
  },
  {
    _id: 6,
    value: "Sunday",
  },
];
