import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { isObject } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  calculateEndTime,
  checkTimeOverlap,
  empty,
  generateValidLessonStartTime,
  generateValidStartTimes,
  isArray,
  isUndefined,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../timeTable/TimeTable.css";

// hooks
import { AuthContext } from "../Root/ProtectedRoute";

// api
import subjectApi from "../../api/Subject";
import staffApi from "../../api/Staff";
import classApi from "../../api/Classes";
import schoolApi from "../../api/School";

// data

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import FullPageLoader from "../../components/loader/FullPageLoader";
import TimeTableWrapper from "../../components/appWrapper/TimeTableWrapper";
import { DaysOfTheWeek } from "../../data/DaysOfTheWeek";
import InputFieldChange from "../../components/form/InputFieldChange";
import { classSections } from "../../data/classSections";

const UpdateSingleClassSchedule = ({ ...props }) => {
  const { user } = useContext(AuthContext);

  const schoolClockInOut =
    !empty(user) && !empty(user.schoolClockInOut) ? user.schoolClockInOut : {};
  const classStartTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.classStartTime)
      ? schoolClockInOut.classStartTime
      : "";
  const schoolDismissalTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.schoolDismissalTime)
      ? schoolClockInOut.schoolDismissalTime
      : "";
  const lessonStartTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.lessonStartTime)
      ? schoolClockInOut.lessonStartTime
      : "";
  const breakTimes =
    !empty(user) &&
    !empty(user.periodSettings) &&
    !empty(user.periodSettings.breakDetails)
      ? user.periodSettings.breakDetails
      : "";
  const maxClassPeriodsInARow =
    !empty(user) &&
    !empty(user.periodSettings) &&
    !empty(user.periodSettings.maxClassPeriodsInARow)
      ? user.periodSettings.maxClassPeriodsInARow
      : 2;

  const navigate = useNavigate();
  const location = useLocation();
  const paramState = !empty(location.state) ? location.state : {};
  let classId = !empty(paramState.classId) ? paramState.classId : "";
  let _periodSection = !empty(paramState.periodSection)
    ? paramState.periodSection
    : "";
  let numberOfPeriods = !empty(paramState.numberOfPeriods)
    ? paramState.numberOfPeriods
    : "";
  let _startTime = !empty(paramState.startTime) ? paramState.startTime : "";
  let endTime = !empty(paramState.endTime) ? paramState.endTime : "";
  let subjectId = !empty(paramState.subjectId) ? paramState.subjectId : "";
  let staffId = !empty(paramState.staffId) ? paramState.staffId : "";
  let day = !empty(paramState.day) ? paramState.day : "";
  const [isLoading, setIsLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [staff, setStaff] = useState([]);
  const [authorizedStaff, setAuthorizedStaff] = useState([]);
  const [reIndexedClasses, setReIndexedClasses] = useState({});
  const [selectedClassId, setSelectedClassId] = useState(classId);
  const [categories, setCategories] = useState([]);
  const [reIndexedCategories, setReIndexedCategories] = useState({});
  const [periodSection, setPeriodSection] = useState(_periodSection);
  const [classScheduleInterval, setClassScheduleInterval] = useState(45);
  const [normalStartTimeIntervals, setNormalStartTimeIntervals] = useState([]);
  const [validNumberOfPeriods, setValidNumberOfPeriods] = useState([]);
  const [selectedNumberOfPeriods, setSelectedNumberOfPeriods] =
    useState(numberOfPeriods);
  const [startTime, setStartTime] = useState(_startTime);
  const toastTR = useRef(null);
  const initialValues = {
    subject_id: subjectId,
    day,
    class_id: selectedClassId,
    start_time: _startTime,
    end_time: endTime,
    number_of_periods: numberOfPeriods,
    period_section: periodSection,
    staff_id: staffId,
  };

  const validationSchema = Yup.object().shape({
    subject_id: Yup.string().required("Subject is required!"),
    day: Yup.string().required("Day is required!"),
    class_id: Yup.string().required("Class is required!"),
    start_time: Yup.string().required("Start Time is required!"),
    end_time: Yup.string().required("End Time is required!"),
    number_of_periods: Yup.string().required("Number of Periods is required!"),
    period_section: Yup.string().required("Period Section is required!"),
    staff_id: Yup.string().required("Staff is required!"),
  });

  // alert functions
  const responseDailog = (
    severity = null,
    summary = null,
    detail = null,
    life = 20000
  ) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life,
    });
  };

  useEffect(() => {
    // get the interval for the class category
    if (!empty(selectedClassId) && !empty(periodSection)) {
      let classInterval = "";
      let validStartTimes = [];
      if (periodSection === "Normal Time") {
        // get classes with the same class category as the selected subject
        const classCategoryId =
          !empty(reIndexedClasses) &&
          !empty(reIndexedClasses[selectedClassId]) &&
          !empty(reIndexedClasses[selectedClassId].categoryId)
            ? reIndexedClasses[selectedClassId].categoryId
            : "";
        classInterval =
          !empty(reIndexedCategories) &&
          !empty(reIndexedCategories[classCategoryId]) &&
          !empty(reIndexedCategories[classCategoryId].classScheduleInterval)
            ? reIndexedCategories[classCategoryId].classScheduleInterval
            : "";
        let defaultCategory = {};
        if (empty(classInterval)) {
          for (let i = 0; i < categories.length; i++) {
            const item = !empty(categories[i]) ? categories[i] : {};
            if (!empty(item) && item.title === "ALL (DEFAULT)") {
              defaultCategory = item;
              break;
            }
          }
          if (
            (empty(defaultCategory) ||
              empty(defaultCategory.classScheduleInterval)) &&
            !empty(selectedSubjectId)
          ) {
            return responseDailog(
              "error",
              "Error Alert",
              `There are no available class schedule interval. Please go to 'Class schedule interval' and update the intervals for different class categories.`
            );
          }
          classInterval =
            !empty(defaultCategory) &&
            !empty(defaultCategory.classScheduleInterval)
              ? parseInt(defaultCategory.classScheduleInterval)
              : 45;
        }

        validStartTimes = generateValidStartTimes(
          lessonStartTime,
          classStartTime,
          schoolDismissalTime,
          classInterval,
          breakTimes
        );
      } else {
        classInterval =
          !empty(user) &&
          !empty(user.periodSettings) &&
          !empty(user.periodSettings.lessonClassInterval)
            ? parseInt(user.periodSettings.lessonClassInterval)
            : 30;

        validStartTimes = generateValidLessonStartTime(
          lessonStartTime,
          schoolDismissalTime,
          classInterval
        );
      }
      setClassScheduleInterval(classInterval);
      setNormalStartTimeIntervals(validStartTimes);

      // generate number of periods
      let numberOfPeriods = Array.from(
        { length: maxClassPeriodsInARow },
        (_, index) => ({
          value: index + 1,
        })
      );
      setValidNumberOfPeriods(numberOfPeriods);
    }
  }, [selectedClassId, periodSection, classes, staff]);

  useEffect(() => {
    if (selectedSubjectId) {
      let authorizedStaff = [];
      if (!empty(staff) && !empty(selectedClassId)) {
        authorizedStaff = staff.filter((staffMember) => {
          const authorizedSubjects =
            !empty(staffMember) &&
            !empty(staffMember.authorizedSubjects) &&
            isArray(staffMember.authorizedSubjects)
              ? staffMember.authorizedSubjects
              : [];
          return authorizedSubjects.some((authorizedSubject) => {
            const _subjectIds =
              !empty(authorizedSubject) &&
              !empty(authorizedSubject.subjectIds) &&
              isArray(authorizedSubject.subjectIds)
                ? authorizedSubject.subjectIds
                : [];
            const _classId =
              !empty(authorizedSubject) && !empty(authorizedSubject.classId)
                ? authorizedSubject.classId
                : "";
            return (
              _classId === selectedClassId &&
              _subjectIds.includes(selectedSubjectId)
            );
          });
        });
        if (empty(authorizedStaff)) {
          responseDailog(
            "error",
            "Internal server error",
            `There are no staff assigned to the selected subject for the selected class.`
          );
        }
        setAuthorizedStaff(authorizedStaff);
      }
    } else if (!empty(selectedClassId)) {
      const selectedClassObj = classes.find(
        (item) =>
          !empty(item) && !empty(item._id) && item._id === selectedClassId
      );
      const subjectIds =
        isObject(selectedClassObj) &&
        !empty(selectedClassObj.subjects) &&
        isArray(selectedClassObj.subjects)
          ? selectedClassObj.subjects
          : [];
      const classSubjects = subjects.filter(
        (item) =>
          !empty(item) && !empty(item._id) && subjectIds.includes(item._id)
      );
      setSubjects(
        !empty(classSubjects) && isArray(classSubjects)
          ? classSubjects
          : subjects
      );
    }
  }, [selectedClassId, classes, selectedSubjectId]);

  useEffect(() => {
    getClasses();
    getSubjects();
    getStaff();
    getCategories();
  }, []);

  // function to get all classes
  const getClasses = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClasses([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        let reIndexedClasses = {};
        if (!empty(data) && isArray(data)) {
          reIndexedClasses = reIndex(data, "_id");
        }
        setReIndexedClasses(reIndexedClasses);
        setSelectedClasses(data);
        return setClasses(data);
      }
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error",
        `Failed to fecth classes.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // functionto get all categories
  const getCategories = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClassCategories(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setCategories([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        let reIndexedCategories = {};
        if (!empty(data) && isArray(data)) {
          reIndexedCategories = reIndex(data, "_id");
        }
        setReIndexedCategories(reIndexedCategories);
        return setCategories(data);
      }
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error",
        `Failed to fecth classes.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // function to get all staff
  const getStaff = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getStaff(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStaff([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        return setStaff(data);
      }
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error",
        `Failed to fetch staff.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjects = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Failed to fetch subjects"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        return setSubjects(response_data.response);
      }
      return;
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * update class schedule
   */
  const updateClassSchedule = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        return responseDailog(
          "error",
          "Internal Server Error",
          "Something went wrong! Try again later."
        );
      }

      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      // Prepare the entire form data for submission
      const formData = {
        subject_id: !isUndefined(values.subject_id) ? values.subject_id : "",
        day: !isUndefined(values.day) ? values.day : "",
        staff_id: !isUndefined(values.staff_id) ? values.staff_id : "",
        class_id: !isUndefined(values.class_id) ? values.class_id : "",
        number_of_periods: !isUndefined(values.number_of_periods)
          ? values.number_of_periods
          : "",
        period_section: !isUndefined(values.period_section)
          ? values.period_section
          : "",
        end_time: !isUndefined(values.end_time) ? values.end_time : "",
        start_time: !isUndefined(values.start_time) ? values.start_time : "",
        schoolId,
      };

      const response = await schoolApi.updateClassSchedule(schoolId, formData);

      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to update schedule!`
        );
      }

      return responseDailog(
        "success",
        "Success",
        "Schedule updated successfully"
      );
    } catch (error) {
      return responseDailog(
        "error",
        "Internal Server Error",
        "Something went wrong updating class schedule"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const calculateEndTimeFunc = (_startTime, setFieldValue, numberOfPeriods) => {
    const endTime = calculateEndTime(
      _startTime,
      classScheduleInterval,
      numberOfPeriods
    );
    // check if time interval overlaps break
    const timeOverLapsBreak = checkTimeOverlap(_startTime, endTime, breakTimes);
    if (timeOverLapsBreak) {
      setFieldValue("end_time", "");
      return responseDailog(
        "error",
        "Forbidden Operation",
        `The selected class start time; ${_startTime}, with period interval of ${numberOfPeriods}, for a class interval of ${classScheduleInterval} minutes, overlaps Break-Time. Please update the start time or the period interval.`,
        20000
      );
    }
    return setFieldValue("end_time", endTime);
  };

  const handleMultiChange = (name, newValue, setFieldValue) => {
    try {
      const value =
        !empty(newValue) && isObject(newValue) && newValue.target?.value
          ? newValue.target.value
          : "";
      setFieldValue(name, value);

      if (name === "subject_id") {
        setSelectedSubjectId(value);
        setFieldValue(name, value);
      }

      if (name === "class_id") {
        return setSelectedClassId(value);
      }

      if (name === "number_of_periods") {
        setSelectedNumberOfPeriods(value);
        return calculateEndTimeFunc(startTime, setFieldValue, value);
      }

      if (name === "start_time") {
        setStartTime(value);
        if (!empty(selectedNumberOfPeriods)) {
          return calculateEndTimeFunc(
            value,
            setFieldValue,
            selectedNumberOfPeriods
          );
        }
      }

      if (name === "period_section") {
        setPeriodSection(value);
      }
    } catch (error) {}
  };

  return (
    <>
      <TimeTableWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader title="Update Class Schedule" />
            <div className="form-container mt-10">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={updateClassSchedule}
              >
                {({ values, handleChange, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div>
                      <em>
                        <strong>Note: *</strong> Please enter the correct
                        details for the schedule
                      </em>
                    </div>
                    <div className="app_input_group md-w-50pc">
                      <SelectField
                        labelTitle="Subject"
                        placeholder="Select Subject"
                        name="subject_id"
                        required={true}
                        options={subjects}
                        height={50}
                        valueKey="_id"
                        selectedOption={values.subject_id}
                        handleChangeFunc={(e) =>
                          handleMultiChange("subject_id", e, setFieldValue)
                        }
                      />
                    </div>
                    <>
                      <div className="app_input_group">
                        <SelectField
                          labelTitle="Day"
                          placeholder="Select Day"
                          name="day"
                          required={true}
                          options={DaysOfTheWeek}
                          height={50}
                          valueKey="value"
                          isDisabled={true}
                          display="value"
                          selectedOption={values.day}
                          handleChangeFunc={(e) =>
                            handleMultiChange("day", e, setFieldValue)
                          }
                        />
                      </div>
                      <div className="time-table-period-border mt-30">
                        <div className="mb-20 time-table-period-box">
                          <div className="app_input_group">
                            <SelectField
                              labelTitle="Class"
                              placeholder="Select Class"
                              name="class_id"
                              required={true}
                              options={selectedClasses}
                              height={50}
                              valueKey="id"
                              // isDisabled={true}
                              selectedOption={values.class_id}
                              handleChangeFunc={(e) =>
                                handleMultiChange("class_id", e, setFieldValue)
                              }
                            />
                            <SelectField
                              labelTitle="Period Section"
                              placeholder="Select Period Section"
                              name="period_section"
                              required={true}
                              options={classSections}
                              height={50}
                              valueKey="title"
                              display="title"
                              selectedOption={values.period_section}
                              handleChangeFunc={(e) =>
                                handleMultiChange(
                                  "period_section",
                                  e,
                                  setFieldValue
                                )
                              }
                            />
                          </div>
                          <div className="app_input_group mt-10">
                            <SelectField
                              labelTitle="Start Time"
                              placeholder="Select Start Time"
                              name="start_time"
                              required={true}
                              options={normalStartTimeIntervals}
                              height={50}
                              valueKey="value"
                              display="value"
                              selectedOption={values.start_time}
                              handleChangeFunc={(e) =>
                                handleMultiChange(
                                  "start_time",
                                  e,
                                  setFieldValue
                                )
                              }
                            />
                            <SelectField
                              labelTitle="Number of Periods"
                              placeholder="Select Number of Periods"
                              name="number_of_periods"
                              required={true}
                              options={validNumberOfPeriods}
                              height={50}
                              valueKey="value"
                              display="value"
                              selectedOption={values.number_of_periods}
                              handleChangeFunc={(e) =>
                                handleMultiChange(
                                  "number_of_periods",
                                  e,
                                  setFieldValue
                                )
                              }
                            />
                            <InputFieldChange
                              placeholder="Enter End Time"
                              name="end_time"
                              required={true}
                              height={50}
                              marginTop={10}
                              isDisabled={true}
                              labelTitle="End Time"
                              value={values.end_time}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="app_input_group">
                            <SelectField
                              labelTitle="Staff"
                              placeholder="Select Staff"
                              name="staff_id"
                              required={true}
                              options={authorizedStaff}
                              height={50}
                              valueKey="_id"
                              display="nameTitle"
                              selectedOption={values.staff_id}
                              handleChangeFunc={(e) =>
                                handleMultiChange("staff_id", e, setFieldValue)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>

                    <div className="form-button-container">
                      <ButtonIcon
                        height={45}
                        marginTop={5}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={300}
                        borderColor="#633ccd"
                        buttonText="Update Schedule"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </TimeTableWrapper>
    </>
  );
};

export default UpdateSingleClassSchedule;
