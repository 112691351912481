import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { FaMinus, FaPlus } from "react-icons/fa";
import TableLoading from "../../components/skeleton/TableLoading";

const AddSubjectsToClass = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [subjectData, setSubjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [classSubjects, setClassSubjects] = useState([]);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [subjectIds, setSubjectIds] = useState([]);
  const [classTitle, setClassTitle] = useState("");
  const [checked, setChecked] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [classCategoryId, setClassCategoryId] = useState("");
  const { classId } = useParams();
  if (!classId) {
    navigate("/404");
  }

  useEffect(() => {
    // fetch subject
    getClassDetails();
  }, []);

  useEffect(() => {
    if (empty(selectedSubjectIds)) {
      setShowAddButton(false);
    } else {
      setShowAddButton(true);
    }
  }, [selectedSubjectIds]);

  /**
   * Get subjects in class category
   * @param {*} subjectsInClass
   * @param {*} classCategoryId
   */
  const getSubjects = async (subjectsInClass, classCategoryId) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId, classCategoryId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        setSubjectData([]);
      } else {
        const results =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        let updatedSubjectids = [];
        let updatedResults = [];
        for (let i = 0; i < results.length; i++) {
          const subjectId =
            !empty(results[i]) && !empty(results[i]._id) ? results[i]._id : "";
          updatedSubjectids.push(subjectId);
          results[i].added = subjectsInClass.includes(subjectId);
          updatedResults.push(results[i]);
        }
        setSubjectIds(updatedSubjectids);
        setSubjectData(updatedResults);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Get class details
   */
  const getClassDetails = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSingleClass(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to fetch class details"
        );
      } else {
        const classTitle =
          !empty(response_data.response) && !empty(response_data.response.title)
            ? response_data.response.title
            : "";
        const subjectsInClass =
          !empty(response_data.response) &&
          !empty(response_data.response.subjects)
            ? response_data.response.subjects
            : [];
        const _classCategoryId =
          !empty(response_data.response) &&
          !empty(response_data.response.categoryId)
            ? response_data.response.categoryId
            : "";
        setClassSubjects(subjectsInClass);
        setClassTitle(classTitle);
        setClassCategoryId(_classCategoryId);
        getSubjects(subjectsInClass, _classCategoryId);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    }
  };

  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const updateSelectedSubjectIds = (
    subjectId,
    sideAction = false,
    add = false
  ) => {
    try {
      let updatedSubjects = [];
      if (sideAction) {
        let action = "add";
        if (add) {
          updatedSubjects = [...classSubjects, subjectId];
        } else {
          action = "remove";
          const index = classSubjects.indexOf(subjectId);
          if (index > -1) {
            updatedSubjects = classSubjects.filter((id) => id !== subjectId);
          }
        }
        toggleSubjectInClass(action, updatedSubjects, sideAction);
      } else {
        const index = selectedSubjectIds.indexOf(subjectId);
        if (index > -1) {
          updatedSubjects = selectedSubjectIds.filter((id) => id !== subjectId);
        } else {
          updatedSubjects = [...selectedSubjectIds, subjectId];
        }
        setSelectedSubjectIds(updatedSubjects);
        if (updatedSubjects.length !== subjectIds.length) {
          setChecked(false);
        } else {
          setChecked(true);
        }
      }
    } catch (error) {}
  };

  const toggleSubjectInClass = async (
    action = "add",
    updatedSubjects = [],
    sideAction = false
  ) => {
    try {
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      let dataToUpdate = [];
      if (!sideAction) {
        if (action === "add") {
          if (
            selectedSubjectIds.every((item) => classSubjects.includes(item))
          ) {
            dataToUpdate = selectedSubjectIds;
          } else {
            dataToUpdate = [
              ...new Set([...classSubjects, ...selectedSubjectIds]),
            ];
          }
        } else {
          const filteredArray = classSubjects.filter(
            (item) => !selectedSubjectIds.includes(item)
          );
          dataToUpdate = filteredArray;
        }
      } else {
        dataToUpdate = updatedSubjects;
      }
      const response = await subjectApi.toggleSubjectsInClass(
        dataToUpdate,
        classId,
        schoolId
      );
      const response_data = prepareResponseData(response);
      setSelectedSubjectIds([]);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        responseDailog(
          "error",
          "Something went wrong",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Operation failed"
        );
      } else {
        await getClassDetails();
        responseDailog("success", "Success", "Operation successful");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const actionBodyTemplate = (rowData) => {
    const added =
      !empty(rowData) && !empty(rowData.added) && rowData.added ? true : false;
    const subjectId =
      !empty(rowData) && !empty(rowData._id) && rowData._id
        ? rowData._id
        : false;
    return (
      <Button
        icon={added ? "pi pi-minus" : "pi pi-plus"}
        style={{
          backgroundColor: "transparent",
          color: added ? "#e65061" : "#389d17",
          borderColor: added ? "#e65061" : "#389d17",
          borderWidth: 1,
          borderRadius: "50%",
        }}
        onClick={() => {
          updateSelectedSubjectIds(subjectId, true, added ? false : true);
        }}
      />
    );
  };

  const checkBoxBodyTemplate = (rowData) => {
    const subjectId = !empty(rowData) && !empty(rowData._id) ? rowData._id : "";
    return (
      <Checkbox
        checked={selectedSubjectIds.includes(subjectId)}
        onChange={() => updateSelectedSubjectIds(subjectId)}
      />
    );
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            <MainHeader
              children={
                showAddButton ? (
                  <>
                    <ButtonIcon
                      borderColor="#389d17"
                      backgroundColor="#389d17"
                      color="#ffffff"
                      icon={<FaPlus style={{ fontSize: 12 }} />}
                      buttonText="Add"
                      marginRight={1}
                      width={70}
                      height={30}
                      onClick={() => toggleSubjectInClass("add")}
                    />
                    <ButtonIcon
                      borderColor="red"
                      backgroundColor="red"
                      color="#ffffff"
                      icon={<FaMinus style={{ fontSize: 12 }} />}
                      buttonText="Remove"
                      width={100}
                      height={30}
                      onClick={() => toggleSubjectInClass("remove")}
                    />
                  </>
                ) : (
                  ""
                )
              }
              redirect={false}
              title={`Add/Remove Subject(s) from ${classTitle}`}
            />
            <div style={{ marginBottom: 10 }}>
              <span style={{ marginRight: 40 }}>
                <strong>No. of Subjects In class: </strong>
                {classSubjects.length}
              </span>
              <span>
                <strong>No. of Selected Subjects: </strong>
                {selectedSubjectIds.length}
              </span>
            </div>
            {!isLoading ? (
              <DataTable value={subjectData} tableStyle={{ minWidth: "30rem" }}>
                <Column
                  field="_id"
                  header={
                    <Checkbox
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.checked);
                        if (e.checked) {
                          setShowAddButton(true);
                          setSelectedSubjectIds(subjectIds);
                        } else {
                          setSelectedSubjectIds([]);
                        }
                      }}
                    />
                  }
                  body={checkBoxBodyTemplate}
                />
                <Column field="title" header="Title" />
                <Column field="code" header="Code" />
                <Column
                  field="added"
                  header="Action"
                  body={actionBodyTemplate}
                />
              </DataTable>
            ) : (
              <TableLoading cols={4} rows={20} />
            )}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default AddSubjectsToClass;
