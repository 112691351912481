export default {
  red: "#d23b3b",
  white: "#ffffff",
  secondary: "#000000",
  lightRed: "#f33c3c",
  success: "#22C55E",
  danger: "#e65061",
  primary: "#633ccd",
  black: "#000000",
  tomato: "tomato",
  lightgray: "#f1f1f1",
  darkgray: "#888",
  gray: "#777",
  gold: "#FDCC0D",
  light: "#fefefe",
  orange: "#f37658",
  transRed: "#f9010d26",
  charcoal: "#36454F",
};
