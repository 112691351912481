export const classSections = [
  {
    _id: 1,
    title: "Normal Time",
  },
  {
    _id: 2,
    title: "Lesson Time",
  },
];
