import { useContext, useEffect, useRef, useState } from "react";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import schoolApi from "../../api/School";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import TableLoading from "../../components/skeleton/TableLoading";
import { FaPlus } from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { NavLink } from "react-router-dom";
import colors from "../../config/colors";

const GraduationYears = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  //ref
  const toastTR = useRef(null);
  // states
  const [graduationYears, setGraduationYears] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // fetch graduation years
    try {
      getGraduationYears();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load graduation years. Please try again later."
      );
    }
  }, [user]);

  // function to get graduation years
  const getGraduationYears = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.getGraduationYears(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setGraduationYears([]);
      } else {
        return setGraduationYears(
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : []
        );
      }
    } catch (error) {
      console.log(error);
      responseDailog("error", "Error Alert", `Server error.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const classTitleTemplate = (rowData) => {
    const classTitle =
      !empty(rowData) && !empty(rowData.classTitle) ? rowData.classTitle : "";
    const graduationClassId =
      !empty(rowData) && !empty(rowData.graduationClassId)
        ? rowData.graduationClassId
        : "";
    const graduationYear =
      !empty(rowData) && !empty(rowData.graduationYear)
        ? rowData.graduationYear
        : "";
    return (
      <NavLink
        to={`/graduation/class/${graduationClassId}/${classTitle}/year/${graduationYear}`}
        style={{ color: colors.primary, textDecoration: "none" }}
      >
        {classTitle}
      </NavLink>
    );
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader title="Graduation Years/Classes" />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <DataTable
                value={graduationYears}
                tableStyle={{ minWidth: "100%" }}
              >
                <Column
                  field="graduationYear"
                  header="Graduation Year"
                ></Column>
                <Column
                  field="classTitle"
                  header="Class"
                  body={classTitleTemplate}
                ></Column>
                <Column
                  field="noOfStudents"
                  header="Number of Students"
                ></Column>
              </DataTable>
            ) : (
              <TableLoading cols={4} rows={20} />
            )}
            {/* table end  */}
          </div>
        </main>
      </AppWrapper>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default GraduationYears;
