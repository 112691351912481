import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  empty,
  isArray,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { Toast } from "primereact/toast";
import * as Yup from "yup";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "../staff/Staff.css";

// api
import studentApi from "../../api/Student";
import classApi from "../../api/Classes";
import termApi from "../../api/Terms";
import sessionApi from "../../api/Session";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import {
  FaBook,
  FaBookOpen,
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaCloudUploadAlt,
  FaEdit,
  FaExclamationTriangle,
  FaImage,
  FaStar,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import Underline from "../../components/others/Underline";
import BoxLoading from "../../components/skeleton/BoxLoading";
import ItemLoading from "../../components/skeleton/ItemLoading";
import TableLoading from "../../components/skeleton/TableLoading";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Form, Formik } from "formik";
import SelectField from "../../components/form/SelectField";
import InputField from "../../components/form/InputField";
import FullPageLoader from "../../components/loader/FullPageLoader";
import colors from "../../config/colors";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  classId: Yup.string().required(required),
  comment: Yup.string().optional(),
});

const initialValues = {
  classId: "",
  comment: "",
};

const StudentsProfile = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [studentData, setStudentData] = useState({});
  const [classTitle, setClassTitle] = useState("");
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);
  const [percentageLoading, setPercentageLoading] = useState(0);
  const [promotionModalOpen, setPromotionModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [classData, setClassData] = useState([]);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [isUploadAvatarModal, setIsUploadAvatarModal] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState("");
  const [studentReports, setStudentReports] = useState([]);

  const [avatar, setAvatar] = useState(
    !empty(studentData) && !empty(studentData.avatar) ? studentData.avatar : ""
  );
  const fileInputRef = useRef(null);
  const location = useLocation();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { studentId } = useParams();
  const toastTR = useRef(null);

  if (!studentId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    // check if a new student was added
    if (!empty(location) && !empty(location.state)) {
      const paramState = !empty(location.state) ? location.state : {};
      const studentAdded = !empty(paramState.studentAdded)
        ? paramState.studentAdded
        : false;
      const studentUpdated = !empty(paramState.studentUpdated)
        ? paramState.studentUpdated
        : false;
      if (studentAdded === true || studentUpdated === true) {
        const actionType = studentAdded ? "added" : "updated";
        paramState.studentAdded = false;
        paramState.studentUpdated = false;
        responseDailog(
          "success",
          "Success",
          `Student ${actionType} successfully!`
        );
        if (!empty(location) && !empty(location.state)) {
          navigate(null, {
            replace: true,
            state: { studentAdded: false, studentUpdated: false },
          });
        }
      }
    }

    getStudentDetails();
  }, []);

  useEffect(() => {
    getStudentPercentage();
    getClasses();
  }, [studentId, user]);

  const getReports = async (reports) => {
    try {
      setStudentReports([]);
      if (!empty(reports) && isArray(reports)) {
        const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
        const termResponse = await termApi.getTerms(schoolId);
        const response_data = prepareResponseData(termResponse);
        let reIndexedTerms = {};
        let reIndexedSessions = {};
        if (!empty(response_data) && !empty(response_data.response)) {
          reIndexedTerms =
            !empty(response_data.response) && isArray(response_data.response)
              ? reIndex(response_data.response, "_id")
              : {};
        }
        const sessionResponse = await sessionApi.getSessions(schoolId);
        const session_response_data = prepareResponseData(sessionResponse);
        if (
          !empty(session_response_data) &&
          !empty(session_response_data.response)
        ) {
          reIndexedSessions =
            !empty(session_response_data.response) &&
            isArray(session_response_data.response)
              ? reIndex(session_response_data.response, "_id")
              : {};
        }

        const _reports = [];
        for (let i = 0; i < reports.length; i++) {
          const termId =
            !empty(reports[i]) && !empty(reports[i].termId)
              ? reports[i].termId
              : "";
          const sessionId =
            !empty(reports[i]) && !empty(reports[i].sessionId)
              ? reports[i].sessionId
              : "";

          if (!empty(reIndexedSessions[sessionId])) {
            const title = !empty(reIndexedSessions[sessionId].title)
              ? reIndexedSessions[sessionId].title
              : "";
            reports[i].sessionTitle = title;
          }
          if (!empty(reIndexedTerms[termId])) {
            const title = !empty(reIndexedTerms[termId].title)
              ? reIndexedTerms[termId].title
              : "";
            reports[i].termTitle = title;
          }

          if (!empty(reports[i].termTitle) && !empty(reports[i].sessionTitle)) {
            _reports.push(reports[i]);
          }
        }
        _reports.sort((a, b) => {
          const paramA = a.termTitle + a.sessionTitle;
          const paramB = b.termTitle + b.sessionTitle;
          if (paramA < paramB) {
            return -1;
          }
          if (paramA > paramB) {
            return 1;
          }
          return 0;
        });
        setStudentReports(_reports);
      }
    } catch (error) {}
  };

  // function to get all classes
  const getClasses = async () => {
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassData([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setClassData(data);
      }
    } catch (error) {}
  };

  const getStudentDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.getStudentProfile(studentId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        navigate(-1);
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Failed to fetch student details"
        );
      }
      const studentDetails =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const graduated =
        !empty(response_data) && !empty(studentDetails.graduationClassId)
          ? true
          : false;
      const title =
        !empty(response_data) && !empty(studentDetails.classTitle)
          ? studentDetails.classTitle
          : graduated
          ? "Graduated"
          : "N/A";
      const subjects =
        !empty(studentDetails) && !empty(studentDetails.assignedSubjects)
          ? studentDetails.assignedSubjects
          : [];
      const reports =
        !empty(studentDetails) && !empty(studentDetails.reports)
          ? studentDetails.reports
          : [];
      setAvatar(
        !empty(studentDetails) && !empty(studentDetails.avatar)
          ? studentDetails.avatar
          : ""
      );
      setAssignedSubjects(subjects);
      setClassTitle(title);
      setNumOfSubjects(subjects.length);
      getReports(reports);
      return setStudentData(studentDetails);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (promotionModalOpen) setPromotionModalOpen(false);
      if (!isActionLoading) setIsActionLoading(true);
      const id =
        !empty(studentData) && !empty(studentData._id) ? studentData._id : "";
      if (empty(id)) {
        return responseDailog(
          "error",
          "Error Alert",
          "There are no selected students."
        );
      }
      const comment =
        !empty(values) && !empty(values.comment) ? values.comment : "";
      const _classId =
        !empty(values) && !empty(values.classId) ? values.classId : "";
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";

      const classId =
        !empty(studentData) && !empty(studentData.class)
          ? studentData.class
          : !empty(studentData) && !empty(studentData.currentClassId)
          ? studentData.currentClassId
          : "";
      const response = await studentApi.promoteStudent(
        [id],
        schoolId,
        comment,
        _classId,
        classId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to promote the selected student.`;
        return responseDailog("error", "Error Alert", error_response);
      }

      responseDailog("success", "Success", `Promoted student successfully!`);
      getStudentPercentage();
      return getStudentDetails();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const getStudentPercentage = async () => {
    setPercentageLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.getStudentPercentage(
        studentId,
        schoolId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return;
      }
      setAssessmentPercentage(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setPercentageLoading(false);
    }
  };

  // Function to trigger the hidden file input click
  const handleImageFilePickUp = () => {
    fileInputRef.current.click();
  };

  // Function to handle the selected file
  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        // Convert file to Base64 when the file is loaded
        reader.onloadend = async () => {
          const base64String = reader.result;
          setPreviewAvatar(base64String);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {}
  };

  const submitAvatar = async () => {
    // send file to the server
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(studentData) && !empty(studentData._id) ? studentData._id : "";
      const response = await studentApi.uploadStudentAvatar(
        schoolId,
        studentId,
        previewAvatar,
        "students"
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || empty(response_data.success)) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to update school avatar!"
        );
      }

      setAvatar(previewAvatar);

      return responseDailog(
        "success",
        "Success",
        "School avatar updated successfully."
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
      setPreviewAvatar("");
      setIsUploadAvatarModal(false);
    }
  };

  const firstName =
    !empty(studentData) && !empty(studentData.firstName)
      ? studentData.firstName
      : "";
  const lastName =
    !empty(studentData) && !empty(studentData.lastName)
      ? studentData.lastName
      : "";
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);
  const middleName =
    !empty(studentData) && !empty(studentData.middleName)
      ? studentData.middleName
      : "";
  const gender =
    !empty(studentData) && !empty(studentData.gender) ? studentData.gender : "";
  const regNo =
    !empty(studentData) && !empty(studentData.regNo) ? studentData.regNo : "";
  const address =
    !empty(studentData) && !empty(studentData.address)
      ? studentData.address
      : "";
  const stateOfResidence =
    !empty(studentData) && !empty(studentData.stateOfResidence)
      ? studentData.stateOfResidence
      : "";
  const lgaOfResidence =
    !empty(studentData) && !empty(studentData.lgaOfResidence)
      ? studentData.lgaOfResidence
      : "";
  const stateOfOrigin =
    !empty(studentData) && !empty(studentData.stateOfOrigin)
      ? studentData.stateOfOrigin
      : "";
  const lgaOfOrigin =
    !empty(studentData) && !empty(studentData.lgaOfOrigin)
      ? studentData.lgaOfOrigin
      : "";
  const nationality =
    !empty(studentData) && !empty(studentData.nationality)
      ? studentData.nationality
      : "";
  const religion =
    !empty(studentData) && !empty(studentData.religion)
      ? studentData.religion
      : "";
  const dateOfBirth =
    !empty(studentData) && !empty(studentData.dateOfBirth)
      ? studentData.dateOfBirth
      : "";
  const active =
    !empty(studentData) && !empty(studentData.active) ? studentData.active : "";
  const phoneNumber =
    !empty(studentData) && !empty(studentData.phoneNumber)
      ? studentData.phoneNumber
      : "";
  const email =
    !empty(studentData) && !empty(studentData.email) ? studentData.email : "";
  const previousSchool =
    !empty(studentData) && !empty(studentData.previous_school)
      ? studentData.previous_school
      : "";
  const qrCodePath =
    !empty(studentData) && !empty(studentData.qrCodePath)
      ? studentData.qrCodePath
      : "";
  const hasPaid =
    !empty(studentData) && !empty(studentData.paid) ? studentData.paid : "No";
  const graduated =
    !empty(studentData) && !empty(studentData.graduationClassId) ? true : false;

  //function to show delete modal
  const showDeleteModal = () => {
    setModalVisible(true);
  };

  //function to hide confirm modal
  const hideDeleteModal = () => {
    setModalVisible(false);
  };

  // hide activation modal
  const hidePromotionModal = () => {
    setPromotionModalOpen(false);
  };
  // hide activation modal
  const hideActivationModal = () => {
    setActivationModalOpen(false);
  };

  const deleteStudent = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.deleteStudent(studentId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Failed to delete student."
        );
      }

      // redirect to students list
      const studentDeleted = true;
      navigate("/students", {
        state: { studentDeleted },
      });
    } catch (error) {
      return responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
      return setModalVisible(false);
    }
  };

  //function to activate student on payment
  const activateStudent = async () => {
    try {
      if (activationModalOpen) setActivationModalOpen(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await studentApi.activateStudent([studentId], schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : `Failed to activate student`;
        return responseDailog("error", "Error Alert", error_response);
      }

      return responseDailog(
        "success",
        "Success",
        `Activated student successfully!`
      );
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const activateStudentDialogFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideActivationModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={() => {
          activateStudent();
        }}
      />
    </div>
  );

  const modalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideDeleteModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={deleteStudent}
      />
    </div>
  );

  const sessionId =
    !empty(user) && !empty(user.currentSessionId) ? user.currentSessionId : "";
  const termId =
    !empty(user) && !empty(user.currentTermId) ? user.currentTermId : "";
  const classId =
    !empty(studentData) && !empty(studentData.class)
      ? studentData.class
      : !empty(studentData) && !empty(studentData.currentClassId)
      ? studentData.currentClassId
      : "";

  const goToReportSheet = () => {
    if (
      !empty(studentId) &&
      !empty(sessionId) &&
      !empty(termId) &&
      !empty(classId)
    ) {
      return navigate(
        `/student/${studentId}/report-sheet/session/${sessionId}/term/${termId}/class/${classId}`
      );
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Student Profile" />
          {/* end menu header */}
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#f27c1b"
                        title="Class Title"
                        entry={classTitle}
                        icon={<FaUserGraduate className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#633ccd"
                        title="Subjects"
                        entry={numOfSubjects}
                        icon={<FaBookReader className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !percentageLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#e65061"
                        title="Assessment"
                        entry={assessmentPercentage + "%"}
                        icon={<FaChartLine className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#98c38b"
                      title="Attendance"
                      entry="N/A"
                      icon={<FaChartArea className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Student Bio-Data"
                        rightIcon={
                          hasPaid === "Yes" ? (
                            <NavLink to={`/student/${studentId}/update`}>
                              <FaEdit
                                style={{ color: "#4338CA", cursor: "pointer" }}
                              />
                            </NavLink>
                          ) : null
                        }
                      />
                      <div className="dashboard_school_list">
                        <div className="avatar-container">
                          <div className="avatar-box">
                            {!empty(avatar) ? (
                              <Avatar
                                image={avatar}
                                shape="circle"
                                style={{
                                  width: 200,
                                  height: 200,
                                  border: "4px solid " + colors.lightgray,
                                  padding: 10,
                                }}
                                className="p-overlay-badge"
                              >
                                <Badge
                                  style={{
                                    position: "absolute",
                                    top: 22,
                                    right: 15,
                                    width: 15,
                                    height: 15,
                                    backgroundColor: active
                                      ? colors.success
                                      : colors.danger,
                                  }}
                                  className="avatar-badge"
                                />
                              </Avatar>
                            ) : (
                              <Avatar
                                label={lastNameInitial + firstNameInitial}
                                shape="circle"
                                style={{
                                  width: 150,
                                  height: 150,
                                  fontSize: 70,
                                }}
                                className="p-overlay-badge"
                              >
                                <Badge
                                  style={{
                                    position: "absolute",
                                    top: 22,
                                    right: 22,
                                    width: 15,
                                    height: 15,
                                    backgroundColor: active
                                      ? colors.success
                                      : colors.danger,
                                  }}
                                  className="avatar-badge"
                                />
                              </Avatar>
                            )}
                          </div>
                          {!graduated && (
                            <div className="flex flex-center w-100pc">
                              <ButtonIcon
                                height={50}
                                marginTop={1}
                                color={colors.primary}
                                backgroundColor="transparent"
                                width={180}
                                fontSize={22}
                                borderColor="transparent"
                                buttonText="Update Avatar"
                                iconMarginRight={8}
                                icon={<FaCloudUploadAlt />}
                                fontWeight="bold"
                                onClick={() => setIsUploadAvatarModal(true)}
                              />
                            </div>
                          )}

                          {hasPaid !== "Yes" && !graduated && (
                            <>
                              <div className="flex flex-center w-100pc mt-10">
                                <div className="mt-10">
                                  <ButtonIcon
                                    borderColor={colors.danger}
                                    backgroundColor={colors.danger}
                                    color="#ffffff"
                                    buttonText="Activate Account"
                                    onClick={() => setActivationModalOpen(true)}
                                  />
                                </div>
                              </div>
                              <div className="mt-10 assessment_not_paid">
                                <FaExclamationTriangle color="red" size={25} />
                                <p>
                                  This student has an outstanding portal levy
                                </p>
                              </div>
                            </>
                          )}
                          <div className="information-container">
                            <div className="form-section-title mb-20">
                              Personal Information
                              <Underline />
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">
                                Registration Number:{" "}
                              </strong>
                              <div>{!isLoading ? regNo : <ItemLoading />}</div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">First Name: </strong>
                              <div>
                                {!isLoading ? firstName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">Last Name: </strong>
                              <div>
                                {!isLoading ? lastName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">Middle Name: </strong>
                              <div>
                                {!isLoading ? middleName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">Gender: </strong>
                              <div>{!isLoading ? gender : <ItemLoading />}</div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">Address: </strong>
                              <div>
                                {!isLoading ? address : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">
                                State of Residence:{" "}
                              </strong>
                              <div>
                                {!isLoading ? (
                                  stateOfResidence
                                ) : (
                                  <ItemLoading />
                                )}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">
                                L.G.A of Residence:{" "}
                              </strong>
                              <div>
                                {!isLoading ? lgaOfResidence : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">
                                State of Origin:{" "}
                              </strong>
                              <div>
                                {!isLoading ? stateOfOrigin : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">
                                L.G.A of Origin:{" "}
                              </strong>
                              <div>
                                {!isLoading ? lgaOfOrigin : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">Nationality: </strong>
                              <div>
                                {!isLoading ? nationality : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">Religion: </strong>
                              <div>
                                {!isLoading ? religion : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">Date of Birth: </strong>
                              <div>
                                {!isLoading ? dateOfBirth : <ItemLoading />}
                              </div>
                            </div>
                            <div className="form-section-title mb-20">
                              Contact Information
                              <Underline />
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">
                                Phone Number 1:{" "}
                              </strong>
                              <div>
                                {!isLoading ? phoneNumber : <ItemLoading />}
                              </div>
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">Email: </strong>
                              <div>{!isLoading ? email : <ItemLoading />}</div>
                            </div>
                            <div className="form-section-title mb-20">
                              Academic Information
                              <Underline />
                            </div>
                            <div className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-14">
                                Previous School:{" "}
                              </strong>
                              <div>
                                {!isLoading ? previousSchool : <ItemLoading />}
                              </div>
                            </div>
                          </div>
                          {!empty(qrCodePath) && (
                            <div>
                              <div className="form-section-title mb-20">
                                QR-Code
                                <Underline />
                              </div>
                              <div>
                                <img
                                  src={qrCodePath}
                                  width={200}
                                  height={200}
                                />
                              </div>
                            </div>
                          )}
                          {!graduated && (
                            <>
                              <div className="form-section-title">
                                <strong>Critical Area</strong>
                              </div>
                              <div>
                                <div className="form-section-title mb-10">
                                  Promote/Demote Student
                                </div>
                                <span>
                                  <strong>Note: </strong>Ensure you select the
                                  right class.
                                </span>
                                <div className="mt-10">
                                  <ButtonIcon
                                    borderColor={colors.primary}
                                    backgroundColor={colors.primary}
                                    color={colors.white}
                                    buttonText="Promote Student"
                                    onClick={() => setPromotionModalOpen(true)}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="form-section-title mb-10">
                                  Delete Student
                                </div>
                                <span>
                                  <strong>Note: </strong>This action cannot be
                                  undone. Ensure that you intend to delete this
                                  student before continuing.
                                </span>
                                <div className="mt-10">
                                  <ButtonIcon
                                    borderColor={colors.danger}
                                    backgroundColor={colors.danger}
                                    color="#ffffff"
                                    buttonText="Delete Student"
                                    onClick={showDeleteModal}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  }
                  addStyle="student_list_box"
                />
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              <div className="main_right_boxes">
                {!graduated && (
                  <Card
                    children={
                      <>
                        <MainHeader title="Class Subjects" />
                        <div className="mt-10">
                          {!isLoading ? (
                            assignedSubjects.map((item) => {
                              return (
                                <p
                                  key={
                                    !empty(item) && !empty(item._id)
                                      ? item._id
                                      : 0
                                  }
                                  className="mb-10 flex space-between bio-data-item"
                                >
                                  <span>
                                    <FaStar
                                      color="#DAA520"
                                      style={{ marginRight: 8, fontSize: 12 }}
                                    />
                                    {!empty(item) && !empty(item.title)
                                      ? item.title
                                      : ""}
                                  </span>
                                </p>
                              );
                            })
                          ) : (
                            <TableLoading rows={5} cols={1} header={false} />
                          )}
                        </div>
                      </>
                    }
                    addStyle="card_adjust"
                  />
                )}
                <Card
                  children={
                    <>
                      <MainHeader title="Performance History" />
                      <p className="mb-20">
                        <NavLink
                          to={`/student/${studentId}/transcript`}
                          style={{
                            textDecoration: "none",
                            color: colors.primary,
                          }}
                        >
                          <strong>
                            <span>
                              <FaBookOpen />{" "}
                            </span>
                            <span>Go to Transcript</span>
                          </strong>
                        </NavLink>
                        <div
                          onClick={goToReportSheet}
                          style={{
                            color: colors.primary,
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                        >
                          <strong>
                            <span>
                              <FaBook />{" "}
                            </span>
                            <span>Go to Report Sheet</span>
                          </strong>
                        </div>
                      </p>
                      <div className="mt-10">
                        {!isLoading ? (
                          studentReports.map((item, index) => {
                            const termTitle =
                              !empty(item) && !empty(item.termTitle)
                                ? item.termTitle
                                : "";
                            const sessionTitle =
                              !empty(item) && !empty(item.sessionTitle)
                                ? item.sessionTitle
                                : "";
                            const cumulativeScore =
                              !empty(item) && !empty(item.cumulativeScore)
                                ? item.cumulativeScore
                                : "";
                            const average =
                              !empty(item) && !empty(item.average)
                                ? item.average + "%"
                                : "";
                            const grade =
                              !empty(item) && !empty(item.grade)
                                ? item.grade
                                : "";
                            const remark =
                              !empty(item) && !empty(item.remark)
                                ? item.remark
                                : "";
                            const position =
                              !empty(item) && !empty(item.position)
                                ? item.position
                                : "";
                            return (
                              <div
                                key={index}
                                className="mb-10 flex direction-column bio-data-item"
                              >
                                <span>
                                  <FaStar
                                    color="#DAA520"
                                    style={{ marginRight: 8, fontSize: 12 }}
                                  />
                                  {termTitle} ({sessionTitle})
                                </span>
                                <p>
                                  <ul style={{ padding: "8px 35px" }}>
                                    <li>
                                      <span>
                                        <strong>CumulativeScore:&nbsp;</strong>
                                      </span>
                                      <span>{cumulativeScore}</span>
                                    </li>
                                    <li>
                                      <span>
                                        <strong>Average:&nbsp;</strong>
                                      </span>
                                      <span>{average}</span>
                                    </li>
                                    <li>
                                      <span>
                                        <strong>Grade:&nbsp;</strong>
                                      </span>
                                      <span>{grade}</span>
                                    </li>
                                    <li>
                                      <span>
                                        <strong>Position:&nbsp;</strong>
                                      </span>
                                      <span>{position}</span>
                                    </li>
                                    <li>
                                      <span>
                                        <strong>Remark:&nbsp;</strong>
                                      </span>
                                      <span>{remark}</span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            );
                          })
                        ) : (
                          <TableLoading rows={5} cols={1} header={false} />
                        )}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
              </div>
            </div>
            {/* end of right box */}
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>

      <Dialog
        visible={modalVisible}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={modalFooter}
        onHide={hideDeleteModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          <p>
            Are you sure you want to delete the student?
            <br />
            <em>
              <strong style={{ color: colors.danger }}>
                PS: This operation can not be undone.
              </strong>
            </em>
          </p>
        </div>
      </Dialog>
      <Dialog
        visible={promotionModalOpen}
        className="promotion-modal"
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm Promotion"
        modal
        onHide={hidePromotionModal}
      >
        <div
          className="confirmation-content"
          style={{ display: "flex", alignItems: "center" }}
        >
          {
            <>
              <div className="form-container">
                <div>
                  <p>
                    <strong>Current Class:</strong> {classTitle}
                  </p>
                  <br></br>
                  <p className="flex align-center">
                    You are about to promote the selected student(s). Ensure
                    that you are promoting the right students to the right
                    class.
                  </p>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit, values, handleChange }) => (
                    <Form style={{ width: "100%" }}>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          columnGap: 12,
                        }}
                      >
                        <SelectField
                          labelTitle="Promote to"
                          placeholder="Select class"
                          name="classId"
                          required={true}
                          options={classData}
                          height={50}
                          valueKey="id"
                          selectedOption={values.classId}
                          handleChangeFunc={handleChange}
                          customPlaceholder="Graduated"
                          customValue="graduated"
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          columnGap: 12,
                          alignItems: "center",
                          marginBottom: 12,
                        }}
                      >
                        <InputField
                          placeholder="Enter Comment"
                          name="comment"
                          height={180}
                          as="textarea"
                          row={9}
                          cols={8}
                          value=""
                          labelTitle="Comment"
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ButtonIcon
                          height={45}
                          marginTop={5}
                          color="#ffffff"
                          backgroundColor="#633ccd"
                          width={300}
                          borderColor="#633ccd"
                          buttonText="Promote"
                          type="submit"
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          }
        </div>
      </Dialog>

      <Dialog
        visible={activationModalOpen}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={activateStudentDialogFooter}
        onHide={hideActivationModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <>
              <p>
                Are you sure you want to <strong>activate</strong> this student?{" "}
                <br />
                <br />
                By clicking "Yes", you are confirming that{" "}
                <strong>{lastName + " " + firstName + " has"}</strong> made
                portal payment for this term.
                <br />
                <br />
                <em style={{ color: colors.danger }}>
                  <strong>Note:</strong> This action cannot be undone.
                </em>
              </p>
            </>
          }
        </div>
      </Dialog>

      <Dialog
        visible={isUploadAvatarModal}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm Action"
        modal
        onHide={() => {
          setPreviewAvatar("");
          setIsUploadAvatarModal(false);
        }}
      >
        <div
          className="confirmation-content"
          style={{
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="avatar-preview-box mb-20">
            {!empty(previewAvatar) ? (
              <img src={previewAvatar} alt="" />
            ) : (
              <FaCloudUploadAlt size={80} />
            )}
          </div>
          {<span>Select image to upload</span>}

          <div className="flex flex-center w-100pc col-g-12">
            <ButtonIcon
              height={40}
              marginTop={1}
              color={colors.tomato}
              backgroundColor={colors.lightgray}
              width={170}
              fontSize={22}
              borderColor={colors.lightgray}
              buttonText="Select Avatar"
              iconMarginRight={8}
              icon={<FaImage />}
              fontWeight="bold"
              onClick={handleImageFilePickUp}
            />
            {previewAvatar ? (
              <ButtonIcon
                height={40}
                marginTop={1}
                color={colors.primary}
                backgroundColor={colors.lightgray}
                width={180}
                fontSize={22}
                borderColor={colors.lightgray}
                buttonText="Update Avatar"
                iconMarginRight={8}
                icon={<FaCloudUploadAlt />}
                fontWeight="bold"
                onClick={() => submitAvatar()}
              />
            ) : null}
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              style={{ display: "none" }}
              accept="image/*"
            />
          </div>
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default StudentsProfile;
