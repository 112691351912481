import client from "./Client";

const addSubject = (school_id, title, code, active, discipline, categories) =>
  client.post("/subject/add", {
    school_id,
    title,
    code,
    active,
    discipline,
    categories,
  });

const getSubjects = (schoolId, classCategoryId) => {
  const params = {};
  if (classCategoryId) {
    params.categoryId = classCategoryId;
  }
  return client.get(`/${schoolId}/subjects`, { params });
};

const toggleSubjectVisibility = (subject_id, active, school_id) =>
  client.post(`/subject/visibility/toggle`, {
    school_id,
    subject_id,
    active,
  });

const toggleSubjectsInClass = (subject_ids, class_id, school_id) =>
  client.post(`/class/subjects/toggle`, {
    school_id,
    id: class_id,
    subject_ids,
  });

const toggleStaffSubjects = (
  re_indexed_authorized_subjects,
  staff_id,
  school_id
) =>
  client.post(`/staff/subjects/assign`, {
    re_indexed_authorized_subjects,
    id: staff_id,
    school_id,
  });

const updateSubject = (
  subject_id,
  school_id,
  title,
  code,
  active,
  discipline_id,
  categories
) =>
  client.post("/subject/update", {
    subject_id,
    school_id,
    title,
    code,
    active,
    discipline_id,
    categories,
  });

const deleteSubject = (subject_id, school_id) =>
  client.post("/subject/delete", {
    subject_id,
    school_id,
  });

const getSingleSubject = (subject_id, school_id) =>
  client.get(`/${school_id}/subject/${subject_id}`);

const positionSubject = (
  school_id,
  class_id,
  session_id,
  term_id,
  subject_id
) =>
  client.post("/student/subject/position", {
    school_id,
    class_id,
    session_id,
    term_id,
    subject_id,
  });

export default {
  addSubject,
  getSubjects,
  toggleSubjectVisibility,
  updateSubject,
  getSingleSubject,
  deleteSubject,
  toggleSubjectsInClass,
  toggleStaffSubjects,
  positionSubject,
};
