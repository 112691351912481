import { useContext, useRef, useState } from "react";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import * as Yup from "yup";

// css
import "../students/Students.css";
import "./Attendance.css";

// api
import attendanceApi from "../../api/Attendance";

// image
import student_avatar from "../../assets/avatars/profile_avatar.png";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import AttendanceWrapper from "../../components/appWrapper/AttendanceWrapper";
import { Form, Formik } from "formik";
import InputField from "../../components/form/InputField";
import { Avatar } from "primereact/avatar";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import colors from "../../config/colors";
import { FaTimes } from "react-icons/fa";

const initialValues = {
  reg_no: "",
};

const validationSchema = Yup.object().shape({
  reg_no: Yup.string().required("The Reg No. field is required."),
});

const AttendanceManual = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  //ref
  const toastTR = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [studentDataPanelOpen, setStudentDataPanelOpen] = useState(false);

  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const handleSubmit = async (values) => {
    if (!isLoading) setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }

      const reg_no =
        !empty(values) && !empty(values.reg_no) ? values.reg_no : "";
      const school_id = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await attendanceApi.getStudentAttendanceDetails(
        school_id,
        reg_no
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to retrieve student data"
        );
      }
      const studentData =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      setStudentData(studentData);
      setStudentDataPanelOpen(true);
    } catch (error) {
      responseDailog("error", "Internal error", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const closeProfile = () => {
    setStudentDataPanelOpen(false);
    setStudentData({});
  };

  const submitAttendance = async (type) => {
    if (!isLoading) setIsLoading(true);
    try {
      if (empty(studentData)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }

      const schoolId = !empty(studentData.schoolId) ? studentData.schoolId : "";
      const studentId = !empty(studentData._id) ? studentData._id : "";
      const creatorId = !empty(user._id) ? user._id : "";
      const response = await attendanceApi.recordAttendance(
        schoolId,
        studentId,
        creatorId,
        type
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to record attendance."
        );
      }
      closeProfile();
      return responseDailog(
        "success",
        "Success",
        "Attendance recorded successfully."
      );
    } catch (error) {
      responseDailog("error", "Internal error", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AttendanceWrapper {...props}>
        <main>
          <div className="tableCard">
            <MainHeader title="Attendance" />
          </div>
          <div className="form-container mt-10">
            {!studentDataPanelOpen && (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form style={{ width: "100%" }}>
                  <div>
                    <em>
                      <strong>Note: *</strong> Please enter the correct details
                      for the student
                    </em>
                  </div>
                  <div
                    style={{ width: "100%", display: "flex", columnGap: 12 }}
                  >
                    <InputField
                      placeholder="Enter Student Registration Number"
                      name="reg_no"
                      height={50}
                      labelTitle="Registration Number"
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ButtonIcon
                      height={45}
                      marginTop={5}
                      color="#ffffff"
                      backgroundColor="#633ccd"
                      width={300}
                      borderColor="#633ccd"
                      buttonText="Submit"
                      type="submit"
                    />
                  </div>
                </Form>
              </Formik>
            )}
            {!empty(studentData) && studentDataPanelOpen && (
              <div className="attendance-profile-box">
                <div className="attendance-profile-close">
                  <FaTimes
                    color={colors.danger}
                    onClick={() => closeProfile()}
                  />
                </div>
                <div className="attendance-profile-header">Student Details</div>
                <div className="attendance-profile-avatar">
                  <Avatar
                    image={
                      !empty(studentData.avatar)
                        ? studentData.avatar
                        : student_avatar
                    }
                    shape="circle"
                    style={{ width: 120, height: 120 }}
                  />
                </div>
                <div className="attendance-profile-body">
                  <p>
                    <strong className="mr-10">Reg. No:</strong>
                    {!empty(studentData.regNo) ? " " + studentData.regNo : ""}
                  </p>
                  <p>
                    <strong className="mr-10">First Name:</strong>
                    {!empty(studentData.firstName)
                      ? " " + studentData.firstName
                      : ""}
                  </p>
                  <p>
                    <strong className="mr-10">Last Name:</strong>
                    {!empty(studentData.lastName)
                      ? " " + studentData.lastName
                      : ""}
                  </p>
                  <p>
                    <strong className="mr-10">Middle Name:</strong>
                    {!empty(studentData.middleName)
                      ? " " + studentData.middleName
                      : ""}
                  </p>
                  <p>
                    <strong className="mr-10">Class:</strong>
                    {!empty(studentData.currentClass)
                      ? " " + studentData.currentClass
                      : ""}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      columnGap: 8,
                    }}
                  >
                    <ButtonIcon
                      height={45}
                      marginTop={5}
                      color="#ffffff"
                      backgroundColor={colors.primary}
                      width={120}
                      borderColor={colors.primary}
                      buttonText="Check-In"
                      onClick={() => submitAttendance("check-in")}
                    />
                    <ButtonIcon
                      height={45}
                      marginTop={5}
                      color="#ffffff"
                      backgroundColor={colors.danger}
                      width={120}
                      borderColor={colors.danger}
                      buttonText="Check-Out"
                      onClick={() => submitAttendance("check-out")}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AttendanceWrapper>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default AttendanceManual;
