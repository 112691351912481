import { useContext, useRef, useState, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import classApi from "../../api/Classes";

// data
import { Intervals } from "../../data/Intervals";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { AuthContext } from "../Root/ProtectedRoute";
import { empty, prepareResponseData } from "../../Utilities/utils";
import InputField from "../../components/form/InputField";
import SelectField from "../../components/form/SelectField";
import TimeTableWrapper from "../../components/appWrapper/TimeTableWrapper";
import { Numbers } from "../../data/Numbers";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  category_id: Yup.string().required(required),
  interval: Yup.string().required(required),
});
const lessonValidationSchema = Yup.object().shape({
  lesson_class_interval: Yup.string().required(required),
});
const maxClassValidationSchema = Yup.object().shape({
  max_class_periods_in_a_row: Yup.number().required(required),
});

const UpdateClassScheduleInterval = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const toastTR = useRef(null);
  const [classCategories, setClassCategories] = useState([]);
  const lessonClassInterval =
    !empty(user) &&
    !empty(user.periodSettings.lessonClassInterval) &&
    !empty(user.periodSettings.lessonClassInterval)
      ? user.periodSettings.lessonClassInterval
      : "";
  const maxClassPeriodsInARow =
    !empty(user) &&
    !empty(user.periodSettings) &&
    !empty(user.periodSettings.maxClassPeriodsInARow)
      ? user.periodSettings.maxClassPeriodsInARow
      : 2;

  useEffect(() => {
    getClassCategories();
  }, []);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getClassCategories = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClassCategories(schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassCategories([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setClassCategories(data);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        responseDailog("error", "Error Alert", "Something went wrong.");
      }
      const interval = !empty(values.interval) ? values.interval : "";
      const categoryId = !empty(values.category_id) ? values.category_id : "";

      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.updateClassScheduleInterval(
        categoryId,
        schoolId,
        interval
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || empty(response_data.success)) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to update interval!"
        );
      }

      responseDailog(
        "success",
        "Success",
        "Category Schedule Interval updated successfully."
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLessonClassInterval = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        responseDailog("error", "Error Alert", "Something went wrong.");
      }
      const lessonClassInterval = !empty(values.lesson_class_interval)
        ? values.lesson_class_interval
        : "";

      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.updateLessonClassInterval(
        schoolId,
        lessonClassInterval
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || empty(response_data.success)) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to update lesson class interval!"
        );
      }

      responseDailog(
        "success",
        "Success",
        "Lesson Class Interval updated successfully."
      );
    } catch (error) {
      responseDailog(
        "error",
        "Error Alert",
        "Failed to update lesson class interval."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleMaxClassPeriodsInARow = async (values) => {
    try {
      if (!isLoading) setIsLoading(true);
      if (empty(values)) {
        responseDailog("error", "Error Alert", "Something went wrong.");
      }
      const maxClassPeriodsInARow = !empty(values.max_class_periods_in_a_row)
        ? values.max_class_periods_in_a_row
        : 2;

      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.updateMaxClassPeriodsInARow(
        schoolId,
        maxClassPeriodsInARow
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || empty(response_data.success)) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to update maximum class periods in a row!"
        );
      }

      responseDailog(
        "success",
        "Success",
        "Maximum class periods in a row updated successfully."
      );
    } catch (error) {
      responseDailog(
        "error",
        "Error Alert",
        "Failed to update maximum class periods in a row."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TimeTableWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader title="Update Class Schedule Intervals" />
            <div className="form-container mt-30">
              <div
                style={{
                  fontSize: 22,
                  color: "#633ccd",
                  fontWeight: "bold",
                  marginBottom: 20,
                }}
              >
                Maximum Class Periods in a Row
              </div>
              <Formik
                initialValues={{
                  max_class_periods_in_a_row: maxClassPeriodsInARow,
                }}
                onSubmit={handleMaxClassPeriodsInARow}
                validationSchema={maxClassValidationSchema}
              >
                {({ handleSubmit, values, handleChange }) => (
                  <Form style={{ width: "100%", marginBottom: 50 }}>
                    <div className="grade-setting-block">
                      <SelectField
                        labelTitle="Max. Class Periods in a row"
                        placeholder="Select max class periods in a row"
                        name="max_class_periods_in_a_row"
                        options={Numbers.slice(1, 9)}
                        height={50}
                        valueKey="value"
                        display="value"
                        selectedOption={values.max_class_periods_in_a_row}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="flex-start form-button-container">
                      <ButtonIcon
                        height={45}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={120}
                        borderColor="#633ccd"
                        buttonText="Update"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="form-container mt-30">
              <div
                style={{
                  fontSize: 22,
                  color: "#633ccd",
                  fontWeight: "bold",
                  marginBottom: 20,
                }}
              >
                Class Schedule Intervals
              </div>
              <Formik
                initialValues={{
                  lesson_class_interval: lessonClassInterval,
                }}
                onSubmit={handleLessonClassInterval}
                validationSchema={lessonValidationSchema}
              >
                {({ handleSubmit, values, handleChange }) => (
                  <Form style={{ width: "100%", marginBottom: 50 }}>
                    <div
                      style={{
                        fontSize: 16,
                        color: "#633ccd",
                      }}
                      className="flex space-between"
                    >
                      <span>LESSON PERIOD SCHEDULE</span>
                    </div>
                    <div className="grade-setting-block">
                      <SelectField
                        labelTitle="Lesson Class Interval"
                        placeholder="Select Lesson Class Interval"
                        name="lesson_class_interval"
                        options={Intervals}
                        height={50}
                        valueKey="value"
                        display="title"
                        selectedOption={values.lesson_class_interval}
                        handleChangeFunc={handleChange}
                      />
                    </div>
                    <div className="flex-start form-button-container">
                      <ButtonIcon
                        height={45}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={120}
                        borderColor="#633ccd"
                        buttonText="Update"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
              {classCategories.map((data, key) => {
                const categoryId =
                  !empty(data) && !empty(data._id) ? data._id : "";
                const title =
                  !empty(data) && !empty(data.title) ? data.title : "";
                const interval =
                  !empty(data) && !empty(data.classScheduleInterval)
                    ? data.classScheduleInterval
                    : "";

                return (
                  <Formik
                    key={key}
                    initialValues={{
                      category_id: categoryId,
                      interval,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ handleSubmit, values, handleChange }) => (
                      <Form style={{ width: "100%", marginBottom: 50 }}>
                        <div
                          style={{
                            fontSize: 16,
                            color: "#633ccd",
                          }}
                          className="flex space-between"
                        >
                          <span>{title}</span>
                        </div>
                        <div className="grade-setting-block">
                          <div className="grade-setting-block-content">
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <SelectField
                                labelTitle="Interval"
                                placeholder="Select Interval"
                                name="interval"
                                options={Intervals}
                                height={50}
                                valueKey="value"
                                display="title"
                                selectedOption={values.interval}
                                handleChangeFunc={handleChange}
                              />
                              <InputField
                                placeholder=""
                                name="category_id"
                                height={10}
                                value={categoryId}
                                required={true}
                                labelTitle=""
                                isHidden={true}
                              />
                            </div>
                            <div className="flex-start form-button-container">
                              <ButtonIcon
                                height={45}
                                color="#ffffff"
                                backgroundColor="#633ccd"
                                width={120}
                                borderColor="#633ccd"
                                buttonText="Update"
                                type="submit"
                              />
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                );
              })}
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </TimeTableWrapper>
    </>
  );
};

export default UpdateClassScheduleInterval;
