import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LogOut() {
  const navigate = useNavigate();

  useEffect(() => {
    const schoolToken = localStorage.getItem("schoolAccessToken");
    if (schoolToken) {
      localStorage.removeItem("schoolAccessToken");
    }
    navigate("/");
  }, []);

  return <div>Logged Out</div>;
}

export default LogOut;
