import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { isObject } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import {
  calculateEndTime,
  checkTimeOverlap,
  empty,
  generateValidLessonStartTime,
  generateValidStartTimes,
  isArray,
  isUndefined,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../timeTable/TimeTable.css";

// hooks
import { AuthContext } from "../Root/ProtectedRoute";

// api
import subjectApi from "../../api/Subject";
import staffApi from "../../api/Staff";
import classApi from "../../api/Classes";
import schoolApi from "../../api/School";

// data

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import FullPageLoader from "../../components/loader/FullPageLoader";
import MultiSelectField from "../../components/form/MultiSelectField";
import TimeTableWrapper from "../../components/appWrapper/TimeTableWrapper";
import { DaysOfTheWeek } from "../../data/DaysOfTheWeek";
import InputFieldChange from "../../components/form/InputFieldChange";
import colors from "../../config/colors";
import { FaPlus } from "react-icons/fa";
import { classSections } from "../../data/classSections";

const AddClassSchedule = ({ ...props }) => {
  const { user } = useContext(AuthContext);

  const schoolClockInOut =
    !empty(user) && !empty(user.schoolClockInOut) ? user.schoolClockInOut : {};
  const classStartTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.classStartTime)
      ? schoolClockInOut.classStartTime
      : "";
  const schoolDismissalTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.schoolDismissalTime)
      ? schoolClockInOut.schoolDismissalTime
      : "";
  const lessonStartTime =
    !empty(schoolClockInOut) && !empty(schoolClockInOut.lessonStartTime)
      ? schoolClockInOut.lessonStartTime
      : "";
  const breakTimes =
    !empty(user) &&
    !empty(user.periodSettings) &&
    !empty(user.periodSettings.breakDetails)
      ? user.periodSettings.breakDetails
      : "";
  const maxClassPeriodsInARow =
    !empty(user) &&
    !empty(user.periodSettings) &&
    !empty(user.periodSettings.maxClassPeriodsInARow)
      ? user.periodSettings.maxClassPeriodsInARow
      : 2;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const { scheduleId } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [staff, setStaff] = useState([]);
  const [authorizedStaff, setAuthorizedStaff] = useState([]);
  const [days, setDays] = useState([]);
  const [reIndexedClasses, setReIndexedClasses] = useState({});
  const [selectedClassId, setSelectedClassId] = useState("");
  const [periods, setPeriods] = useState({});
  const [categories, setCategories] = useState([]);
  const [reIndexedCategories, setReIndexedCategories] = useState({});
  const [periodSection, setPeriodSection] = useState("");
  const [classScheduleInterval, setClassScheduleInterval] = useState(45);
  const [normalStartTimeIntervals, setNormalStartTimeIntervals] = useState([]);
  const [validNumberOfPeriods, setValidNumberOfPeriods] = useState([]);
  const [selectedNumberOfPeriods, setSelectedNumberOfPeriods] = useState(0);
  const [startTime, setStartTime] = useState(classStartTime);
  const toastTR = useRef(null);
  const initialValues = {
    subject_id: "",
    days: [],
  };

  const validationSchema = Yup.object().shape({
    subject_id: Yup.string().required("Subject is required!"),
    days: Yup.array()
      .of(Yup.string().required("Each day must be a valid string"))
      .min(1, "At least one day must be selected")
      .required("Days are required!"),
  });

  // alert functions
  const responseDailog = (
    severity = null,
    summary = null,
    detail = null,
    life = 20000
  ) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life,
    });
  };

  useEffect(() => {
    // get the interval for the class category
    if (!empty(selectedClassId) && !empty(periodSection)) {
      let classInterval = "";
      let validStartTimes = [];
      if (periodSection === "Normal Time") {
        const classCategoryId =
          !empty(reIndexedClasses) &&
          !empty(reIndexedClasses[selectedClassId]) &&
          !empty(reIndexedClasses[selectedClassId].categoryId)
            ? reIndexedClasses[selectedClassId].categoryId
            : "";
        classInterval =
          !empty(reIndexedCategories) &&
          !empty(reIndexedCategories[classCategoryId]) &&
          !empty(reIndexedCategories[classCategoryId].classScheduleInterval)
            ? reIndexedCategories[classCategoryId].classScheduleInterval
            : "";
        let defaultCategory = {};
        for (let i = 0; i < categories.length; i++) {
          const item = !empty(categories[i]) ? categories[i] : {};
          if (!empty(item) && item.title === "ALL (DEFAULT)") {
            defaultCategory = item;
            break;
          }
        }
        if (
          empty(defaultCategory) ||
          empty(defaultCategory.classScheduleInterval)
        ) {
          return responseDailog(
            "error",
            "Error Alert",
            `There are no available class schedule interval. Please go to 'Class schedule interval' and update the intervals for different class categories.`
          );
        }
        if (empty(classInterval)) {
          classInterval =
            !empty(defaultCategory) &&
            !empty(defaultCategory.classScheduleInterval)
              ? parseInt(defaultCategory.classScheduleInterval)
              : 45;
        }

        validStartTimes = generateValidStartTimes(
          lessonStartTime,
          classStartTime,
          schoolDismissalTime,
          classInterval,
          breakTimes
        );
      } else {
        classInterval =
          !empty(user) &&
          !empty(user.periodSettings) &&
          !empty(user.periodSettings.lessonClassInterval)
            ? parseInt(user.periodSettings.lessonClassInterval)
            : 30;

        validStartTimes = generateValidLessonStartTime(
          lessonStartTime,
          schoolDismissalTime,
          classInterval
        );
      }
      setClassScheduleInterval(classInterval);
      setNormalStartTimeIntervals(validStartTimes);

      // generate number of periods
      let numberOfPeriods = Array.from(
        { length: maxClassPeriodsInARow },
        (_, index) => ({
          value: index + 1,
        })
      );
      setValidNumberOfPeriods(numberOfPeriods);
    }
  }, [selectedClassId, periodSection]);

  useEffect(() => {
    if (!empty(selectedSubjectId)) {
      // get classes with the same class category as the selected subject
      const selectedSubjectIdObj = subjects.find((item) => {
        const subjectObj = !empty(item) && !empty(item._id) ? item._id : "";
        return subjectObj === selectedSubjectId;
      });
      let subjectClasses = [];
      if (!empty(classes)) {
        const subjectCategories =
          !empty(selectedSubjectIdObj) &&
          !empty(selectedSubjectIdObj.categories) &&
          isArray(selectedSubjectIdObj.categories)
            ? selectedSubjectIdObj.categories
            : "";
        subjectClasses = classes.filter(
          (item) =>
            !empty(item) &&
            !empty(item.categoryId) &&
            subjectCategories.includes(item.categoryId)
        );
        setSelectedClasses(subjectClasses);
      }

      let authorizedStaff = [];
      if (!empty(staff) && !empty(selectedClassId)) {
        authorizedStaff = staff.filter((staffMember) => {
          const authorizedSubjects =
            !empty(staffMember) &&
            !empty(staffMember.authorizedSubjects) &&
            isArray(staffMember.authorizedSubjects)
              ? staffMember.authorizedSubjects
              : [];
          return authorizedSubjects.some((authorizedSubject) => {
            const _subjectIds =
              !empty(authorizedSubject) &&
              !empty(authorizedSubject.subjectIds) &&
              isArray(authorizedSubject.subjectIds)
                ? authorizedSubject.subjectIds
                : [];
            const _classId =
              !empty(authorizedSubject) && !empty(authorizedSubject.classId)
                ? authorizedSubject.classId
                : "";
            return (
              _classId === selectedClassId &&
              _subjectIds.includes(selectedSubjectId)
            );
          });
        });
        if (empty(authorizedStaff)) {
          responseDailog(
            "error",
            "Internal server error",
            `There are no staff assigned to the selected subject for the selected class.`
          );
        }
        setAuthorizedStaff(authorizedStaff);
      }
    }
  }, [selectedSubjectId, selectedClassId]);

  useEffect(() => {
    getClasses();
    getSubjects();
    getStaff();
    getCategories();
  }, []);

  useEffect(() => {
    // Initialize periods with one period for each selected day
    const newPeriods = {};
    days.forEach((day) => {
      newPeriods[day] = periods[day] || 1;
    });
    setPeriods(newPeriods);
  }, [days]);

  // function to get all classes
  const getClasses = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClasses([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        let reIndexedClasses = {};
        if (!empty(data) && isArray(data)) {
          reIndexedClasses = reIndex(data, "_id");
        }
        setReIndexedClasses(reIndexedClasses);
        return setClasses(data);
      }
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error",
        `Failed to fecth classes.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // functionto get all categories
  const getCategories = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClassCategories(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setCategories([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        let reIndexedCategories = {};
        if (!empty(data) && isArray(data)) {
          reIndexedCategories = reIndex(data, "_id");
        }
        setReIndexedCategories(reIndexedCategories);
        return setCategories(data);
      }
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error",
        `Failed to fecth classes.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  // function to get all staff
  const getStaff = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await staffApi.getStaff(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStaff([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        return setStaff(data);
      }
    } catch (error) {
      responseDailog(
        "error",
        "Internal server error",
        `Failed to fetch staff.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjects = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await subjectApi.getSubjects(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Failed to fetch subjects"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        return setSubjects(response_data.response);
      }
      return;
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    } finally {
      setIsLoading(false);
    }
  };

  const submitClassSchedule = async (values) => {
    if (!isLoading) setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }

      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      // Prepare the periods data from the form
      const preparedPeriods = {};
      let dayIndex = 0;
      for (const day of days) {
        // Check if periods for the day are defined
        if (!empty(periods) && !isUndefined(periods[day])) {
          const _periods = periods[day]; // This is the number of periods for the day

          // Prepare periods for the day based on _periods
          preparedPeriods[day] = Array.from(
            { length: _periods },
            (_, index) => ({
              start_time: values[`start_time_${dayIndex}_${index}`],
              end_time: values[`end_time_${dayIndex}_${index}`],
              staff_id: values[`staff_id_${dayIndex}_${index}`],
              class_id: values[`class_id_${dayIndex}_${index}`],
              number_of_periods:
                values[`number_of_periods_${dayIndex}_${index}`],
              period_section: values[`period_section_${dayIndex}_${index}`],
            })
          );
        }
        dayIndex++;
      }

      // Prepare the entire form data for submission
      const formData = {
        subject_id: !isUndefined(values.subject_id) ? values.subject_id : "",
        days: !isUndefined(values.days) ? values.days : [],
        periods: preparedPeriods,
      };

      let response = await schoolApi.addClassSchedule(schoolId, formData);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }
      navigate("/time-table/list", {
        state: { scheduleAdded: true },
      });
    } catch (error) {
      responseDailog("error", "Internal server error", "Operation failed.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubjectChange = (name, newValue, setFieldValue) => {
    const subjectId =
      !empty(newValue) &&
      isObject(newValue) &&
      isObject(newValue.target) &&
      !empty(newValue.target.value)
        ? newValue.target.value
        : "";
    setSelectedSubjectId(subjectId);
    setFieldValue(name, subjectId);
  };

  const removePeriod = (day) => {
    setPeriods((prev) => {
      const updatedPeriods = { ...prev };

      // Check if the day exists and has periods
      if (updatedPeriods[day] > 0) {
        // If this is the last period for the day, remove the day entirely
        if (updatedPeriods[day] === 1) {
          delete updatedPeriods[day];

          // Also remove the day from the days array
          setDays((prevDays) => prevDays.filter((d) => d !== day));
        } else {
          // Decrement the number of periods for the specified day
          updatedPeriods[day]--;
        }
      }

      return updatedPeriods;
    });
  };

  const addAnotherPeriod = (day) => {
    setPeriods((prev) => ({
      ...prev,
      [day]: (prev[day] || 1) + 1,
    }));
  };

  const calculateEndTimeFunc = (
    _startTime,
    setFieldValue,
    numberOfPeriods,
    dayIndex,
    periodIndex
  ) => {
    const endTime = calculateEndTime(
      _startTime,
      classScheduleInterval,
      numberOfPeriods
    );
    // check if time interval overlaps break
    const timeOverLapsBreak = checkTimeOverlap(_startTime, endTime, breakTimes);
    if (timeOverLapsBreak) {
      setFieldValue(`end_time_${dayIndex}_${periodIndex}`, "");
      return responseDailog(
        "error",
        "Forbidden Operation",
        `The selected class start time; ${_startTime}, with period interval of ${numberOfPeriods}, for a class interval of ${classScheduleInterval} minutes, overlaps Break-Time. Please update the start time or the period interval.`,
        20000
      );
    }
    return setFieldValue(`end_time_${dayIndex}_${periodIndex}`, endTime);
  };

  const handleMultiChange = (
    name,
    newValue,
    setFieldValue,
    type,
    dayIndex = null,
    periodIndex = null
  ) => {
    try {
      if (type === "days") {
        const days = !empty(newValue) && isArray(newValue) ? newValue : [];
        setFieldValue(name, days);
        setDays(days);

        // Reset periods when days change, but keep existing periods for other days
        const newPeriods = { ...periods }; // Spread existing periods to retain them
        days.forEach((day) => {
          // If the day is newly added, initialize with 1 period if it doesn't already exist
          if (!newPeriods[day]) {
            newPeriods[day] = 1; // Initialize with 1 period for the new day
          }
        });
        setPeriods(newPeriods); // Set the updated periods state
        return;
      }

      if (type === "staff_id") {
        const staffId =
          !empty(newValue) && isObject(newValue) && newValue.target?.value
            ? newValue.target.value
            : "";
        setFieldValue(name, staffId);
      }

      if (type === "class_id") {
        const classId =
          !empty(newValue) && isObject(newValue) && newValue.target?.value
            ? newValue.target.value
            : "";
        setFieldValue(name, classId);
        setSelectedClassId(classId);
      }

      if (type === "number_of_periods") {
        const numberOfPeriods =
          !empty(newValue) && isObject(newValue) && newValue.target?.value
            ? newValue.target.value
            : "";
        setFieldValue(name, numberOfPeriods);
        setSelectedNumberOfPeriods(numberOfPeriods);

        return calculateEndTimeFunc(
          startTime,
          setFieldValue,
          numberOfPeriods,
          dayIndex,
          periodIndex
        );
      }

      if (type === "start_time") {
        const startTime =
          !empty(newValue) && isObject(newValue) && newValue.target?.value
            ? newValue.target.value
            : "";
        setFieldValue(name, startTime);
        setStartTime(startTime);
        if (!empty(selectedNumberOfPeriods)) {
          return calculateEndTimeFunc(
            startTime,
            setFieldValue,
            selectedNumberOfPeriods,
            dayIndex,
            periodIndex
          );
        }
      }

      if (type === "period_section") {
        const periodSection =
          !empty(newValue) &&
          isObject(newValue) &&
          !empty(newValue.target.value)
            ? newValue.target.value
            : "Normal Time";
        setFieldValue(name, periodSection);
        setPeriodSection(periodSection);
      }
    } catch (error) {}
  };

  return (
    <>
      <TimeTableWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader
              title={
                !empty(scheduleId)
                  ? "Update Class Schedule"
                  : "Add Class Schedule"
              }
            />
            <div className="form-container mt-10">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitClassSchedule}
              >
                {({ values, handleChange, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div>
                      <em>
                        <strong>Note: *</strong> Please enter the correct
                        details for the subject
                      </em>
                    </div>
                    <div className="app_input_group md-w-50pc">
                      <SelectField
                        labelTitle="Subject"
                        placeholder="Select Subject"
                        name="subject_id"
                        required={true}
                        options={subjects}
                        height={50}
                        valueKey="_id"
                        selectedOption={values.subject_id}
                        handleChangeFunc={(e) =>
                          handleSubjectChange("subject_id", e, setFieldValue)
                        }
                      />
                    </div>
                    {!empty(selectedSubjectId) && (
                      <>
                        <div className="app_input_group">
                          <MultiSelectField
                            labelTitle="Days"
                            required={true}
                            placeholder="Select days"
                            name="days"
                            options={DaysOfTheWeek}
                            selectedOptions={days}
                            optionValue="value"
                            optionLabel="value"
                            onChange={(e) =>
                              handleMultiChange(
                                "days",
                                e.value,
                                setFieldValue,
                                "days"
                              )
                            }
                          />
                        </div>
                        {days.map((day, dayIndex) => {
                          return (
                            <div
                              key={dayIndex}
                              className="time-table-period-border mt-30"
                            >
                              <p
                                className="mb-20"
                                style={{ color: colors.primary, fontSize: 18 }}
                              >
                                <strong>{day}</strong>
                              </p>
                              {(periods[day] || 1) > 0 &&
                                [...Array(periods[day] || 1)].map(
                                  (_, periodIndex) => (
                                    <div
                                      key={periodIndex}
                                      className="mb-20 time-table-period-box"
                                    >
                                      <p>
                                        <strong>
                                          Period {periodIndex + 1}
                                        </strong>
                                      </p>
                                      <div className="app_input_group">
                                        <SelectField
                                          labelTitle="Class"
                                          placeholder="Select Class"
                                          name={`class_id_${dayIndex}_${periodIndex}`}
                                          required={true}
                                          options={selectedClasses}
                                          height={50}
                                          valueKey="id"
                                          selectedOption={
                                            values[
                                              `class_id_${dayIndex}_${periodIndex}`
                                            ] || ""
                                          }
                                          handleChangeFunc={(e) =>
                                            handleMultiChange(
                                              `class_id_${dayIndex}_${periodIndex}`,
                                              e,
                                              setFieldValue,
                                              "class_id"
                                            )
                                          }
                                        />
                                        {!empty(selectedClassId) && (
                                          <SelectField
                                            labelTitle="Period Section"
                                            placeholder="Select Period Section"
                                            name={`period_section_${dayIndex}_${periodIndex}`}
                                            required={true}
                                            options={classSections}
                                            height={50}
                                            valueKey="title"
                                            display="title"
                                            selectedOption={
                                              values[
                                                `period_section_${dayIndex}_${periodIndex}`
                                              ] || ""
                                            }
                                            handleChangeFunc={(e) =>
                                              handleMultiChange(
                                                `period_section_${dayIndex}_${periodIndex}`,
                                                e,
                                                setFieldValue,
                                                "period_section"
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                      {!empty(periodSection) && (
                                        <>
                                          <div className="app_input_group mt-10">
                                            <SelectField
                                              labelTitle="Start Time"
                                              placeholder="Select Start Time"
                                              name={`start_time_${dayIndex}_${periodIndex}`}
                                              required={true}
                                              options={normalStartTimeIntervals}
                                              height={50}
                                              valueKey="value"
                                              display="value"
                                              selectedOption={
                                                values[
                                                  `start_time_${dayIndex}_${periodIndex}`
                                                ] || ""
                                              }
                                              handleChangeFunc={(e) =>
                                                handleMultiChange(
                                                  `start_time_${dayIndex}_${periodIndex}`,
                                                  e,
                                                  setFieldValue,
                                                  "start_time",
                                                  dayIndex,
                                                  periodIndex
                                                )
                                              }
                                            />
                                            <SelectField
                                              labelTitle="Number of Periods"
                                              placeholder="Select Number of Periods"
                                              name={`number_of_periods_${dayIndex}_${periodIndex}`}
                                              required={true}
                                              options={validNumberOfPeriods}
                                              height={50}
                                              valueKey="value"
                                              display="value"
                                              selectedOption={
                                                values[
                                                  `number_of_periods_${dayIndex}_${periodIndex}`
                                                ] || ""
                                              }
                                              handleChangeFunc={(e) =>
                                                handleMultiChange(
                                                  `number_of_periods_${dayIndex}_${periodIndex}`,
                                                  e,
                                                  setFieldValue,
                                                  "number_of_periods",
                                                  dayIndex,
                                                  periodIndex
                                                )
                                              }
                                            />
                                            <InputFieldChange
                                              placeholder="Enter End Time"
                                              name={`end_time_${dayIndex}_${periodIndex}`}
                                              required={true}
                                              height={50}
                                              marginTop={10}
                                              isDisabled={true}
                                              labelTitle="End Time"
                                              value={
                                                values[
                                                  `end_time_${dayIndex}_${periodIndex}`
                                                ] || ""
                                              }
                                              onChange={handleChange}
                                            />
                                          </div>
                                          <div className="app_input_group">
                                            <SelectField
                                              labelTitle="Staff"
                                              placeholder="Select Staff"
                                              name={`staff_id_${dayIndex}_${periodIndex}`}
                                              required={true}
                                              options={authorizedStaff}
                                              height={50}
                                              valueKey="_id"
                                              display="nameTitle"
                                              selectedOption={
                                                values[
                                                  `staff_id_${dayIndex}_${periodIndex}`
                                                ] || ""
                                              }
                                              handleChangeFunc={(e) =>
                                                handleMultiChange(
                                                  `staff_id_${dayIndex}_${periodIndex}`,
                                                  e,
                                                  setFieldValue,
                                                  "staff_id"
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                      {dayIndex > 0 ||
                                      (periodIndex > 0 && dayIndex === 0) ? (
                                        <ButtonIcon
                                          height={45}
                                          marginTop={5}
                                          color="#ffffff"
                                          backgroundColor={colors.danger}
                                          width={200}
                                          borderColor={colors.danger}
                                          icon={<FaPlus />}
                                          iconMarginRight={8}
                                          buttonText="Remove period"
                                          onClick={() =>
                                            removePeriod(day, periodIndex)
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  )
                                )}
                              <ButtonIcon
                                height={45}
                                marginTop={5}
                                color="#ffffff"
                                backgroundColor="#633ccd"
                                width={200}
                                borderColor="#633ccd"
                                icon={<FaPlus />}
                                iconMarginRight={8}
                                buttonText="Add another period"
                                onClick={() => addAnotherPeriod(day)}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}

                    <div className="form-button-container">
                      <ButtonIcon
                        height={45}
                        marginTop={5}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={300}
                        borderColor="#633ccd"
                        buttonText="Add Schedule(s)"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </TimeTableWrapper>
    </>
  );
};

export default AddClassSchedule;
