import { useContext, useRef, useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import { AuthContext } from "../Root/ProtectedRoute";
import { Scanner } from "@yudiel/react-qr-scanner";

// css
import "../students/Students.css";
import "./Attendance.css";

// api
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";

//components
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import AttendanceWrapper from "../../components/appWrapper/AttendanceWrapper";

const AttendanceScanner = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  //ref
  const toastTR = useRef(null);
  // states
  const [isActionLoading, setIsActionLoading] = useState(false);
  const { classId } = useParams();
  if (!classId) {
    // navigate("/404");
  }

  return (
    <>
      <AttendanceWrapper {...props}>
        <main>
          <Scanner onScan={(result) => console.log(result)} />
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AttendanceWrapper>

      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default AttendanceScanner;
