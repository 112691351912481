import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import assessmentApi from "../../api/Assessment";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import TableLoading from "../../components/skeleton/TableLoading";
import { FaPlus } from "react-icons/fa";
import AffectiveDomainDataTable from "../../components/tables/primeTable/affectiveDomain/AffectiveDomainDataTable";

const AffectiveDomains = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [affectiveDomainData, setAffectiveDomainData] = useState([]);
  const [affectiveDomainModalVisibility, setAffectiveDomainModalVisibility] =
    useState(false);
  const [selectedAffectiveDomainDetail, setSelectedAffectiveDomainDetail] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);

  useEffect(() => {
    if (!empty(location) && !empty(location.state)) {
      const paramState = !empty(location.state) ? location.state : {};
      let affectiveDomainAdded = !empty(paramState.affectiveDomainAdded)
        ? paramState.affectiveDomainAdded
        : false;
      let affectiveDomainUpdated = !empty(paramState.affectiveDomainUpdated)
        ? paramState.affectiveDomainUpdated
        : false;
      if (affectiveDomainAdded === true || affectiveDomainUpdated === true) {
        const actionType = affectiveDomainAdded
          ? "added"
          : affectiveDomainUpdated
          ? "updated"
          : "";
        paramState.affectiveDomainAdded = false;
        paramState.affectiveDomainUpdated = false;
        responseDailog(
          "success",
          "Success",
          `Affective Domain ${actionType} successfully!`
        );
        if (!empty(location) && !empty(location.state)) {
          navigate(null, {
            replace: true,
            state: {
              affectiveDomainAdded: false,
              affectiveDomainUpdated: false,
            },
          });
        }
      }
    }
  }, []);
  useEffect(() => {
    // fetch affective domain
    try {
      getAffectiveDomain();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load affective domain. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { affectiveDomainAdded: false, affectiveDomainUpdated: false },
        });
      }
    }
  }, [user]);

  // function to get all affective domain
  const getAffectiveDomain = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await assessmentApi.getAffectiveDomains(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setAffectiveDomainData([]);
      } else {
        const data =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : [];
        setAffectiveDomainData(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  // delete affective domain
  const deleteAffectiveDomain = async () => {
    try {
      if (affectiveDomainModalVisibility)
        setAffectiveDomainModalVisibility(false);
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const affectiveDomainId =
        !empty(selectedAffectiveDomainDetail) &&
        !empty(selectedAffectiveDomainDetail._id)
          ? selectedAffectiveDomainDetail._id
          : "";
      const response = await assessmentApi.deleteAffectiveDomain(
        schoolId,
        affectiveDomainId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        const error_response =
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to delete affective domain";
        return responseDailog("error", "Something went wrong", error_response);
      }

      responseDailog(
        "success",
        "Success",
        `Affective Domain, ${
          !empty(selectedAffectiveDomainDetail.title)
            ? selectedAffectiveDomainDetail.title
            : ""
        }, was deleted successfully!`
      );
      return getAffectiveDomain();
    } catch (error) {
      return responseDailog(
        "error",
        "Something went wrong",
        "Request failed please try again later"
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  //function to hide confirm modal
  const hideAffectiveDomainModal = () => {
    setAffectiveDomainModalVisibility(false);
  };

  //go to update affective domain page
  const goToUpdateAffectiveDomain = (data) => {
    const affectiveDomainId = !empty(data) && !empty(data._id) ? data._id : "";
    navigate(`/assessment/affective-domain/${affectiveDomainId}/update`);
  };

  // delete affective domain
  const showDeleteAffectiveDomainModal = (data) => {
    setSelectedAffectiveDomainDetail(data);
    setAffectiveDomainModalVisibility(true);
  };

  const affectiveDomainModalFooter = (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <Button
        label="No"
        icon="pi pi-times"
        style={{
          backgroundColor: "transparent",
          color: "#e65061",
          borderColor: "#e65061",
          borderWidth: 1,
        }}
        onClick={hideAffectiveDomainModal}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        style={{
          backgroundColor: "transparent",
          color: "#389d17",
          borderColor: "#389d17",
          borderWidth: 1,
        }}
        onClick={deleteAffectiveDomain}
      />
    </div>
  );

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              button
              buttonText="Add Affective Domain"
              link="/assessment/affective-domain/add"
              title="Affective Domains"
              icon={<FaPlus />}
              iconMarginRight={5}
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <AffectiveDomainDataTable
                affectiveDomain={affectiveDomainData}
                goToUpdateAffectiveDomainScreen={goToUpdateAffectiveDomain}
                goToDeleteAffectiveDomainScreen={showDeleteAffectiveDomainModal}
              />
            ) : (
              <TableLoading rows={20} cols={3} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
      </AppWrapper>
      {isActionLoading && <FullPageLoader visible={isActionLoading} />}

      <Dialog
        visible={affectiveDomainModalVisibility}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={affectiveDomainModalFooter}
        onHide={hideAffectiveDomainModal}
      >
        <div
          className="confirmation-content"
          style={{ marginTop: 20, display: "flex", alignItems: "center" }}
        >
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem", marginRight: 15, color: "#e65061" }}
          />
          {
            <span>
              Are you sure you want to delete the selected affective domain with
              title,
              <strong>
                {" "}
                {!empty(selectedAffectiveDomainDetail) &&
                !empty(selectedAffectiveDomainDetail.title)
                  ? selectedAffectiveDomainDetail.title
                  : ""}
              </strong>
              ?
            </span>
          }
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default AffectiveDomains;
