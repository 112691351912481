import client from "./Client";

const updateSchoolSettings = (
  closing_date = "",
  opening_date = "",
  remarks = [],
  school_id,
  type = ""
) =>
  client.post(`/settings/update`, {
    closing_date,
    opening_date,
    remarks,
    school_id,
    type,
  });

const updatePassword = (
  school_id = "",
  current_password = "",
  new_password = "",
  confirm_password = ""
) =>
  client.post(`/settings/password/update`, {
    school_id,
    current_password,
    new_password,
    confirm_password,
  });

const getNumberOfStudentsInSchool = (school_id) =>
  client.get(`/${school_id}/stat/students`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getSchoolAssessmentsData = (school_id) =>
  client.get(`/${school_id}/classes/students/assessments-data`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getNumberOfSubjectsInSchool = (school_id) =>
  client.get(`/${school_id}/stat/subjects`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getNoOfSchoolStudentsByGender = (school_id) =>
  client.get(`/${school_id}/stat/students/gender-data`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getNumberOfStaff = (school_id) =>
  client.get(`/${school_id}/stat/staff`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getNumberOfClasses = (school_id) =>
  client.get(`/${school_id}/stat/classes`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getStudentAssessmentPerformance = (school_id) =>
  client.get(`/${school_id}/stat/students/assessment`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getNoOfSchoolStaffByGender = (school_id) =>
  client.get(`/${school_id}/stat/staff/gender-data`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getRecentlyRegisteredStudents = (
  school_id,
  page = 0,
  rows = 10,
  search = ""
) => {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (rows) {
    params.rows = rows;
  }
  if (search) {
    params.search = search;
  }

  return client.get(`/${school_id}/recently-registered/students`, {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getRecentlyRegisteredStaff = (
  school_id,
  page = 0,
  rows = 10,
  search = ""
) => {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (rows) {
    params.rows = rows;
  }
  if (search) {
    params.search = search;
  }

  return client.get(`/${school_id}/recently-registered/staff`, {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getGraduationYears = (school_id) =>
  client.get(`/${school_id}/graduation-years`);

const updateAssessmentMaxScores = (
  school_id = "",
  ca1_max = "",
  ca2_max = "",
  exam_max = ""
) =>
  client.post(`/assessment/max-scores`, {
    school_id,
    ca1_max,
    ca2_max,
    exam_max,
  });

const updateUserAvatar = (user_id = "", avatar_base64 = "") =>
  client.post(`/avatar/upload`, {
    user_id,
    avatar_base64,
  });

const updatePeriods = (
  school_id,
  no_of_breaks,
  break_details,
  no_of_periods_after_last_break,
  no_of_classes_per_day
) =>
  client.post(`/academic-settings/periods`, {
    school_id,
    no_of_breaks,
    break_details,
    no_of_periods_after_last_break,
    no_of_classes_per_day,
  });

const updateSchoolTimes = (
  school_id,
  class_start_time,
  school_start_time,
  school_dismissal_time,
  lesson_start_time,
  lesson_dismissal_time,
  school_open_days,
  lesson_free_days
) =>
  client.post(`/academic-settings/times`, {
    school_id,
    class_start_time,
    school_start_time,
    school_dismissal_time,
    lesson_start_time,
    lesson_dismissal_time,
    school_open_days,
    lesson_free_days,
  });

// add batch schedule
const addClassSchedule = (school_id, formData) =>
  client.post(`/class/schedule/add`, {
    school_id,
    ...formData,
  });

const addSingleClassSchedule = (school_id, formData) =>
  client.post(`/class/single/schedule/add`, {
    school_id,
    ...formData,
  });

const updateClassSchedule = (school_id, formData) =>
  client.post(`/class/schedule/update`, {
    school_id,
    ...formData,
  });

const getSchoolClassSchedules = (
  school_id,
  session_id = null,
  term_id = null
) => {
  const params = {};
  if (session_id) {
    params.session_id = session_id;
  }
  if (term_id) {
    params.term_id = term_id;
  }

  return client.get(`/${school_id}/class-schedules`, {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export default {
  updateSchoolSettings,
  getNumberOfStudentsInSchool,
  getSchoolAssessmentsData,
  getNumberOfSubjectsInSchool,
  getNoOfSchoolStudentsByGender,
  getNumberOfStaff,
  getNumberOfClasses,
  getStudentAssessmentPerformance,
  getNoOfSchoolStaffByGender,
  getRecentlyRegisteredStudents,
  updatePassword,
  getRecentlyRegisteredStaff,
  updateAssessmentMaxScores,
  updateUserAvatar,
  getGraduationYears,
  updatePeriods,
  updateSchoolTimes,
  addClassSchedule,
  updateClassSchedule,
  getSchoolClassSchedules,
  addSingleClassSchedule,
};
