import client from "./Client";

const getSchoolData = (userId) => {
  return client.get(`/details/${encodeURIComponent(userId)}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export default {
  getSchoolData,
};
