import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { forEach } from "lodash";
import * as Yup from "yup";

// css
import "../students/Students.css";
import "./Settings.css";

// api
import schoolApi from "../../api/School";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import FullPageLoader from "../../components/loader/FullPageLoader";
import Card from "../../components/card/Card";
import { Form, Formik } from "formik";
import InputField from "../../components/form/InputField";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { AuthContext } from "../Root/ProtectedRoute";
import { empty, prepareResponseData } from "../../Utilities/utils";
import SettingsWrapper from "../../components/appWrapper/SettingsWrapper";

const initialUpdatePasswordValues = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};

const required = "This field is required.";
const validationUpdatePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required(required),
  new_password: Yup.string().required(required),
  confirm_password: Yup.string().required(required),
});

const SettingsUpdatePassword = ({ ...props }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const toastTR = useRef(null);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const handleUpdatePasswordSubmit = async (values) => {
    if (!isLoading) setIsLoading(true);
    try {
      if (empty(values)) {
        return responseDailog(
          "error",
          "Error Alert",
          "Something went wrong! Try again later."
        );
      }
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";

      const fields = ["current_password", "new_password", "confirm_password"];
      const details = {};
      if (!empty(values)) {
        forEach(fields, (data) => {
          details[data] =
            !empty(data) && !empty(values[data]) ? values[data] : "";
        });
      }
      const response = await schoolApi.updatePassword(
        schoolId,
        ...Object.values(details)
      );

      const response_data = prepareResponseData(response);
      if (empty(response_data.success)) {
        if (
          !empty(response_data) &&
          !empty(response_data.statusCodeType) &&
          response_data.statusCodeType === "unauthorized"
        ) {
          return navigate("/");
        }
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      responseDailog("success", "Successful", "Password updated successfully");
      setTimeout(() => {
        navigate("/logout");
      }, 3000);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SettingsWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader title="Settings" />
            <div className="flex flex-direction-row col-g-12 w-100pc">
              <Card
                children={
                  <div>
                    <MainHeader titleColor="#633ccd" title="Update Password" />
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={initialUpdatePasswordValues}
                        validationSchema={validationUpdatePasswordSchema}
                        onSubmit={handleUpdatePasswordSubmit}
                      >
                        <Form style={{ width: "100%" }}>
                          <div className="app_input_group">
                            <InputField
                              placeholder="Enter Current Password"
                              name="current_password"
                              height={50}
                              type="password"
                              labelTitle={
                                <>
                                  Current Password
                                  <span className="required">*</span>
                                </>
                              }
                            />
                            <InputField
                              placeholder="Enter New Password"
                              name="new_password"
                              height={50}
                              type="password"
                              labelTitle={
                                <>
                                  New Paassword
                                  <span className="required">*</span>
                                </>
                              }
                            />
                            <InputField
                              placeholder="Confirm New Password"
                              name="confirm_password"
                              height={50}
                              type="password"
                              labelTitle={
                                <>
                                  Confirm New Paassword
                                  <span className="required">*</span>
                                </>
                              }
                            />
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <ButtonIcon
                              height={45}
                              marginTop={5}
                              color="#ffffff"
                              backgroundColor="#633ccd"
                              width={300}
                              borderColor="#633ccd"
                              buttonText="Update Password"
                              type="submit"
                            />
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                }
                addStyle="settings-body px-20"
              />
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </SettingsWrapper>
    </>
  );
};

export default SettingsUpdatePassword;
