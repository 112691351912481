import { useContext, useRef, useState } from "react";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "../staff/Staff.css";

// api
import schoolApi from "../../api/School";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import Card from "../../components/card/Card";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import Underline from "../../components/others/Underline";
import ItemLoading from "../../components/skeleton/ItemLoading";
import colors from "../../config/colors";
import SettingsWrapper from "../../components/appWrapper/SettingsWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import { FaCloudUploadAlt, FaImage } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import FullPageLoader from "../../components/loader/FullPageLoader";

const SchoolProfile = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const toastTR = useRef(null); // Create a reference to the hidden file input element
  const fileInputRef = useRef(null);
  const [isUploadAvatarModal, setIsUploadAvatarModal] = useState(false);
  const [avatar, setAvatar] = useState(
    !empty(user) && !empty(user.avatar) ? user.avatar : ""
  );
  const [previewAvatar, setPreviewAvatar] = useState("");

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  // Function to trigger the hidden file input click
  const handleImageFilePickUp = () => {
    fileInputRef.current.click();
  };

  // Function to handle the selected file
  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        // Convert file to Base64 when the file is loaded
        reader.onloadend = async () => {
          const base64String = reader.result;
          setPreviewAvatar(base64String);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {}
  };

  const submitAvatar = async () => {
    // send file to the server
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await schoolApi.updateUserAvatar(
        schoolId,
        previewAvatar,
        "schools"
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || empty(response_data.success)) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Failed to update school avatar!"
        );
      }

      setPreviewAvatar("");
      setAvatar(previewAvatar);

      return responseDailog(
        "success",
        "Success",
        "School avatar updated successfully."
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
      setIsUploadAvatarModal(false);
    }
  };

  const name = !empty(user) && !empty(user.name) ? user.name : "";
  const initial = name.charAt(0);
  const ownerName =
    !empty(user) && !empty(user.ownerName) ? user.ownerName : "";
  const ownerEmail =
    !empty(user) && !empty(user.ownerEmail) ? user.ownerEmail : "";
  const ownerPhone =
    !empty(user) && !empty(user.ownerPhone) ? user.ownerPhone : "";
  const address = !empty(user) && !empty(user.address) ? user.address : "";
  const stateOfResidence =
    !empty(user) && !empty(user.stateOfResidence) ? user.stateOfResidence : "";
  const lgaOfResidence =
    !empty(user) && !empty(user.lgaOfResidence) ? user.lgaOfResidence : "";
  const stateOfOrigin =
    !empty(user) && !empty(user.stateOfOrigin) ? user.stateOfOrigin : "";
  const lgaOfOrigin =
    !empty(user) && !empty(user.lgaOfOrigin) ? user.lgaOfOrigin : "";
  const ownerAddress =
    !empty(user) && !empty(user.ownerAddress) ? user.ownerAddress : "";
  const dateRegistered =
    !empty(user) && !empty(user.dateRegistered) ? user.dateRegistered : "";
  const active = !empty(user) && !empty(user.active) ? user.active : "";
  const activeSession =
    !empty(user) && !empty(user.currentSessionTitle)
      ? user.currentSessionTitle
      : "";
  const activeTerm =
    !empty(user) && !empty(user.currentTermTitle) ? user.currentTermTitle : "";
  const phoneNumber1 =
    !empty(user) && !empty(user.phoneNumber1) ? user.phoneNumber1 : "";
  const phoneNumber2 =
    !empty(user) && !empty(user.phoneNumber2) ? user.phoneNumber2 : "";
  const email = !empty(user) && !empty(user.email) ? user.email : "";
  const govtApproved =
    !empty(user) && !empty(user.govtApproved) ? user.govtApproved : "";

  return (
    <>
      <SettingsWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="School Profile" />
          {/* end menu header */}
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div className="school-profile-box">
              <Card
                children={
                  <>
                    <div className="dashboard_school_list">
                      <div className="profile-avatar-container">
                        <div className="avatar-box">
                          {!empty(avatar) ? (
                            <Avatar
                              image={avatar}
                              shape="circle"
                              style={{
                                width: 200,
                                height: 200,
                                border: "4px solid " + colors.lightgray,
                                padding: 10,
                              }}
                              className="p-overlay-badge"
                            >
                              <Badge
                                style={{
                                  position: "absolute",
                                  top: 22,
                                  right: 15,
                                  width: 15,
                                  height: 15,
                                  backgroundColor: active
                                    ? colors.success
                                    : colors.danger,
                                }}
                                className="avatar-badge"
                              />
                            </Avatar>
                          ) : (
                            <Avatar
                              label={initial}
                              shape="circle"
                              style={{ width: 150, height: 150, fontSize: 70 }}
                              className="p-overlay-badge"
                            >
                              <Badge
                                style={{
                                  position: "absolute",
                                  top: 22,
                                  right: 22,
                                  width: 15,
                                  height: 15,
                                  backgroundColor: active
                                    ? colors.success
                                    : colors.danger,
                                }}
                                className="avatar-badge"
                              />
                            </Avatar>
                          )}
                        </div>
                        <div className="flex flex-center w-100pc">
                          <ButtonIcon
                            height={50}
                            marginTop={1}
                            color={colors.primary}
                            backgroundColor="transparent"
                            width={180}
                            fontSize={22}
                            borderColor="transparent"
                            buttonText="Update Avatar"
                            iconMarginRight={8}
                            icon={<FaCloudUploadAlt />}
                            fontWeight="bold"
                            onClick={() => setIsUploadAvatarModal(true)}
                          />
                        </div>
                        <div className="information-container">
                          <div className="form-section-title mb-20">
                            Bio
                            <Underline />
                          </div>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Name</strong>
                            <span>{!isLoading ? name : <ItemLoading />}</span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Name: </strong>
                            <span>
                              {!isLoading ? stateOfOrigin : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">State of Origin: </strong>
                            <span>
                              {!isLoading ? lgaOfOrigin : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">
                              State of Residence:{" "}
                            </strong>
                            <span>
                              {!isLoading ? stateOfResidence : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">
                              L.G.A of Residence:{" "}
                            </strong>
                            <span>
                              {!isLoading ? lgaOfResidence : <ItemLoading />}
                            </span>
                          </p>
                          <div className="form-section-title mb-20">
                            Contact Information
                            <Underline />
                          </div>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Address: </strong>
                            <span>
                              {!isLoading ? address : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Phone Number 1: </strong>
                            <span>
                              {!isLoading ? phoneNumber1 : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Phone Number 2: </strong>
                            <span>
                              {!isLoading ? phoneNumber2 : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Email: </strong>
                            <span>{!isLoading ? email : <ItemLoading />}</span>
                          </p>
                          <div className="form-section-title mb-20">
                            Academic Information
                            <Underline />
                          </div>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">
                              Government Approved:{" "}
                            </strong>
                            <span>
                              {!isLoading ? govtApproved : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Active Session: </strong>
                            <span>
                              {!isLoading ? activeSession : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Active Term: </strong>
                            <span>
                              {!isLoading ? activeTerm : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Date Registered: </strong>
                            <span>
                              {!isLoading ? dateRegistered : <ItemLoading />}
                            </span>
                          </p>
                        </div>
                        <div className="information-container">
                          <div className="form-section-title mb-20">
                            Owner Information
                            <Underline />
                          </div>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Name</strong>
                            <span>
                              {!isLoading ? ownerName : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Email</strong>
                            <span>
                              {!isLoading ? ownerEmail : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Phone</strong>
                            <span>
                              {!isLoading ? ownerPhone : <ItemLoading />}
                            </span>
                          </p>
                          <p className="mb-10 flex space-between bio-data-item">
                            <strong className="fs-14">Address</strong>
                            <span>
                              {!isLoading ? ownerAddress : <ItemLoading />}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                }
                addStyle="student_list_box"
              />
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
        {isLoading && <FullPageLoader visible={isLoading} />}
      </SettingsWrapper>

      <Dialog
        visible={isUploadAvatarModal}
        style={{ width: "32rem", zIndex: 999999 }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm Action"
        modal
        onHide={() => {
          setPreviewAvatar("");
          setIsUploadAvatarModal(false);
        }}
      >
        <div
          className="confirmation-content"
          style={{
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="avatar-preview-box mb-20">
            {!empty(previewAvatar) ? (
              <img src={previewAvatar} alt="" />
            ) : (
              <FaCloudUploadAlt size={80} />
            )}
          </div>
          {<span>Select image to upload</span>}

          <div className="flex flex-center w-100pc col-g-12">
            <ButtonIcon
              height={40}
              marginTop={1}
              color={colors.tomato}
              backgroundColor={colors.lightgray}
              width={170}
              fontSize={22}
              borderColor={colors.lightgray}
              buttonText="Select Avatar"
              iconMarginRight={8}
              icon={<FaImage />}
              fontWeight="bold"
              onClick={handleImageFilePickUp}
            />
            {previewAvatar ? (
              <ButtonIcon
                height={40}
                marginTop={1}
                color={colors.primary}
                backgroundColor={colors.lightgray}
                width={180}
                fontSize={22}
                borderColor={colors.lightgray}
                buttonText="Update Avatar"
                iconMarginRight={8}
                icon={<FaCloudUploadAlt />}
                fontWeight="bold"
                onClick={() => submitAvatar()}
              />
            ) : null}
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              style={{ display: "none" }}
              accept="image/*"
            />
          </div>
        </div>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default SchoolProfile;
