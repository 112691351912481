export const Numbers = [
  {
    _id: 0,
    value: 0,
  },
  {
    _id: 1,
    value: 1,
  },
  {
    _id: 2,
    value: 2,
  },
  {
    _id: 3,
    value: 3,
  },
  {
    _id: 4,
    value: 4,
  },
  {
    _id: 5,
    value: 5,
  },
  {
    _id: 6,
    value: 6,
  },
  {
    _id: 7,
    value: 7,
  },
  {
    _id: 8,
    value: 8,
  },
  {
    _id: 9,
    value: 9,
  },
  {
    _id: 10,
    value: 10,
  },
  {
    _id: 11,
    value: 11,
  },
  {
    _id: 12,
    value: 12,
  },
];
