import { useContext, useRef, useState, useEffect } from "react";
import * as Yup from "yup";

// api
import attendanceApi from "../../api/Attendance";

//css
import "../dashboard/Dashboard.css";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";

//data
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { Toast } from "primereact/toast";
import colors from "../../config/colors";
import AttendanceWrapper from "../../components/appWrapper/AttendanceWrapper";
import { Form, Formik } from "formik";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaCheck, FaTimes } from "react-icons/fa";
import { months } from "../../data/months";

const validationSchema = Yup.object().shape({
  start_date: Yup.string().optional(),
  end_date: Yup.string().optional(),
  session_ids: Yup.string().optional(),
  term_ids: Yup.string().optional(),
});

const initialValues = {
  start_date: "",
  end_date: "",
  session_ids: "",
  term_ids: "",
};
const AttendanceDashboard = ({ ...props }) => {
  const { user } = useContext(AuthContext);

  // stat status
  const [assessmentDataLoading, setAssessmentDataLoading] = useState(false);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);

  const assessmentData = [];
  const [chartData, setChartData] = useState({
    labels: assessmentData.map((data) => {
      const classTitle = !empty(data) && !empty(data.title) ? data.title : "";
      return classTitle;
    }),
    datasets: [
      {
        label: "Class Performance",
        data: assessmentData.map((data) => {
          const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
          return sum;
        }),
        backgroundColor: colors.charcoal,
        borderRadius: 12,
      },
    ],
  });
  const toastTR = useRef(null);

  useEffect(() => {
    getClassAttendanceStat();
  }, [user]);

  const getDaysInMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // January is 0, so need to add 1
    return new Date(year, month, 0).getDate();
  };

  // Creating sample data for names and attendance
  useEffect(() => {
    const _daysInMonth = getDaysInMonth();
    setDaysInMonth(_daysInMonth);
    const data = [
      {
        name: "name 1",
        attendance: [
          { day: 1, month: 8, year: 2024 },
          { day: 2, month: 8, year: 2024 },
          { day: 3, month: 8, year: 2024 },
          { day: 4, month: 8, year: 2024 },
          { day: 5, month: 8, year: 2024 },
          { day: 6, month: 8, year: 2024 },
          { day: 7, month: 8, year: 2024 },
        ],
      },
      {
        name: "name 2",
        attendance: [
          { day: 1, month: 8, year: 2024 },
          { day: 2, month: 8, year: 2024 },
          { day: 3, month: 8, year: 2024 },
          { day: 4, month: 8, year: 2024 },
          { day: 5, month: 8, year: 2024 },
          { day: 6, month: 8, year: 2024 },
          { day: 7, month: 8, year: 2024 },
        ],
      },
      // Add more names as needed
    ];
    setAttendanceData(data);
  }, []);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getClassAttendanceStat = async (params = {}) => {
    setAssessmentDataLoading(true);
    params.type = "CHECK-IN";
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await attendanceApi.getClassAssessmentStat(
        schoolId,
        params
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data.success
      ) {
        return;
      }
      const attendanceData =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : [];
      setChartData({
        labels: attendanceData.map((data) => {
          const classTitle =
            !empty(data) && !empty(data.classTitle) ? data.classTitle : "";
          return classTitle;
        }),
        datasets: [
          {
            label: `Students Present`,
            data: attendanceData.map((data) => {
              const studentsPresent =
                !empty(data) && !empty(data.studentsPresent)
                  ? data.studentsPresent
                  : 0;
              return studentsPresent;
            }),
            backgroundColor: colors.primary,
            borderRadius: 12,
          },
          {
            label: `Students Absent`,
            data: attendanceData.map((data) => {
              const studentsPresent =
                !empty(data) && !empty(data.studentsPresent)
                  ? data.studentsPresent
                  : 0;
              const noOfStudents =
                !empty(data) && !empty(data.noOfStudents)
                  ? data.noOfStudents
                  : 0;
              return noOfStudents - studentsPresent;
            }),
            backgroundColor: colors.orange,
            borderRadius: 12,
          },
        ],
      });
    } catch (error) {
      responseDailog("error", "Attendance Chart Data", "Something went wrong.");
    } finally {
      setAssessmentDataLoading(false);
    }
  };

  const searchConstraints = (values) => {
    if (empty(values)) {
      responseDailog(
        "error",
        "Something went wrong.",
        "Please refresh page or try again later."
      );
    }
    const start_date = !empty(values.start_date) ? values.start_date : "";
    const end_date = !empty(values.end_date) ? values.end_date : "";
    const session_id = !empty(values.session_id) ? values.session_id : "";
    const term_id = !empty(values.term_id) ? values.term_id : "";
    const payload = { start_date, end_date, session_id, term_id };
  };
  const attendanceBodyTemplate = (rowData, options) => {
    const day = parseInt(options.field.replace("day", ""), 10);
    const isPresent = rowData.attendance.some((att) => att.day === day);

    return (
      <div style={{ textAlign: "center" }}>
        {isPresent ? (
          <FaCheck style={{ color: "green" }} />
        ) : (
          <FaTimes style={{ color: "red" }} />
        )}
      </div>
    );
  };

  return (
    <>
      <AttendanceWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Attendance History" />
          {/* end menu header */}
          <div className="mb-20" style={{ width: "100%" }}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={searchConstraints}
            >
              {({ handleChange }) => (
                <Form style={{ width: "100%" }}>
                  <div className="attendance-dashboard-filter">
                    <SelectField
                      labelTitle="Month"
                      placeholder="Select Month"
                      name="month"
                      options={months}
                      height={50}
                      valueKey="id"
                      handleChangeFunc={handleChange}
                    />
                    <ButtonIcon
                      height={45}
                      marginTop={7}
                      color="#ffffff"
                      backgroundColor="#633ccd"
                      width={280}
                      borderColor="#633ccd"
                      buttonText="Generate"
                      type="submit"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div className="datatable">
              <DataTable
                value={attendanceData}
                tableClassName="dashboard-table"
                responsiveLayout="scroll"
                emptyMessage="No data found"
                loading={isLoading}
              >
                <Column
                  field="name"
                  header="Name"
                  headerClassName="table-header"
                  style={{ width: "30%" }}
                />

                {[...Array(daysInMonth)].map((_, index) => (
                  <Column
                    key={index + 1}
                    field={`day${index + 1}`}
                    header={index + 1}
                    headerClassName="table-header"
                    body={attendanceBodyTemplate}
                    style={{ textAlign: "center", width: "40px" }}
                  />
                ))}
              </DataTable>
            </div>
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AttendanceWrapper>
    </>
  );
};

export default AttendanceDashboard;
