import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";

// api
import sessionApi from "../../api/Session";
import termApi from "../../api/Terms";
import classApi from "../../api/Classes";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import SelectField from "../../components/form/SelectField";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { AuthContext } from "../Root/ProtectedRoute";

const required = "This field is required!";
const validationSchema = Yup.object().shape({
  session_id: Yup.string().required(required),
  session_id: Yup.string().required(required),
  class_id: Yup.string().required(required),
  term_id: Yup.string().required(required),
});

const initialValues = {
  session_id: "",
  term_id: "",
  class_id: "",
};

const ResultChecker = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [terms, setTerms] = useState([]);
  const toastTR = useRef(null);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    getSessions();
    getTerms();
    getClasses();
  }, []);

  const getSessions = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await sessionApi.getSessions(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Failed to fetch sessions!"
            : "Failed to fetch sessions"
        );
      }

      if (!empty(response_data) && response_data.success) {
        const sessionData = response_data.response;
        setSessions(sessionData);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getTerms = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await termApi.getTerms(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Failed to fetch terms!"
            : "Failed to fetch terms"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const termData = response_data.response;
        setTerms(termData);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getClasses = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getClasses(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Failed to fetch terms!"
            : "Failed to fetch terms"
        );
      }

      if (!empty(response_data) && !empty(response_data.response)) {
        const classData = response_data.response;
        setClasses(classData);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const sessionId =
        !empty(values) && !empty(values.session_id) ? values.session_id : "";
      const classId =
        !empty(values) && !empty(values.class_id) ? values.class_id : "";
      const termId =
        !empty(values) && !empty(values.term_id) ? values.term_id : "";
      navigate("/assessment/report-sheet", {
        state: { sessionId, termId, classId },
      });
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClassChange = (setFieldValue) => (e) => {
    const { value } = e.target;
    setFieldValue("class_id", value);
  };

  const handleSessionChange = (setFieldValue) => (e) => {
    const { value } = e.target;
    setFieldValue("session_id", value);
  };
  const handleTermChange = (setFieldValue) => (e) => {
    const { value } = e.target;
    setFieldValue("term_id", value);
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="container flex-center-top">
            <MainHeader title="Check Result" />
            <div className="form-container mt-30">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, values, handleChange, setFieldValue }) => (
                  <Form style={{ width: "100%" }}>
                    <div>
                      <em>
                        <strong>Note: *</strong> Enter the details of the
                        academic session and term you wish to check the result
                      </em>
                    </div>
                    <div className="app_input_group">
                      <SelectField
                        labelTitle="Session"
                        placeholder="Select Session"
                        name="session_id"
                        options={sessions}
                        height={50}
                        valueKey="id"
                        selectedOption={values.session_id}
                        handleChangeFunc={handleSessionChange(setFieldValue)}
                      />
                      <SelectField
                        labelTitle="Term"
                        required={true}
                        placeholder="Select Term"
                        name="term_id"
                        options={terms}
                        height={50}
                        valueKey="id"
                        selectedOption={values.term_id}
                        handleChangeFunc={handleTermChange(setFieldValue)}
                      />
                      <SelectField
                        labelTitle={
                          <>
                            Class <span className="required">*</span>
                          </>
                        }
                        placeholder="Select Class"
                        name="class_id"
                        options={classes}
                        height={50}
                        valueKey="id"
                        selectedOption={values.class_id}
                        handleChangeFunc={handleClassChange(setFieldValue)}
                      />
                    </div>
                    <div className="form-button-container">
                      <ButtonIcon
                        height={45}
                        marginTop={5}
                        color="#ffffff"
                        backgroundColor="#633ccd"
                        width={300}
                        borderColor="#633ccd"
                        buttonText="Submit"
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </main>
        {isLoading && <FullPageLoader visible={isLoading} />}
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default ResultChecker;
