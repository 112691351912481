import React from "react";
import { ErrorMessage } from "formik";
import _ from "lodash";
import { empty } from "../../Utilities/utils";
import { MultiSelect } from "primereact/multiselect";

export default function MultiSelectField({
  name,
  placeholder,
  icon,
  id,
  options = [],
  labelTitle = "",
  selectedOptions = [],
  maxSelectedLabels = 100,
  marginTop = 5,
  onChange,
  optionLabel = "title",
  optionValue = "_id",
  required = false,
  multiClassName = "w-full md:w-20rem",
}) {
  return (
    <>
      <div
        className="p-inputgroup mt-30"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {!empty(labelTitle) && (
          <label htmlFor={id}>
            {labelTitle} {required ? <span className="required">*</span> : ""}
          </label>
        )}
        <div style={{ display: "flex", marginTop, flexDirection: "row" }}>
          {icon && (
            <span className="p-inputgroup-addon">
              <i className={"pi pi-" + icon}></i>
            </span>
          )}
          <MultiSelect
            value={selectedOptions}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            maxSelectedLabels={maxSelectedLabels}
            className={multiClassName}
            filter
            optionLabel={optionLabel}
            optionValue={optionValue}
            style={{ borderColor: "transparent" }}
          />
        </div>
        <ErrorMessage
          name={name}
          component="div"
          style={{
            marginTop: 7,
            fontSize: 14,
            fontStyle: "italic",
            color: "#ce1616",
          }}
        />
      </div>
    </>
  );
}
